import * as tf from "@tensorflow/tfjs"


// output code
const IQC_CODE = {
    "BRIGHTNESS_GOOD": 200,
    "BRIGHTNESS_WARN": 210,
    "BRIGHTNESS_BAD": 220,
    "BRIGHTNESS_NULL": 290,

    "ANGULAR_GOOD": 300,
    "ANGULAR_WARN": 310,
    "ANGULAR_BAD": 320,
    "ANGULAR_NULL": 390,

    "POSITION_GOOD": 400,
    "POSITION_WARN_OUT_OF_BOUND": 410,
    "POSITION_WARN_CLOSE": 411,
    "POSITION_WARN_FAR": 412,
    "POSITION_BAD_OUT_OF_BOUND": 420,
    "POSITION_BAD_CLOSE": 421,
    "POSITION_BAD_FAR": 422,
    "POSITION_NULL": 490,

    "EYE_OPEN": 500,
    "EYE_LEFT_CLOSED": 510,
    "EYE_RIGHT_CLOSED": 511,
    "EYE_BOTH_CLOSED": 520,
    "EYE_NULL": 590,
}

// main class
export class ImageQualityCheckRealTime{
    // private members
    #DEBUG_TAG

    #PAD_SCALE_DET
    #FACE_Detection_IMAGE_HEIGHT
    #FACE_Detection_IMAGE_WIDTH
    #FACE_Detection_THRESHOLD
    #FACE_LANDMARK_IMAGE_HEIGHT
    #FACE_LANDMARK_IMAGE_WIDTH
    #modelFaceDection
    #modelFaceLandmark

    #POSITION_FACE_BEST_faceRect_CORD
    #faceRectBadInner
    #faceRectBadOuter
    #faceRectWarnInner
    #faceRectWarnOuter

    #IQC_Results

    #BRIGHTNESS_THRESHOLD
    #ANGULAR_YAW_MAX
    #ANGULAR_YAW_MIN
    #ANGULAR_ROLL_MAX
    #ANGULAR_ROLL_MIN
    #ANGULAR_PITCH_MAX
    #ANGULAR_PITCH_MIN
    #POSITION_PAD_SCALE_DET
    #POSITION_PAD_SCALE_BAD
    #EYE_CLOSED_EAR_THRESHOLD

    #BRIGHTNESS_IMAGE_HEIGHT
    #BRIGHTNESS_IMAGE_WIDTH

    #currentFrameRawResults

    constructor(isDebug){
        this.DEBUG_MODE = isDebug
        this.#DEBUG_TAG = 'lumini-IQC_RT\t'

        this.#PAD_SCALE_DET = 0.1
        this.#FACE_Detection_IMAGE_HEIGHT = 96
        this.#FACE_Detection_IMAGE_WIDTH = 96
        this.#FACE_Detection_THRESHOLD = 0.5
        this.#FACE_LANDMARK_IMAGE_HEIGHT = 128
        this.#FACE_LANDMARK_IMAGE_WIDTH = 128
        this.#BRIGHTNESS_IMAGE_HEIGHT = 64
        this.#BRIGHTNESS_IMAGE_WIDTH = 64
        this.isModelLoaded = false

        this.#loadModel()
        this.refreshResults()
        this.initUIParameter(0, 0, 1, 1)
        this.setScaler(1, 1, 1, 1)
    }

    async #loadModel(){
        // load ML model form encrypted file name
        this.#modelFaceDection = await tf.loadGraphModel(process.env.PUBLIC_URL + '182c8323caaa8/30c4170302250')
        this.#modelFaceLandmark = await tf.loadGraphModel(process.env.PUBLIC_URL + '37de08dbfdc23/2130f2e7f3188')

        // call once to initialize GPU memory.
        const dummyCanvas = document.createElement('canvas')
        dummyCanvas.width = this.#FACE_LANDMARK_IMAGE_HEIGHT
        dummyCanvas.height = this.#FACE_LANDMARK_IMAGE_WIDTH
        const ctx = dummyCanvas.getContext('2d')
        ctx.clearRect(0, 0, dummyCanvas.width, dummyCanvas.height)
        this.imageQualityCheckRealTime(ctx.getImageData(0, 0, dummyCanvas.width, dummyCanvas.height), false)
        this.isModelLoaded = true

        this.#log("model is loaded successfully!")
    }

    // main function from client
    imageQualityCheckRealTime(video, isMirrored){
        const tt = performance.now()

        const image = this.#preprocessInputImage(video, isMirrored)
        const faceRectPromise = this.#detectFace(image, this.#modelFaceDection)
        const landmarksPromise = faceRectPromise.then(data => this.#detectFaceLandmark(image, this.#modelFaceLandmark, data))
        const brightnessPromise = faceRectPromise.then(data => this.#checkBrightness(image, data))
        const positionPromise = faceRectPromise.then(data => this.#checkPosition(data))
        const angularPromise = landmarksPromise.then(data => this.#checkAngular(data))
        const eyeClosedPromise = landmarksPromise.then(data => this.#checkEyeClosed(data))

        const results = this.#postProcessFullTask(image, faceRectPromise, landmarksPromise, brightnessPromise, angularPromise, positionPromise, eyeClosedPromise)

        this.#log(`${(performance.now() - tt).toFixed(2)}ms`)   // compute computing time.
        this.#log(`tf.numTensors: ${tf.memory().numTensors}`)   // check memory leaking.
        return results
    }

    async #detectFace(image, model){
        const imagePreprocess = this.#preprocessFaceDetection(image)
        const output = model.execute(imagePreprocess)
        const output_tp = tf.transpose(output, [0, 2, 1])

        let faceRect = Array(4)
        let faceRectPadding = Array(4)
        let objScoreMax = 0
        let objectIndex = -1

        for (let index = 0; index < output_tp.shape[1]; index++) {
            const objScore = output_tp.dataSync()[index * 5 + 4]

            if ((objScore > this.#FACE_Detection_THRESHOLD) && (objScore > objScoreMax)){
                objScoreMax = objScore
                objectIndex = index
            }
        }

        // if face is detecetd
        if (objectIndex !== -1){
            const objfaceRect = output_tp.dataSync().slice(objectIndex * 5, objectIndex * 5 + 4)

            faceRect[0] = (objfaceRect[0] - (objfaceRect[2] / 2)) / this.#FACE_Detection_IMAGE_WIDTH
            faceRect[1] = (objfaceRect[1] - (objfaceRect[3] / 2)) / this.#FACE_Detection_IMAGE_HEIGHT
            faceRect[2] = (objfaceRect[0] + (objfaceRect[2] / 2)) / this.#FACE_Detection_IMAGE_WIDTH
            faceRect[3] = (objfaceRect[1] + (objfaceRect[3] / 2)) / this.#FACE_Detection_IMAGE_HEIGHT

            faceRectPadding[0] = faceRect[0] - ((faceRect[2] - faceRect[0]) * this.#PAD_SCALE_DET)
            faceRectPadding[1] = faceRect[1] - ((faceRect[3] - faceRect[1]) * this.#PAD_SCALE_DET)
            faceRectPadding[2] = faceRect[2] + ((faceRect[2] - faceRect[0]) * this.#PAD_SCALE_DET)
            faceRectPadding[3] = faceRect[3] + ((faceRect[3] - faceRect[1]) * this.#PAD_SCALE_DET)
        }
        else{
            faceRect = undefined
        }

        tf.dispose(output_tp)
        tf.dispose(imagePreprocess)
        tf.dispose(output)

        return [faceRect, faceRectPadding]
    }

    async #detectFaceLandmark(image, model, faceRect){
        if (faceRect[0] !== undefined){
            const imagePreprocess = this.#preprocessFaceLandmark(image, faceRect)
            const output = await model.execute(imagePreprocess)

            tf.dispose(imagePreprocess)
            return output
        }
    }

    async #checkBrightness(preprocessImage, faceRect){
        return tf.tidy( () => {
            if (faceRect[0] !== undefined){
                const faceRectReversed = Array(4)
                faceRectReversed[0] = faceRect[1][1]
                faceRectReversed[1] = faceRect[1][0]
                faceRectReversed[2] = faceRect[1][3]
                faceRectReversed[3] = faceRect[1][2]
                preprocessImage = tf.image.cropAndResize(preprocessImage, [faceRectReversed], [0], [this.#BRIGHTNESS_IMAGE_HEIGHT, this.#BRIGHTNESS_IMAGE_WIDTH], "bilinear")

                const red = tf.slice(preprocessImage, [0, 0, 0, 0], [1, this.#BRIGHTNESS_IMAGE_HEIGHT, this.#BRIGHTNESS_IMAGE_WIDTH, 1]).mul(0.2989)
                const green = tf.slice(preprocessImage, [0, 0, 0, 1], [1, this.#BRIGHTNESS_IMAGE_HEIGHT, this.#BRIGHTNESS_IMAGE_WIDTH, 1]).mul(0.5870)
                const blue = tf.slice(preprocessImage, [0, 0, 0, 2], [1, this.#BRIGHTNESS_IMAGE_HEIGHT, this.#BRIGHTNESS_IMAGE_WIDTH, 1]).mul(0.1140)
                const brightness = tf.div(tf.sum(tf.add(red, green, blue)), this.#BRIGHTNESS_IMAGE_HEIGHT * this.#BRIGHTNESS_IMAGE_WIDTH).dataSync()[0]

                if (brightness < this.#BRIGHTNESS_THRESHOLD / 2)
                    return IQC_CODE.BRIGHTNESS_BAD
                else if (brightness >= this.#BRIGHTNESS_THRESHOLD / 2 && brightness < this.#BRIGHTNESS_THRESHOLD)
                    return IQC_CODE.BRIGHTNESS_WARN
                else if (brightness >= this.#BRIGHTNESS_THRESHOLD)
                    return IQC_CODE.BRIGHTNESS_GOOD
            }
            else
                return IQC_CODE.BRIGHTNESS_NULL
        })
    }

    async #checkAngular(landmarkTensor) {
        if (landmarkTensor !== undefined){
            const landmarks = landmarkTensor.dataSync()
            const landmarkIntegerResult = new Array(212).fill(0)

            let xMin = Infinity
            let xMax = -Infinity
            let yMin = Infinity
            let yMax = -Infinity

            for (let i = 0; i < 106; i++) {
                xMax = Math.max(xMax, landmarks[2 * i])
                xMin = Math.min(xMin, landmarks[2 * i])
                yMax = Math.max(yMax, landmarks[2 * i + 1])
                yMin = Math.min(yMin, landmarks[2 * i + 1])
            }

            for (let i = 0; i < 106; i++) {
                landmarkIntegerResult[2 * i] = Math.floor(((landmarks[2 * i] - xMin) / (xMax - xMin)) * this.#FACE_LANDMARK_IMAGE_WIDTH)
                landmarkIntegerResult[2 * i + 1] = Math.floor(((landmarks[2 * i + 1] - yMin) / (yMax - yMin)) * this.#FACE_LANDMARK_IMAGE_HEIGHT)
            }

            const le2n = landmarkIntegerResult[46 * 2] - landmarkIntegerResult[72 * 2]
            const re2n = landmarkIntegerResult[75 * 2] - landmarkIntegerResult[46 * 2]
            const yaw = le2n - re2n

            const eyeY = (landmarkIntegerResult[74 * 2 + 1] + landmarkIntegerResult[77 * 2 + 1]) / 2
            const chinY = landmarkIntegerResult[16 * 2 + 1]
            const e2n = landmarkIntegerResult[46 * 2 + 1] - eyeY
            const n2m = chinY - landmarkIntegerResult[46 * 2 + 1]
            const pitch = e2n / n2m

            const roll = landmarkIntegerResult[82 * 2 + 1] - landmarkIntegerResult[83 * 2 + 1]

            if (yaw >= this.#ANGULAR_YAW_MIN * 1.4 &&
                yaw <= this.#ANGULAR_YAW_MAX * 1.4 &&
                roll >= this.#ANGULAR_ROLL_MIN * 1.4 &&
                roll <= this.#ANGULAR_ROLL_MAX * 1.4 &&
                pitch >= this.#ANGULAR_PITCH_MIN * 0.7 &&
                pitch <= this.#ANGULAR_PITCH_MAX * 1.4){

                if (yaw >= this.#ANGULAR_YAW_MIN &&
                    yaw <= this.#ANGULAR_YAW_MAX &&
                    roll >= this.#ANGULAR_ROLL_MIN &&
                    roll <= this.#ANGULAR_ROLL_MAX &&
                    pitch >= this.#ANGULAR_PITCH_MIN &&
                    pitch <= this.#ANGULAR_PITCH_MAX)

                    return IQC_CODE.ANGULAR_GOOD
                else
                    return IQC_CODE.ANGULAR_WARN
            }else
                return IQC_CODE.ANGULAR_BAD
        }
        else
            return IQC_CODE.ANGULAR_NULL
    }

    async #checkPosition(faceRect) {
        if (faceRect[0] !== undefined && this.#POSITION_FACE_BEST_faceRect_CORD !== undefined) {
            const faceRect_xyxy_cord = faceRect[0]

            if (this.#POSITION_PAD_SCALE_DET === Infinity || this.#POSITION_PAD_SCALE_BAD === Infinity){
                return IQC_CODE.POSITION_GOOD
            }

            // Check face position based on area and boundaries
            if ((faceRect_xyxy_cord[2] - faceRect_xyxy_cord[0]) < (this.#faceRectBadInner[2] - this.#faceRectBadInner[0]) ||
                (faceRect_xyxy_cord[3] - faceRect_xyxy_cord[1]) < (this.#faceRectBadInner[3] - this.#faceRectBadInner[1])) {
                return IQC_CODE.POSITION_BAD_FAR
            }
            else if ((faceRect_xyxy_cord[2] - faceRect_xyxy_cord[0]) > (this.#faceRectBadOuter[2] - this.#faceRectBadOuter[0]) ||
                     (faceRect_xyxy_cord[3] - faceRect_xyxy_cord[1]) > (this.#faceRectBadOuter[3] - this.#faceRectBadOuter[1])) {
                return IQC_CODE.POSITION_BAD_CLOSE
            }
            else if ((faceRect_xyxy_cord[2] - faceRect_xyxy_cord[0]) < (this.#faceRectWarnInner[2] - this.#faceRectWarnInner[0]) ||
                     (faceRect_xyxy_cord[3] - faceRect_xyxy_cord[1]) < (this.#faceRectWarnInner[3] - this.#faceRectWarnInner[1])) {
                return IQC_CODE.POSITION_WARN_FAR
            }
            else if ((faceRect_xyxy_cord[2] - faceRect_xyxy_cord[0]) > (this.#faceRectWarnOuter[2] - this.#faceRectWarnOuter[0]) ||
                     (faceRect_xyxy_cord[3] - faceRect_xyxy_cord[1]) > (this.#faceRectWarnOuter[3] - this.#faceRectWarnOuter[1])) {
                return IQC_CODE.POSITION_WARN_CLOSE
            }
            else if (faceRect_xyxy_cord[0] < this.#faceRectBadOuter[0] ||
                     faceRect_xyxy_cord[1] < this.#faceRectBadOuter[1] ||
                     faceRect_xyxy_cord[2] > this.#faceRectBadOuter[2] ||
                     faceRect_xyxy_cord[3] > this.#faceRectBadOuter[3] ||
                     faceRect_xyxy_cord[0] > this.#faceRectBadInner[0] ||
                     faceRect_xyxy_cord[1] > this.#faceRectBadInner[1] ||
                     faceRect_xyxy_cord[2] < this.#faceRectBadInner[2] ||
                     faceRect_xyxy_cord[3] < this.#faceRectBadInner[3] ||
                     faceRect_xyxy_cord[0] < 0 ||
                     faceRect_xyxy_cord[1] < 0 ||
                     faceRect_xyxy_cord[2] > 1 ||
                     faceRect_xyxy_cord[3] > 1) {
                return IQC_CODE.POSITION_BAD_OUT_OF_BOUND
            }
            else if (faceRect_xyxy_cord[0] < this.#faceRectWarnOuter[0] ||
                     faceRect_xyxy_cord[1] < this.#faceRectWarnOuter[1] ||
                     faceRect_xyxy_cord[2] > this.#faceRectWarnOuter[2] ||
                     faceRect_xyxy_cord[3] > this.#faceRectWarnOuter[3] ||
                     faceRect_xyxy_cord[0] > this.#faceRectWarnInner[0] ||
                     faceRect_xyxy_cord[1] > this.#faceRectWarnInner[1] ||
                     faceRect_xyxy_cord[2] < this.#faceRectWarnInner[2] ||
                     faceRect_xyxy_cord[3] < this.#faceRectWarnInner[3] ||
                     faceRect_xyxy_cord[0] < 0 ||
                     faceRect_xyxy_cord[1] < 0 ||
                     faceRect_xyxy_cord[2] > 1 ||
                     faceRect_xyxy_cord[3] > 1) {
                return IQC_CODE.POSITION_WARN_OUT_OF_BOUND
            }
            else
                return IQC_CODE.POSITION_GOOD

        }
        else
            return IQC_CODE.POSITION_NULL
    }

    async #checkEyeClosed(landmarkTensor){
        if (landmarkTensor !== undefined){
            const landmarks = landmarkTensor.dataSync()
            const leftEye_E1 = landmarks[57 * 2 + 1] - landmarks[53 * 2 + 1]
            const leftEye_E2 = landmarks[56 * 2 + 1] - landmarks[54 * 2 + 1]
            const leftEye_E3 = 2 * (landmarks[55 * 2] - landmarks[52 * 2])

            const rightEye_E1 = landmarks[62 * 2 + 1] - landmarks[60 * 2 + 1]
            const rightEye_E2 = landmarks[63 * 2 + 1] - landmarks[59 * 2 + 1]
            const rightEye_E3 = 2 * (landmarks[61 * 2] - landmarks[58 * 2])

            const leftEAR = (leftEye_E1 + leftEye_E2) / leftEye_E3
            const rightEAR = (rightEye_E1 + rightEye_E2) / rightEye_E3

            if (leftEAR < this.#EYE_CLOSED_EAR_THRESHOLD && rightEAR < this.#EYE_CLOSED_EAR_THRESHOLD)
                return IQC_CODE.EYE_BOTH_CLOSED
            else if (leftEAR < this.#EYE_CLOSED_EAR_THRESHOLD)
                return IQC_CODE.EYE_LEFT_CLOSED
            else if (rightEAR < this.#EYE_CLOSED_EAR_THRESHOLD)
                return IQC_CODE.EYE_RIGHT_CLOSED
            else
                return IQC_CODE.EYE_OPEN
        }
        return IQC_CODE.EYE_NULL
    }

    async #postProcessFullTask(image, faceRectPromise, landmarksPromise, brightnessPromise, angularPromise, positionPromise, eyeClosedPromise){
        const [faceRect, faceRectPadding] = await faceRectPromise
        const landmarks = await landmarksPromise
        const brightness = await brightnessPromise
        const angular = await angularPromise
        const position = await positionPromise
        const eyeClosed = await eyeClosedPromise

        if (faceRect !== undefined){
            // if face is detected, set the current results
            this.#currentFrameRawResults.faceRect = faceRect
            this.#currentFrameRawResults.faceRectPadding = faceRectPadding
            this.#currentFrameRawResults.landmarks = landmarks.dataSync()
        }
        else{
            // if face is not detected, set the current results undefined.
            // TODO: set to previous results on tracking?
            this.#currentFrameRawResults.faceRect = undefined
            this.#currentFrameRawResults.faceRectPadding = undefined
            this.#currentFrameRawResults.landmarks = undefined
        }

        tf.dispose(image)
        tf.dispose(faceRect)
        tf.dispose(landmarks)

        return [brightness, angular, position, eyeClosed]
    }

    #preprocessInputImage(video, isMirrored){
        return tf.tidy( () => {
            let image = tf.browser.fromPixels(video).expandDims(0)
            if (isMirrored) image = tf.image.flipLeftRight(image.toFloat())
            return image
        })
    }

    #preprocessFaceDetection(preprocessImage){
        return tf.tidy( () => {
            preprocessImage = preprocessImage.resizeBilinear([this.#FACE_Detection_IMAGE_HEIGHT, this.#FACE_Detection_IMAGE_WIDTH])
            preprocessImage = preprocessImage.div(255)
            return preprocessImage
        })
    }

    #preprocessFaceLandmark(preprocessImage, faceRect){
        const faceRectReversed = Array(4)
        faceRectReversed[0] = faceRect[1][1]
        faceRectReversed[1] = faceRect[1][0]
        faceRectReversed[2] = faceRect[1][3]
        faceRectReversed[3] = faceRect[1][2]

        return tf.tidy( () => {
            preprocessImage = tf.image.cropAndResize(preprocessImage, [faceRectReversed], [0], [this.#FACE_LANDMARK_IMAGE_HEIGHT, this.#FACE_LANDMARK_IMAGE_WIDTH], "bilinear")
            preprocessImage = preprocessImage.div(255).sub(0.5).div(0.25)
            return preprocessImage
        })
    }

    #drawRect(faceRect, ctx){
        if (faceRect !== undefined){
            const x = faceRect[0] * ctx.canvas.clientWidth
            const y = faceRect[1] * ctx.canvas.clientHeight
            const w = (faceRect[2] - faceRect[0]) * ctx.canvas.clientWidth
            const h = (faceRect[3] - faceRect[1]) * ctx.canvas.clientHeight
            ctx.beginPath()
            ctx.rect(x, y, w, h)
            ctx.stroke()
        }
    }

    #drawLandmark(landmarks, faceRectPadding, ctx){
        const xRatio = ((faceRectPadding[2] - faceRectPadding[0]) * ctx.canvas.clientWidth) / this.#FACE_LANDMARK_IMAGE_WIDTH
        const yRatio = ((faceRectPadding[3] - faceRectPadding[1]) * ctx.canvas.clientHeight) / this.#FACE_LANDMARK_IMAGE_HEIGHT

        for (let index = 0; index < landmarks.length; index+=2) {
            let x = landmarks[index] * xRatio * this.#FACE_LANDMARK_IMAGE_WIDTH
            let y = landmarks[index + 1] * yRatio * this.#FACE_LANDMARK_IMAGE_HEIGHT

            x = x + (faceRectPadding[0] * ctx.canvas.clientWidth)
            y = y + (faceRectPadding[1] * ctx.canvas.clientHeight)

            ctx.fillRect(x, y, 3, 3)
        }
    }

    drawResults(ctx){
        ctx.strokeStyle = 'cyan'
        this.#drawRect(this.#POSITION_FACE_BEST_faceRect_CORD, ctx)
        ctx.strokeStyle = '#FF0000'
        this.#drawRect(this.#faceRectBadInner, ctx)
        this.#drawRect(this.#faceRectBadOuter, ctx)
        ctx.strokeStyle = '#FFF000'
        this.#drawRect(this.#faceRectWarnInner, ctx)
        this.#drawRect(this.#faceRectWarnOuter, ctx)
        if (this.#currentFrameRawResults.landmarks !== undefined && this.#currentFrameRawResults.faceRect!== undefined){
            ctx.fillStyle = 'green'
            ctx.strokeStyle = '#0000FF'
            this.#drawRect(this.#currentFrameRawResults.faceRect, ctx)
            this.#drawLandmark(this.#currentFrameRawResults.landmarks, this.#currentFrameRawResults.faceRectPadding, ctx)
        }
    }

    getDetectResult(){
        return this.#IQC_Results
    }

    initUIParameter(originUIfaceRect){
        this.#POSITION_FACE_BEST_faceRect_CORD = originUIfaceRect
    }

    refreshResults(){
        this.#IQC_Results = [IQC_CODE.BRIGHTNESS_NULL, IQC_CODE.ANGULAR_NULL, IQC_CODE.POSITION_NULL, IQC_CODE.EYE_NULL]
        this.#currentFrameRawResults = {
            "faceRect": undefined,
            "faceRectPadding": undefined,
            "landmarks": undefined,
        }
    }

    setScaler(brightnessScaler, angularScaler, positionScaler, eyeScaler){
        // The lower the value, the easier to pass.
        // Pass all if "scaler == 0f".
        const BRIGHTNESS_THRESHOLD_DEFAULT = 80
        const ANGULAR_YAW_MAX_DEFAULT = 20
        const ANGULAR_YAW_MIN_DEFAULT = -20
        const ANGULAR_ROLL_MAX_DEFAULT = 10
        const ANGULAR_ROLL_MIN_DEFAULT = -10
        const ANGULAR_PITCH_MAX_DEFAULT = 0.6
        const ANGULAR_PITCH_MIN_DEFAULT = 0.25
        const POSITION_PAD_SCALE_DET_DEFAULT = this.#PAD_SCALE_DET
        const POSITION_PAD_SCALE_BAD_DEFAULT = this.#PAD_SCALE_DET + 0.05
        const EYE_CLOSED_EAR_THRESHOLD_DEFAULT = 0.1

        this.#BRIGHTNESS_THRESHOLD = (angularScaler !== 0) ? BRIGHTNESS_THRESHOLD_DEFAULT * brightnessScaler : -Infinity
        this.#ANGULAR_YAW_MAX = (angularScaler !== 0) ? ANGULAR_YAW_MAX_DEFAULT / angularScaler : Infinity
        this.#ANGULAR_YAW_MIN = (angularScaler !== 0) ? ANGULAR_YAW_MIN_DEFAULT / angularScaler : -Infinity
        this.#ANGULAR_ROLL_MAX = (angularScaler !== 0) ? ANGULAR_ROLL_MAX_DEFAULT / angularScaler : Infinity
        this.#ANGULAR_ROLL_MIN = (angularScaler !== 0) ? ANGULAR_ROLL_MIN_DEFAULT / angularScaler : -Infinity
        this.#ANGULAR_PITCH_MAX = (angularScaler !== 0) ? ANGULAR_PITCH_MAX_DEFAULT / angularScaler : Infinity
        this.#ANGULAR_PITCH_MIN = (angularScaler !== 0) ? ANGULAR_PITCH_MIN_DEFAULT * angularScaler : -Infinity
        this.#POSITION_PAD_SCALE_DET = (angularScaler !== 0) ? POSITION_PAD_SCALE_DET_DEFAULT / positionScaler : Infinity
        this.#POSITION_PAD_SCALE_BAD = (angularScaler !== 0) ? POSITION_PAD_SCALE_BAD_DEFAULT / positionScaler : Infinity
        this.#EYE_CLOSED_EAR_THRESHOLD  = (angularScaler !== 0) ? EYE_CLOSED_EAR_THRESHOLD_DEFAULT * eyeScaler : -Infinity

        // Calculate inner and outer bounds with padding
        // The faceRectBad,Warn can be lower than zero according to canvas size.
        // In this case, the captured face would be too small to analyze itself.
        this.#faceRectBadInner = [
            this.#POSITION_FACE_BEST_faceRect_CORD[0] + this.#POSITION_PAD_SCALE_BAD,
            this.#POSITION_FACE_BEST_faceRect_CORD[1] + this.#POSITION_PAD_SCALE_BAD,
            this.#POSITION_FACE_BEST_faceRect_CORD[2] - this.#POSITION_PAD_SCALE_BAD,
            this.#POSITION_FACE_BEST_faceRect_CORD[3] - this.#POSITION_PAD_SCALE_BAD,
        ]
        this.#faceRectBadOuter = [
            Math.max(this.#POSITION_FACE_BEST_faceRect_CORD[0] - this.#POSITION_PAD_SCALE_BAD, 0),
            Math.max(this.#POSITION_FACE_BEST_faceRect_CORD[1] - this.#POSITION_PAD_SCALE_BAD, 0),
            Math.min(this.#POSITION_FACE_BEST_faceRect_CORD[2] + this.#POSITION_PAD_SCALE_BAD, 1),
            Math.min(this.#POSITION_FACE_BEST_faceRect_CORD[3] + this.#POSITION_PAD_SCALE_BAD, 1),
        ]
        this.#faceRectWarnInner = [
            this.#POSITION_FACE_BEST_faceRect_CORD[0] + this.#POSITION_PAD_SCALE_DET,
            this.#POSITION_FACE_BEST_faceRect_CORD[1] + this.#POSITION_PAD_SCALE_DET,
            this.#POSITION_FACE_BEST_faceRect_CORD[2] - this.#POSITION_PAD_SCALE_DET,
            this.#POSITION_FACE_BEST_faceRect_CORD[3] - this.#POSITION_PAD_SCALE_DET,
        ]
        this.#faceRectWarnOuter = [
            Math.max(this.#POSITION_FACE_BEST_faceRect_CORD[0] - this.#POSITION_PAD_SCALE_DET, 0),
            Math.max(this.#POSITION_FACE_BEST_faceRect_CORD[1] - this.#POSITION_PAD_SCALE_DET, 0),
            Math.min(this.#POSITION_FACE_BEST_faceRect_CORD[2] + this.#POSITION_PAD_SCALE_DET, 1),
            Math.min(this.#POSITION_FACE_BEST_faceRect_CORD[3] + this.#POSITION_PAD_SCALE_DET, 1),
        ]
        /*
        TODO: scalable faceRect based on POSITION_FACE_BEST_faceRect_CORD, like:

        const faceRectWarnInner = [
        this.#POSITION_FACE_BEST_faceRect_CORD[0] + ((POSITION_FACE_BEST_faceRect_CORD[2] - POSITION_FACE_BEST_faceRect_CORD[0]) * this.#POSITION_PAD_SCALE_DET),
        this.#POSITION_FACE_BEST_faceRect_CORD[1] + ((POSITION_FACE_BEST_faceRect_CORD[3] - POSITION_FACE_BEST_faceRect_CORD[1]) * this.#POSITION_PAD_SCALE_DET),
        this.#POSITION_FACE_BEST_faceRect_CORD[2] - ((POSITION_FACE_BEST_faceRect_CORD[2] - POSITION_FACE_BEST_faceRect_CORD[0]) * this.#POSITION_PAD_SCALE_DET),
        this.#POSITION_FACE_BEST_faceRect_CORD[3] - ((POSITION_FACE_BEST_faceRect_CORD[3] - POSITION_FACE_BEST_faceRect_CORD[1]) * this.#POSITION_PAD_SCALE_DET),
        ]
        */
    }

    #log(str){
        if (this.DEBUG_MODE){
            console.log(`${this.#DEBUG_TAG}${str}`)
        }
    }
}
