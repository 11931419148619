import { useRef, useEffect, useState } from "react";
import html2pdf from 'html2pdf.js';

function Test2() {
    const generatePDF = () => {
      const container = document.querySelector('.con');
      const clone = container.cloneNode(true);
  
      // Remove section 3
      const section3 = clone.querySelector('.test_03');
      if (section3) {
        section3.remove();
      }
  
      const opt = {
        margin: 1,
        filename: 'document.pdf',
        image: { type: 'jpeg', quality: 0.98 },
        html2canvas: { scale: 2 },
        jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' }
      };
  
      html2pdf().from(clone).set(opt).output('datauristring').then((pdfData) => {
        // Store PDF Data URI in LocalStorage
        localStorage.setItem('pdfBase64', pdfData);
        alert('PDF stored in LocalStorage');
      });
    };
  

    const downloadPDF = () => {
        const base64data = localStorage.getItem('pdfBase64');
        if (base64data) {
          const link = document.createElement('a');
          link.href = base64data;
          link.download = 'document.pdf';
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        } else {
          alert('No PDF found in LocalStorage');
        }
      };
    

    return (
        <>
            <div className="con">
                <div className="test_01"> 섹션 1 입니다. </div>
                <div className="test_02"> 섹션 2 입니다. </div>
                <div className="test_03"> 섹션 3 입니다. </div>
                <div className="test_04"> 섹션 4 입니다. </div>

                <div className="pdf_btn" onClick={generatePDF}>
                    pdf 로 저장하기
                </div>
        <div className="download_btn" onClick={downloadPDF}>
          PDF 다운로드
        </div>
            </div>
        </>
    ) 
}



export default Test2