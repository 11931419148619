import html2canvas from 'html2canvas';
import { saveAs } from 'file-saver';
import Slider from "react-slick";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import About_luluti from '../about_luluti';
import Skin_score_section from './skin_score_section';
import Skin_concern_slider from './skin_concern_slider';
import Recom_tap_section from './recom_tap_section';
import axios from 'axios';
import html2pdf from 'html2pdf.js';



function SimilarSlider() {
    var data = localStorage.getItem("user_skin_result")
    var img_url = process.env.PUBLIC_URL + "/img/skin_type_result/"
    if(data){
        var gender = JSON.parse(data).customerInfo.sexdstn
        console.log("gender")
        console.log(gender)
        if(gender === "male"){
            
            img_url = process.env.PUBLIC_URL + "/img/skin_type_result_man/"
        }
    }
    var settings = {
        autoplay: true,
        dots: true,
        arrows: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        beforeChange: (current, next) => { },
    };
    return (
        <>
            <Slider {...settings}>
                <div className="similarSlide">
                    <img className="slide_img" src={process.env.PUBLIC_URL + img_url + "NR.png"}></img>
                    <div className="text_box">
                        <div className="sub_headline02">NR</div>
                        <div className="large03">중성</div>
                    </div>
                </div>
                <div className="similarSlide">
                    <img className="slide_img" src={process.env.PUBLIC_URL + img_url + "NRA.png"}></img>
                    <div className="text_box">
                        <div className="sub_headline02">NR-A</div>
                        <div className="large03">알레르기성 중성</div>
                    </div>
                </div>
                <div className="similarSlide">
                    <img className="slide_img" src={process.env.PUBLIC_URL + img_url + "NSA.png"}></img>
                    <div className="text_box">
                        <div className="sub_headline02">NS-A</div>
                        <div className="large03">알레르기성 민감 중성</div>
                    </div>
                </div>
            </Slider>
        </>
    );
}
function InfluencerPickSlider() {
    var settings = {
        autoplay: true,
        dots: true,
        arrows: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
    };
    return (
        <>
            <Slider {...settings}>
                <div className="InfluencerPickSlide">
                    <div className="influencer_info flex al_ce ju_sb">
                        <div className="left flex">
                            <div className="profile_img">
                                <img src={process.env.PUBLIC_URL + "/img/influencer_img.png"}></img>
                            </div>
                            <div className="profile_info">
                                <div className="skin_type label02">
                                    DR-A
                                </div>
                                <div className="influencer_name small01">
                                    김룰루
                                </div>
                            </div>
                        </div>
                        <div className="right flex">
                            <div className="youtube_link">
                                <img src={process.env.PUBLIC_URL + "/img/youtube.png"}></img>
                            </div>
                            <div className="insta_link">
                                <img src={process.env.PUBLIC_URL + "/img/insta.png"}></img>
                            </div>
                        </div>
                    </div>
                    <div className="product_info flex">
                        <div>
                            <div className="product_img">
                                <img src={process.env.PUBLIC_URL + "/img/product_sample_01.png"}></img>
                            </div>
                            <div className="product_brand caption">
                                토리든
                            </div>
                            <div className="product_name label02">
                                다이브인 저분자 히알루론산 세럼 70m
                            </div>
                            <div className="product_price label02">
                                24,000원
                            </div>
                        </div>
                        <div>
                            <div className="product_img">
                                <img src={process.env.PUBLIC_URL + "/img/product_sample_02.png"}></img>
                            </div>
                            <div className="product_brand caption">
                                토리든
                            </div>
                            <div className="product_name label02">
                                다이브인 저분자 히알루론산 세럼 70m
                            </div>
                            <div className="product_price label02">
                                24,000원
                            </div>
                        </div>
                    </div>
                </div>
                <div className="InfluencerPickSlide">
                    <div className="influencer_info flex al_ce ju_sb">
                        <div className="left flex">
                            <div className="profile_img">
                                <img src={process.env.PUBLIC_URL + "/img/influencer_img.png"}></img>
                            </div>
                            <div className="profile_info">
                                <div className="skin_type label02">
                                    DR-A
                                </div>
                                <div className="influencer_name small01">
                                    김룰루
                                </div>
                            </div>
                        </div>
                        <div className="right flex">
                            <div className="youtube_link">
                                <img src={process.env.PUBLIC_URL + "/img/youtube.png"}></img>
                            </div>
                            <div className="insta_link">
                                <img src={process.env.PUBLIC_URL + "/img/insta.png"}></img>
                            </div>
                        </div>
                    </div>
                    <div className="product_info flex">
                        <div>
                            <div className="product_img">
                                <img src={process.env.PUBLIC_URL + "/img/product_sample_01.png"}></img>
                            </div>
                            <div className="product_brand caption">
                                토리든
                            </div>
                            <div className="product_name label02">
                                다이브인 저분자 히알루론산 세럼 70m
                            </div>
                            <div className="product_price label02">
                                24,000원
                            </div>
                        </div>
                        <div>
                            <div className="product_img">
                                <img src={process.env.PUBLIC_URL + "/img/product_sample_02.png"}></img>
                            </div>
                            <div className="product_brand caption">
                                토리든
                            </div>
                            <div className="product_name label02">
                                다이브인 저분자 히알루론산 세럼 70m
                            </div>
                            <div className="product_price label02">
                                24,000원
                            </div>
                        </div>
                    </div>
                </div>
                <div className="InfluencerPickSlide">
                    <div className="influencer_info flex al_ce ju_sb">
                        <div className="left flex">
                            <div className="profile_img">
                                <img src={process.env.PUBLIC_URL + "/img/influencer_img.png"}></img>
                            </div>
                            <div className="profile_info">
                                <div className="skin_type label02">
                                    DR-A
                                </div>
                                <div className="influencer_name small01">
                                    김룰루
                                </div>
                            </div>
                        </div>
                        <div className="right flex">
                            <div className="youtube_link">
                                <img src={process.env.PUBLIC_URL + "/img/youtube.png"}></img>
                            </div>
                            <div className="insta_link">
                                <img src={process.env.PUBLIC_URL + "/img/insta.png"}></img>
                            </div>
                        </div>
                    </div>
                    <div className="product_info flex">
                        <div>
                            <div className="product_img">
                                <img src={process.env.PUBLIC_URL + "/img/product_sample_01.png"}></img>
                            </div>
                            <div className="product_brand caption">
                                토리든
                            </div>
                            <div className="product_name label02">
                                다이브인 저분자 히알루론산 세럼 70m
                            </div>
                            <div className="product_price label02">
                                24,000원
                            </div>
                        </div>
                        <div>
                            <div className="product_img">
                                <img src={process.env.PUBLIC_URL + "/img/product_sample_02.png"}></img>
                            </div>
                            <div className="product_brand caption">
                                피지오겔
                            </div>
                            <div className="product_name label02">
                                DMT 페이셜 크림 100ml
                            </div>
                            <div className="product_price label02">
                                29,900원
                            </div>
                        </div>
                    </div>
                </div>
            </Slider>
        </>
    );
}



function Skin_result_ns() {

    const location = useLocation()
    const params = useParams() 
    let navigate = useNavigate()
    const [modal_btn, setModal_btn] = useState()
    const [result, setResult] = useState()
    const [loading , setLoading] = useState(false)
    
    function generatePDF() {
        const container = document.querySelector('.sub_body');
        const clone = container.cloneNode(true);
        const width = container.offsetWidth;
    
        // 제거할 요소들
        const elementsToRemove = [
            '.recom_product_tap_box',
            '.recom_product_box',
            '.capture_btn',
            '.share_btn',
            '.share_wrap',
            ".similar_type > .slick-slider",
            ".skin_problem_wrap > .slider_wrap",
            ".skin_problem_wrap > .label02",
            ".skin_problem_wrap > .skin_analyzed_img",
            ".recom_product_tap_box",
            ".recom_product_box",
            ".influencer_pick",
            ".skin_rank + .gray_line.thick",
            ".result_capture_area .luluti_info .home_btn"
        ];
    
        // 모든 요소의 높이를 계산하고 제거
        elementsToRemove.forEach(selector => {
            const element = document.querySelector(selector);
            if (element) {
                const styles = window.getComputedStyle(element);
                const heightToRemove = element.offsetHeight + parseFloat(styles.marginTop) + parseFloat(styles.marginBottom);
                const cloneElement = clone.querySelector(selector);
                if (cloneElement) {
                    cloneElement.remove();
                }
            }
        });
    
        // 클론을 문서에 추가하여 높이 측정 가능
        document.body.appendChild(clone);
    
        // PDF 비슷한 타입
        const existingElements = document.querySelectorAll('.similar_type .similarSlide');
        const indicesToAdd = [0, 1, 2];
        indicesToAdd.forEach(index => {
            if (existingElements[index]) {
                const existingElement = existingElements[index];
                const clonedElement = existingElement.cloneNode(true);
                clonedElement.classList.add('pdf_similar_img');
    
                // 지정한 노드 다음에 기존 요소 추가
                const referenceElement = clone.querySelector('.similar_type .title');
                if (referenceElement) {
                    referenceElement.insertAdjacentElement('afterend', clonedElement);
                } else {
                    clone.appendChild(clonedElement);
                }
            }
        });
    
        // 유저 분석 이미지 불러오기
        let result = JSON.parse(localStorage.getItem("user_skin_result"));
        let imglist, 주름, 모공, 트러블, 색소침착, 다크서클, 붉은기, 피지, 탄력, 광채, 피부결;
        주름 = Math.round(result.wrinkle);
        모공 = Math.round(result.pore);
        트러블 = Math.round(result.acne);
        색소침착 = Math.round(result.melanin);
        다크서클 = Math.round(result.darkcircle);
        붉은기 = Math.round(result.hemoglobin);
        탄력 = Math.round(result.elasticity);
        광채 = Math.round(result.radiance);
        피부결 = Math.round(result.texture);
        imglist = result.imageUrlSet.overlay;
    
        let skinMetrics = [
            { name: "주름", value: 주름, img: imglist.wrinkle, logoNum: "00" },
            { name: "모공", value: 모공, img: imglist.pore, logoNum: "01" },
            { name: "트러블", value: 트러블, img: imglist.acne, logoNum: "02" },
            { name: "색소침착", value: 색소침착, img: imglist.melanin, logoNum: "03" },
            { name: "다크서클", value: 다크서클, img: imglist.darkcircle, logoNum: "04" },
            { name: "붉은기", value: 붉은기, img: imglist.hemoglobin, logoNum: "05" },
            { name: "탄력", value: 탄력, img: imglist.elasticity, logoNum: "06" },
            { name: "광채", value: 광채, img: imglist.radiance, logoNum: "07" },
            { name: "피부결", value: 피부결, img: imglist.texture, logoNum: "08" }
        ];
        let sample = skinMetrics[0];
        skinMetrics.sort((a, b) => a.value - b.value);
    
        // 피부고민 HTML 만들기
        let newElementHTML = `<div class="pdf_user_data_wrap">`;
        skinMetrics.forEach(metric => {
            if (metric.img) {
                newElementHTML += `
                <div class="pdf_user_data_box">
                    <div class="data_label_box label02">
                        ${metric.name} | Lv.${metric.value}
                    </div>
                    <div class="data_img_box">
                        <img crossorigin="anonymous" src="${metric.img}">
                        <div class="img_empty_overlay"></div>
                    </div>
                </div>`;
            } else {
                newElementHTML += `
                <div class="pdf_user_data_box">
                    <div class="data_label_box label02">
                        ${metric.name} | Lv.${metric.value}
                    </div>
                    <div class="data_img_box">
                        <img src="${sample.img}" style="opacity:0;">
                        <div class="img_empty_overlay"></div>
                    </div>
                </div>`;
            }
        });
    
        // 빈칸 채우기
        newElementHTML += `
        <div class="pdf_user_data_box">
            <div class="data_label_box label02" style="opacity:0;">
                ${sample.name} | Lv.${sample.value}
            </div>
            <div class="data_img_box">
                <img src="${sample.img}" style="opacity:0;">
                <div class="img_empty_overlay"></div>
            </div>
        </div>`;
    
        newElementHTML += `</div>`;
    
        const referenceElement = clone.querySelector('.skin_problem_wrap .title');
        if (referenceElement) {
            referenceElement.insertAdjacentHTML('afterend', newElementHTML);
        } else {
            clone.appendChild(document.createElement('div')).innerHTML = newElementHTML;
        }
    
        // // 추천화장품
        // let recomElementHTML = `<div class="pdf_recom_data_wrap">
        //     <div class="title_box headline02" style="text-align: center; color: var(--primary-700); margin-bottom: 3.2rem; margin-top: 4rem;">
        //         나에게 맞는 피부 고민별 <br> 화장품 추천은?
        //     </div>`;
        // skinMetrics.forEach(metric => {
        //     recomElementHTML += `
        //     <div class="recom_type">
        //         <div class="pdf_recom_type_title large03" style="display: flex; align-items: center; justify-content: flex-start;">
        //             <img style="width:3rem;" src="${process.env.PUBLIC_URL}/img/skin_icon_color/skin_concern${metric.logoNum}.png">
        //             <div>${metric.name}에 효과적인</div>
        //         </div>
        //         <div class="pdf_recom_product">
        //             <div class="img_box">
        //                 <img src="${process.env.PUBLIC_URL}/img/sell_sample.png">
        //             </div>
        //             <div class="pdf_pro_info">
        //                 <div class="pdf_pro_com label02">피지오겔</div>
        //                 <div class="pdf_pro_name small02">DMT 페이셜크림 100ml</div>
        //                 <div class="pdf_pro_price large02">29,900원</div>
        //             </div>
        //         </div>
        //     </div>`;
        // });
        // recomElementHTML += `</div>`;
    
        // const referenceRecomElement = clone.querySelector('.skin_problem_wrap + .gray_line');
        // if (referenceRecomElement) {
        //     referenceRecomElement.insertAdjacentHTML('afterend', recomElementHTML);
        // } else {
        //     clone.appendChild(document.createElement('div')).innerHTML = recomElementHTML;
        // }
    
        setTimeout(() => {
            // 클론된 요소의 최종 높이 측정
            const finalHeight = clone.scrollHeight;
        
            // PDF 옵션 설정
            const opt = {
                margin: [10, 20, 10, 20],
                filename: 'document.pdf',
                html2canvas: { scale: 1, useCORS: true },
                jsPDF: { unit: 'pt', format: [width, finalHeight], orientation: 'portrait' }
            };
        
            // PDF 생성 및 다운로드
            // html2pdf().from(clone).set(opt).save();
        
            // 데이터 저장
            html2pdf().from(clone).set(opt).toPdf().output('datauristring').then((pdfData) => {
                // Store PDF Data URI in LocalStorage
                localStorage.setItem('pdfBase64', pdfData);
            });
            // 클론된 요소를 문서에서 제거
            document.body.removeChild(clone);
        },500)
    } 

    let request_data_time = 0

    function request_data(jobId) {
        axios.get("https://websdk.lululab-platform.com/api/skin-analysis/results/" + jobId , {
            headers: {
                'x-secret': '2a69f75f-0637-4c0c-852e-5e2502edcdfe',
                'x-license': '067d32d1be894d79a4bb',
                'Content-Type': 'application/json',
            }
        })
        .then((response) => {
            if(response.data.error == "202 ACCEPTED" ){
                alert("잘못된 접근입니다. 확인을 눌러 메인페이지로 돌아갑니다.")
                navigate("/")
            } else{
                let receive_data = JSON.stringify(response.data.data)
                localStorage.setItem("user_skin_result" , receive_data)
                setResult(response.data.data)
                console.log("result")
                console.log(result)
                setLoading(true)
            }
            // setSkinType(response.data);
        })
        .catch((err) => {
            console.log("결과데이터 조회 실패")
            alert("잘못된 접근입니다. 확인을 눌러 메인페이지로 돌아갑니다.")
            navigate("/")
        });
    }


    useEffect(()=>{
        console.log(params.jobId)
        request_data(params.jobId)
        document.querySelector(".sub_page").classList.add("up_prepare")
        setTimeout(() => {
            document.querySelector(".sub_page").classList.add("up_active")
        }, 100);
    },[])
    
    const handleDownload = () => {
        const element = document.querySelector('.result_capture_area');
        html2canvas(element).then((canvas) => {
            canvas.toBlob((blob) => {
                saveAs(blob, 'capture.png');
                document.querySelector(".toast_message").classList.add("active")
                setTimeout(() => {
                document.querySelector(".toast_message").classList.remove("active")
                }, 1000);
            });
        });
    }

    var data = localStorage.getItem("user_skin_result")
    var img_url = process.env.PUBLIC_URL + "/img/skin_type_result/"
    
    if(data){
        var gender = JSON.parse(data).customerInfo.sexdstn
        console.log("gender")
        console.log(gender)
        if (gender === "male") {  
            
            img_url = process.env.PUBLIC_URL + "/img/skin_type_result_man/"
        }
    }
    function Share_link() {
        let currentUrl = window.location.href;
        if (!currentUrl.includes("#share")) {
            currentUrl += "#share";
        }
    
        window.navigator.share({
            title: document.title,
            text: "LuLuTI 피부 분석하기",
            url: currentUrl
        });
    }
    return (
        <div className="sub_page">
        <div className='toast_message'>
            이미지 저장 완료
        </div>
        {modal_btn == 1 ?
        <About_luluti setModal_btn={setModal_btn}></About_luluti>
        : null }
        {loading?  
            <div className="sub_body_wrap" style={{ height: "100vh" }}>
                <div className="sub_body" style={{ paddingTop: "0" }}>
                    <div className="result_capture_area con" style={{ paddingTop: "2rem" }}>
                        <div className="luluti_info small02 flex al_ce ju_ce" onClick={() => {
                            setModal_btn(1)
                        }}>
                            LuLuTI 결과
                            <svg xmlns="http://www.w3.org/2000/svg" width="2.4rem" height="2.4rem" viewBox="0 0 24 24" fill="none">
                                <circle cx="12" cy="12" r="8.5" stroke="#C4C4C4" />
                                <path d="M11.2464 13.8851C11.2584 12.2554 11.7488 11.7568 12.5742 11.2459C13.0646 10.9419 13.4474 10.4554 13.4474 9.77432C13.4474 8.9473 12.8134 8.42432 12.0239 8.42432C11.3421 8.42432 10.6603 8.83784 10.5885 9.83514H9.5C9.55981 8.36351 10.6603 7.5 12.0239 7.5C13.5191 7.5 14.5 8.48514 14.5 9.82297C14.5 10.7716 14.0574 11.4405 13.3158 11.9027C12.5742 12.3649 12.299 12.827 12.2751 13.8851V13.9459H11.2464V13.8851ZM11.7967 16.5C11.378 16.5 11.0191 16.1473 11.0311 15.7095C11.0191 15.2838 11.378 14.9311 11.7967 14.9311C12.2153 14.9311 12.5622 15.2838 12.5622 15.7095C12.5622 16.1473 12.2153 16.5 11.7967 16.5Z" fill="#C4C4C4" />
                            </svg>
                            <div className='home_btn' onClick={()=>{navigate("/")}}>
                                <img src={process.env.PUBLIC_URL + "/img/home.svg"}></img>
                            </div>
                        </div>
                        <div className="type_title">
                            <div className="headline01">
                                NS
                            </div>
                            <div className="sub_headline02">
                                민감 중성
                            </div>
                            <div className="small02">
                                <span>Neutral</span>
                                <span>|</span>
                                <span>Sensitive</span>
                            </div>
                        </div>
                        <div className="type_img">
                            <img src={process.env.PUBLIC_URL + img_url + "NS.png"}></img>
                        </div>
                        <div className="type_info large03">
                            얇은 피부 장벽으로 외부 자극에
                            <br />
                            쉽게 반응하는 <span>민감 중성</span> 피부에요.
                        </div>
                        <div className="gray_line"></div>
                        <div className="balance">
                            <div className="small02 flex ju_ce">
                                유수분 밸런스
                            </div>
                            <div className="balance_bar">
                                <div className="balance_circle" style={{ left: 50 + "%" }}></div>
                            </div>
                            <div className="text_box flex al_ce ju_sb">
                                <div className="dry">
                                    <div className="small02">
                                        건성
                                    </div>
                                    <div className="lable02">
                                        Dry
                                    </div>
                                </div>
                                <div className="neutral">
                                    <div className="small02">
                                        중성
                                    </div>
                                    <div className="lable02">
                                        Neutral
                                    </div>
                                </div>
                                <div className="oily">
                                    <div className="small02">
                                        지성
                                    </div>
                                    <div className="lable02">
                                        Oily
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="sensitive result_type_wrap">
                            <div className="title small02 flex al_ce ju_ce">
                                피부 민감도
                            </div>
                            <div className="result_type_box">
                                <div className="result_type on">
                                    <div className="ko flex al_ce ju_ce">
                                        민감성
                                    </div>
                                    <div className="en flex al_ce ju_ce">
                                        Sensitive
                                    </div>
                                </div>
                                <div className="result_type ">
                                    <div className="ko flex al_ce ju_ce">
                                        비민감성
                                    </div>
                                    <div className="en flex al_ce ju_ce">
                                        Insensitive
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="allergy result_type_wrap">
                            <div className="title small02 flex al_ce ju_ce">
                                알레르기 여부
                            </div>
                            <div className="result_type_box">
                                <div className="result_type">
                                    <div className="ko flex al_ce ju_ce">
                                        알레르기성
                                    </div>
                                    <div className="en flex al_ce ju_ce">
                                        Allergic
                                    </div>
                                </div>
                                <div className="result_type on">
                                    <div className="ko flex al_ce ju_ce">
                                        비알레르기성
                                    </div>
                                    <div className="en flex al_ce ju_ce">
                                        Non-allergic
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div className="con">
                                <div className="capture_btn primary_fill_btn flex al_ce ju_ce small01" onClick={handleDownload}>
                                    내 피부 타입 저장
                                    <svg xmlns="http://www.w3.org/2000/svg" width="19" height="18" viewBox="0 0 19 18" fill="none">
                                        <path d="M14 7.69446L9.49999 12.1945L4.99998 7.69447" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                        <line x1="0.75" y1="-0.75" x2="11.25" y2="-0.75" transform="matrix(-4.37114e-08 -1 -1 4.37114e-08 8.8252 12)" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                        <path d="M1.5 12.5V15.5C1.5 16.0523 1.94772 16.5 2.5 16.5H16.5C17.0523 16.5 17.5 16.0523 17.5 15.5V12.5" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                    </svg>
                                </div> 
                        <div className="gray_line"></div>
                            <div className="similar_type">
                                <div className="title sub_headline01">
                                    <span>NS</span>와 <br />비슷한 피부 타입
                                </div>
                                <SimilarSlider></SimilarSlider>


                                    <div className="gray_line_btn share_btn small01"
                                        onClick={() => {
                                            Share_link()
                                        }}>친구에게 공유하고 비슷한 타입 찾기
                                        <svg xmlns="http://www.w3.org/2000/svg" width="17" height="19" viewBox="0 0 17 19" fill="none">
                                            <path d="M13.5 6L8.5 1L3.5 6" stroke="#666666" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                            <line x1="8.5" y1="1.75" x2="8.5" y2="13.75" stroke="#666666" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                            <path d="M13.5444 10H14.5C15.0523 10 15.5 10.4477 15.5 11V17C15.5 17.5523 15.0523 18 14.5 18H2.5C1.94772 18 1.5 17.5523 1.5 17V11C1.5 10.4477 1.94772 10 2.5 10H3.45556" stroke="#666666" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                        </svg>
                                    </div>
                            </div>
                            <div className="gray_line thick"></div>
                                <Skin_concern_slider result={result}></Skin_concern_slider>
                                <div className="gray_line"></div>
                                <Recom_tap_section result={result}></Recom_tap_section>
                                <div className="gray_line thick"></div>
                                <Skin_score_section result={result}></Skin_score_section>
                                <div className="gray_line thick"></div>

                            {/*<div className="influencer_pick">*/}
                            {/*    <div className="title small02">*/}
                            {/*        같은 타입 인플루언서의 추천 아이템*/}
                            {/*    </div>*/}
                            {/*    <InfluencerPickSlider></InfluencerPickSlider>*/}
                            {/*</div>*/}
                            <div className="share_wrap">
                                <div className="share_box">
                                    <div className="btn_box">
                                        {window.location.hash !== "#share" ?
                                            <div className="mail_share_btn primary_fill_btn small01" onClick={() => {
                                                generatePDF()
                                                navigate("/email_form")
                                            }}>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="2.1rem" height="1.6rem" viewBox="0 0 21 16" fill="none">
                                                    <path d="M18.6004 2.14307L10.5004 9.34307L2.40039 2.14307" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                    <rect x="1.5" y="1.24316" width="18" height="13.5" rx="1" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                </svg>
                                                메일로 LuLuTI 리포트 받기
                                            </div>
                                            :
                                            <div className="mail_share_btn primary_fill_btn small01" onClick={() => { navigate("/info", { state: "info" }) }}>
                                                AI 피부 분석 시작하기
                                            </div>}
                                        <div className="all_skin_type_btn primary_btn small01" onClick={() => {
                                            navigate("/skin_type_all", { state: location.pathname, })
                                        }}>
                                            모든 피부타입 보기
                                        </div>
                                    </div>
                                        <div className="text label02">
                                            친구에게 공유하고 <br />나와 비슷한 피부 타입을 찾아보세요
                                        </div>
                                        <div className="gray_line_btn btm_share share_btn small01" onClick={() => {Share_link()}}>친구에게 공유하고 비슷한 타입 찾기
                                            <svg xmlns="http://www.w3.org/2000/svg" width="17" height="19" viewBox="0 0 17 19" fill="none">
                                                <path d="M13.5 6L8.5 1L3.5 6" stroke="#666666" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                <line x1="8.5" y1="1.75" x2="8.5" y2="13.75" stroke="#666666" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                <path d="M13.5444 10H14.5C15.0523 10 15.5 10.4477 15.5 11V17C15.5 17.5523 15.0523 18 14.5 18H2.5C1.94772 18 1.5 17.5523 1.5 17V11C1.5 10.4477 1.94772 10 2.5 10H3.45556" stroke="#666666" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                            </svg>
                                        </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                : console.log("로딩중")}
        </div>
    )
}



export default Skin_result_ns