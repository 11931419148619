import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";

function SurveyLoading() {
    let navigate = useNavigate();
    let [loading, setLoading] = useState(0);
    let [loadingText, setLoadingText] = useState("피부 상태 살펴보는 중");
    let [sendReady, setSendReady] = useState(false);
    let [requestReady, setRequestReady] = useState(false);
    let [skinType, setSkinType] = useState("nr");
    let [skinScore, setSkinScore] = useState(90);
    let [fail_modal_btn , setFail_modal_btn] = useState(false)
    let request_data_time = 0 
    let jobId = localStorage.getItem("jobId")
    let [errText , setErrText] = useState("")
    let {state} = useLocation()

    function set_err_message_type01(err_code , err_message){
        var tem_text = "오류가 발생했습니다."
        if (err_code == "FAILED_ANALYZE_REQUESTED_IMAGE") {
            const regex = /ERR_(\w{5})/;
            const match = regex.exec(err_message);
            if(match[1] == "LM001"){
                tem_text = "얼굴을 찾지 못했어요. 촬영을 다시 진행 해주세요."
            } else if(match[1] == "LM002"){
                tem_text = "촬영된 얼굴이 작아요. 조금 더 가까이 촬영 해주세요."
            } else if(match[1] == "LM003" || match[1] == "SA001"){
                tem_text = "얼굴이 잘린 것 같아요. 잘리지 않도록 다시 촬영 해주세요."
            } else if(match[1] == "IQ001"){
                tem_text = "얼굴이 흐릿해요. 렌즈를 닦은 후 다시 촬영 해주세요."
            } else if(match[1] == "IQ003"){
                tem_text = "촬영된 마스크를 착용 하셨나요? 마스크를 벗고 다시 촬영 해주세요."
            } else {
                tem_text = "AI 분석에 실패 했어요. 피부 촬영을 다시 진행 해주세요."
            }
        } else if (err_code == "JOBID_NOT_FOUND") {
            tem_text = `오류가 발생했어요. AI 분석을 다시 시도해 주세요. `
        } else if (err_code == "FAILED_ANALYZE_REQUESTED_IMAGE"){
        }
        setErrText(tem_text)
    }
    
    function request_data(jobId) {
        setTimeout(() => {
            axios.get("https://websdk.lululab-platform.com/api/skin-analysis/results/" + jobId , {
                headers: {
                    'x-secret': '2a69f75f-0637-4c0c-852e-5e2502edcdfe',
                    'x-license': '067d32d1be894d79a4bb',
                    'Content-Type': 'application/json',
                }
            })
            .then((response) => {
                console.log("분석결과확인")
                if(response.data.error == "202 ACCEPTED" ){
                    if(request_data_time >= 10){
                        console.log("202")
                        setFail_modal_btn(1)
                        // alert("문제가 발생하였습니다. 다시 촬영해 주세요")
                        // navigate("/camara_api")
                    }
                    request_data_time++
                    request_data(jobId)
                } else{
                    let receive_data = JSON.stringify(response.data.data)
                    localStorage.setItem("user_skin_result" , receive_data)
                    setSkinScore(jobId)
                    setSendReady(true);
                    survey_result_call(jobId)
                    // setSkinType(response.data);
                }
            })
            .catch((err) => {
                console.log("데이터요청")
                console.log(err.response.data.code)
                set_err_message_type01(err.response.data.code , err.response.data.message)
                // setErrText(err.response.data.message)
            });
        }, 1000);
    }

    function set_err_message_type02(err_code , err_message){
        var tem_text = "오류가 발생했습니다."
        if (err_code == "JOB_FAILED_ANALYSIS") {
            tem_text = "AI 분석에 실패했어요. 피부 촬영을 다시 진행해 주세요."
        } else if (err_code == "JOBID_NOT_FOUND") {
            tem_text = `오류가 발생했어요. AI 분석을 다시 시도해 주세요. `
        } else if (err_code == "FAILED_ANALYZE_REQUESTED_IMAGE"){
            const regex = /ERR_(\w{5})/;
            const match = regex.exec(err_message);
            if(match[1] == "LM001"){
                tem_text = "얼굴을 찾지 못했어요. 촬영을 다시 진행 해주세요."
            } else if(match[1] == "LM002"){
                tem_text = "촬영된 얼굴이 작아요. 조금 더 가까이 촬영 해주세요."
            } else if(match[1] == "LM003" || match[1] == "SA001"){
                tem_text = "얼굴이 잘린 것 같아요. 잘리지 않도록 다시 촬영 해주세요."
            } else if(match[1] == "IQ001"){
                tem_text = "얼굴이 흐릿해요. 렌즈를 닦은 후 다시 촬영 해주세요."
            } else if(match[1] == "IQ003"){
                tem_text = "촬영된 마스크를 착용 하셨나요? 마스크를 벗고 다시 촬영 해주세요."
            } else {
                tem_text = "AI 분석에 실패 했어요. 피부 촬영을 다시 진행 해주세요."
            }
        }
        setErrText(tem_text)
    }
    
    function survey_result_call(jobId){
        console.log("피부설문결과 확인")
        let survey_data = JSON.parse(localStorage.getItem("survey_result"))
        let send_data = {
            "jobId": jobId ,
            "survey": survey_data,
            "command": "category",
            "options": {}
        }
        // 서버로 전송하기
        axios.post("https://websdk.lululab-platform.com/api/recommended/cosmetics", send_data, {
            headers: {
                'x-secret': '2a69f75f-0637-4c0c-852e-5e2502edcdfe',
                'x-license': '067d32d1be894d79a4bb',
                'Content-Type': 'application/json',
            },
        })
            .then((result) => {
                // 전송 성공시 uid 저장 및 분석요청진행
                setSkinType(result.data.data.userSkinType)
                setRequestReady(true);
            })
            .catch((err) => {
                console.log("설문결과")
                console.log(err.response.data.code)
                set_err_message_type02(err.response.data.code , err.response.data.message)
                // setErrText(err.response.data.message)
            })
    }
    
    useEffect(()=>{
        setTimeout(() => {
            if(errText == "") {
                setFail_modal_btn(false)
            } else{
                setFail_modal_btn(true)
            }
        }, 4000);
    }, [errText])

    useEffect(() => {
        console.log(state)
        if(state !== "survey_loading"){
            navigate("/")
        }
        request_data(jobId)
    }, [])

    useEffect(() => {

        const interval = setInterval(() => {
            setLoading((prevLoading) => {
                let newLoading = prevLoading + 1;
                if (newLoading <= 35) {
                    setLoadingText("피부 상태 살펴보는 중");
                } else if (newLoading < 70) {
                    setLoadingText("피부 문진 확인하는 중");
                }  else if (newLoading < 100) {
                    setLoadingText("피부 문진 확인하는 중");
                } else if (newLoading === 100 && requestReady && sendReady) {
                    setLoadingText("LuLuTI 리포트 작성 완료");
                    console.log(sendReady , requestReady)
                    document.querySelector(".transition_desolve").classList.add("active");
                    setTimeout(() => {
                        navigate("/result/" + skinType + "/" + skinScore)
                    }, 1500);
                }
                return newLoading > 100 ? 100 : newLoading;
            });
        }, 100);

        return () => clearInterval(interval);
    }, [sendReady, skinType, navigate]);

    return (
        
        <div className="sub_page">
            <div className="sub_body_wrap">
                <div className="sub_body">
                    <div className="con transition_desolve">
                        <div className="loading_wrap">
                            <div className="percent small01">
                                {loading}%
                            </div>
                            <div className="survey_loading">
                                <div className="progress_bar" style={{ width: loading + "%", }}></div>
                            </div>
                            <div className="percent caption">
                                {loadingText}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
            {fail_modal_btn? 
              <div className="fail_modal_wrap">
                <div className="fail_modal">
                  <div className="large01">
                    오류발생
                  </div>
                  <div className="small02">
                    {errText}
                  </div>
                  <div className="fail_btn_wrap">
                    <div className="small01 p200_btn" onClick={()=>{
                      navigate("/camara_guide",{state:"camara_guide"})
                    }}>
                      확인
                    </div>
                  </div>
                </div>
              </div>
            :
            null}
        </div>
        
    );
}

export default SurveyLoading;
