export async function checkPermission(){
    navigator.permissions.query({ name: 'camera' })
    .then((permissionObj) => {
        console.log(permissionObj.state);
        // permission = permissionObj.state;
    })
    .catch((error) => {
        console.log('Got error :', error);
    });

    async function askCameraPermission() {
        try {
            const stream = await navigator.mediaDevices.getUserMedia({ video: true });
            return stream;
        } catch (error) {
            console.error('Error accessing camera:', error);
            return null;
        }
    }

    let localStream; // Declare without type annotation as not explicitly required in JavaScript

    askCameraPermission()
    .then(stream => {
        localStream = stream;
        // Handle potential null value by adding a check before stopping tracks:
        if (localStream) {
            localStream.getTracks().forEach(track => {
                track.stop();
            });
        }
        else {
            console.warn('Camera access not granted or stream is null');
        }
    })
    .catch(error => {
        console.error('Error accessing camera:', error);
    });
}