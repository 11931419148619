import { useNavigate } from "react-router-dom"



function Sub_top_bar({title , close , gotoLink}){
    
    let navigate = useNavigate()
    let url = -1

    return (
        <>
            <div className="sub_top_bar small02">
                {close ?  
                    <div className="back_btn" onClick={()=>{
                        if(gotoLink){
                            gotoLink()
                        }else{
                            navigate(url)
                        }
                    }}>
                        <img style={{width:"1.4rem"}} src={process.env.PUBLIC_URL+"/img/clo_btn.svg"}/>
                    </div>
                :  
                <div className="back_btn" onClick={()=>{
                    if(gotoLink){
                        gotoLink()
                    }else{
                        navigate(url)
                    }
                }}>
                    <img style={{width:"0.8rem"}} src={process.env.PUBLIC_URL+"/img/back_arrow.png"}/>
                </div>
                }
                <div className="sub_page_top_title">
                    {title}
                </div>
                <div className="home_btn" onClick={()=>{
                        navigate("/")
                }}>
                        <img style={{width:"1.4rem"}} src={process.env.PUBLIC_URL+"/img/home.svg"}/>
                </div>
            </div>
        </>
    )
}


export default Sub_top_bar