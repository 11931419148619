import { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";

function Skin_score_section({result}){
    
    const user_score = result.skin_score * 10; // URL에서 score 파라미터를 가져옴
    const user_rank = result.skin_rank_percent; // URL에서 score 파라미터를 가져옴
    const user_average = result.skin_rank_percent_score; // URL에서 score 파라미터를 가져옴
    const targetRef = useRef(null);
    
    const [score, setScore] = useState(0);
    const [rank, setRank] = useState(0);
    const [average, setAverage] = useState(0);
    const [popper, setPopper] = useState(0)

    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                if (entry.isIntersecting) {
                    console.log('Target div is in view!');
                    console.log(user_score);

                    const totalDuration = 1500; // 총 지속 시간 (ms)
                    const increment = 1; // 업데이트당 증가량
                    const updatesPerSecond_score = (user_score / increment) / (totalDuration / 1000); // 초당 업데이트 횟수
                    const intervalTime_score = 1000 / updatesPerSecond_score; // 각 업데이트 간격(ms)

                    const interval_score = setInterval(() => {
                        setScore(prevScore => {
                            if (prevScore < user_score) {
                                return prevScore + increment;
                            } else {
                                clearInterval(interval_score);
                                return prevScore;
                            }
                        });
                    }, intervalTime_score);
                    
                    const updatesPerSecond_rank = (user_rank / increment) / (totalDuration / 1000); // 초당 업데이트 횟수
                    const intervalTime_rank = 1000 / updatesPerSecond_rank; // 각 업데이트 간격(ms)
                    const interval_rank = setInterval(() => {
                        setRank(prevRank => {
                            if (prevRank < user_rank) {
                                return prevRank + increment;
                            } else {
                                clearInterval(interval_rank);
                                return prevRank;
                            }
                        });
                    }, intervalTime_rank);

                    const updatesPerSecond_average = (user_average / increment) / (totalDuration / 1000); // 초당 업데이트 횟수
                    const intervalTime_average = 1000 / updatesPerSecond_average; // 각 업데이트 간격(ms)
                    const interval_average = setInterval(() => {
                        setAverage(prevAverage => {
                            if (prevAverage < user_average) {
                                return prevAverage + increment;
                            } else {
                                clearInterval(interval_average);
                                return prevAverage;
                            }
                        });
                    }, intervalTime_average);

                    setTimeout(() => {
                        clearInterval(interval_score);
                        clearInterval(interval_rank);
                        clearInterval(interval_average);
                        setPopper(1)
                        var full_bar_width = document.querySelector(".rank_bar_wrap .rank_bar").clientWidth
                        var bar_width = document.querySelector(".rank_bar_wrap .rank_bar .bar").clientWidth
                        var remain_width = document.querySelector(".rank_bar .remain").clientWidth
                        var remain_max_position = full_bar_width - remain_width

                        var fin_remain_position = bar_width

                        if(remain_max_position < bar_width){
                            fin_remain_position = (remain_max_position/10) + "rem"
                        } else if(bar_width < remain_width){
                            fin_remain_position = 0
                        }else{
                            fin_remain_position = (fin_remain_position/10 )+ "rem"
                        }

                        console.log(fin_remain_position)
                        document.querySelector(".rank_bar .remain").style.left = fin_remain_position
                        document.querySelector(".rank_bar .remain").classList.add("active")
                        
                    }, totalDuration); // 2초 후에 인터벌 종료
                    setTimeout(() => {
                        setPopper(0)
                    }, 4000);
                }
            },
            {
                root: null, // 뷰포트를 기준으로 함
                rootMargin: '0px',
                threshold: 0.3 // 30% 이상 보일 때 트리거됨
            }
        );

        const targetElement = targetRef.current;
        if (targetElement) {
            observer.observe(targetElement);
        }
        console.log(average)
        return () => {
            if (targetElement) {
                observer.unobserve(targetElement);
            }
        };
    }, [user_score]);

    return (
        <div className="skin_rank" ref={targetRef}>
            <div className="title small02">
                내 피부 랭킹은?
            </div>
            {user_score >= 0 && user_score < 40 ? 
            <>
                <div className="rank_text headline02">
                    오늘도 버텨냈다. 쿠크다스 피부
                </div>
                <div className="rank_img">
                    <img src={process.env.PUBLIC_URL + "/img/bronze_cr.png"}></img>
                </div> 
            </>
            : null}
            {user_score >= 40 && user_score < 70 ? 
            <>
                <div className="rank_text headline02">
                보통은 간다. 무난 피부
                </div>
                <div className="rank_img">
                    <img src={process.env.PUBLIC_URL + "/img/silver_cr.png"}></img>
                </div> 
            </>
            : null}
            {user_score >= 70 && user_score < 90 ? 
            <>
                <div className="rank_text headline02">
                    이쁨받는 생존력 만땅 피부
                </div>
                <div className="rank_img">
                    <img src={process.env.PUBLIC_URL + "/img/gold_cr.png"}></img>
                </div> 
            </>
            : null}
            {user_score >= 90 && user_score <= 100 ? 
            <>
                <div className="rank_text headline02">
                결점 하나 없는 다이아몬드 피부
                </div>
                <div className="rank_img">
                    <img src={process.env.PUBLIC_URL + "/img/platinum_cr.png"}></img>
                </div> 
            </>
            : null}
            <div className="rank_score sub_headline02">
                피부점수 <span className="score">{score}점</span>
            </div>
            <div className="score_rating flex al_ce ju_sa">
                <div className="top_ration">
                    <div className="small02">
                        피부 상위
                    </div>
                    <div className="large02">
                        {rank}%
                    </div>
                </div>
                <div className="age_average">
                    <div className="small02">
                        또래 나이 평균
                    </div>
                    <div className="large02">
                        {average}점
                    </div>
                </div>
            </div>
            <div className="rank_bar_wrap">
                <div className="rank_bar">
                    <div className="bar" style={{ width: score+"%", }}>
                        <div className="remain">
                            플래티넘까지 <span>{90 - score}점</span> 남았어요!
                        </div>
                        <div className='arrow'></div>
                    </div>
                </div>
                <div className="rank_table flex al_ce ju_sb caption">
                    <div>
                        0
                    </div>
                    <div>
                        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="11" viewBox="0 0 14 11" fill="none">
                            <g clip-path="url(#clip0_2795_7784)">
                                <path d="M12.8703 8.26863L13.8069 2.55381C13.8567 2.24931 13.5451 2.01626 13.2733 2.15429L9.95897 3.83728C9.79266 3.92182 9.59022 3.8687 9.48512 3.71271L7.22023 0.354594C7.07236 0.135383 6.75375 0.135383 6.60588 0.354594L4.41179 3.60834C4.3067 3.76396 4.10425 3.81708 3.93794 3.73291L0.830448 2.15429C0.558307 2.01626 0.24671 2.24931 0.29686 2.55381L1.2335 8.26863L1.23202 8.26938H1.23387L1.24087 8.3109H12.5863L12.5856 8.30978L12.8637 8.3109L12.8707 8.26938H12.8725L12.871 8.26863H12.8703Z" fill="#D68112" />
                                <path d="M12.6931 9.01758H1.40992C1.31135 9.01758 1.23145 9.09864 1.23145 9.19863V10.8263C1.23145 10.9262 1.31135 11.0073 1.40992 11.0073H12.6931C12.7916 11.0073 12.8716 10.9262 12.8716 10.8263V9.19863C12.8716 9.09864 12.7916 9.01758 12.6931 9.01758Z" fill="#D68112" />
                            </g>
                            <defs>
                                <clipPath id="clip0_2795_7784">
                                    <rect width="13.5211" height="10.8169" fill="white" transform="translate(0.291504 0.190186)" />
                                </clipPath>
                            </defs>
                        </svg>
                    </div>
                    <div>
                        20
                    </div>
                    <div>
                        30
                    </div>
                    <div>
                        <svg xmlns="http://www.w3.org/2000/svg" width="15" height="11" viewBox="0 0 15 11" fill="none">
                            <g clip-path="url(#clip0_2795_7789)">
                                <path d="M13.2663 8.26863L14.2029 2.55381C14.2527 2.24931 13.9411 2.01626 13.6693 2.15429L10.355 3.83728C10.1887 3.92182 9.98621 3.8687 9.88112 3.71271L7.61622 0.354594C7.46835 0.135383 7.14975 0.135383 7.00188 0.354594L4.80779 3.60834C4.70269 3.76396 4.50025 3.81708 4.33394 3.73291L1.22644 2.15429C0.954303 2.01626 0.642706 2.24931 0.692856 2.55381L1.62949 8.26863L1.62802 8.26938H1.62986L1.63687 8.3109H12.9823L12.9816 8.30978L13.2596 8.3109L13.2667 8.26938H13.2685L13.267 8.26863H13.2663Z" fill="#C7D9DA" />
                                <path d="M13.0896 9.01758H1.80641C1.70784 9.01758 1.62793 9.09864 1.62793 9.19863V10.8263C1.62793 10.9262 1.70784 11.0073 1.80641 11.0073H13.0896C13.1881 11.0073 13.268 10.9262 13.268 10.8263V9.19863C13.268 9.09864 13.1881 9.01758 13.0896 9.01758Z" fill="#C7D9DA" />
                            </g>
                            <defs>
                                <clipPath id="clip0_2795_7789">
                                    <rect width="13.5211" height="10.8169" fill="white" transform="translate(0.6875 0.190186)" />
                                </clipPath>
                            </defs>
                        </svg>
                    </div>
                    <div>
                        50
                    </div>
                    <div>
                        60
                    </div>
                    <div>
                        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="11" viewBox="0 0 14 11" fill="none">
                            <g clip-path="url(#clip0_2795_7794)">
                                <path d="M12.6618 8.26863L13.5984 2.55381C13.6482 2.24931 13.3366 2.01626 13.0648 2.15429L9.75047 3.83728C9.58417 3.92182 9.38172 3.8687 9.27662 3.71271L7.01173 0.354594C6.86386 0.135383 6.54526 0.135383 6.39739 0.354594L4.2033 3.60834C4.0982 3.76396 3.89576 3.81708 3.72945 3.73291L0.621952 2.15429C0.349811 2.01626 0.0382136 2.24931 0.0883642 2.55381L1.025 8.26863L1.02353 8.26938H1.02537L1.03238 8.3109H12.3779L12.3771 8.30978L12.6552 8.3109L12.6622 8.26938H12.664L12.6625 8.26863H12.6618Z" fill="#FFB743" />
                                <path d="M12.4851 9.01758H1.20191C1.10334 9.01758 1.02344 9.09864 1.02344 9.19863V10.8263C1.02344 10.9262 1.10334 11.0073 1.20191 11.0073H12.4851C12.5836 11.0073 12.6635 10.9262 12.6635 10.8263V9.19863C12.6635 9.09864 12.5836 9.01758 12.4851 9.01758Z" fill="#FFB743" />
                            </g>
                            <defs>
                                <clipPath id="clip0_2795_7794">
                                    <rect width="13.5211" height="10.8169" fill="white" transform="translate(0.0830078 0.190186)" />
                                </clipPath>
                            </defs>
                        </svg>

                    </div>
                    <div>
                        80
                    </div>
                    <div>
                        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="11" viewBox="0 0 14 11" fill="none">
                            <g clip-path="url(#clip0_2795_7798)">
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M13.7029 2.55381L12.7663 8.26863H12.767L12.7685 8.26938H12.7667L12.7596 8.3109L12.4816 8.30978L12.4823 8.3109H1.13687L1.12986 8.26938H1.12802L1.12949 8.26863L0.192856 2.55381C0.142706 2.24931 0.454303 2.01626 0.726444 2.15429L3.83394 3.73291C4.00025 3.81708 4.20269 3.76396 4.30779 3.60834L6.50188 0.354594C6.64975 0.135383 6.96835 0.135383 7.11623 0.354594L9.38112 3.71271C9.48621 3.8687 9.68866 3.92182 9.85497 3.83728L13.1693 2.15429C13.4411 2.01626 13.7527 2.24931 13.7029 2.55381ZM1.30612 9.01765H12.5893C12.6878 9.01765 12.7677 9.09871 12.7677 9.19871V10.8263C12.7677 10.9263 12.6878 11.0074 12.5893 11.0074H1.30612C1.20755 11.0074 1.12764 10.9263 1.12764 10.8263V9.19871C1.12764 9.09871 1.20755 9.01765 1.30612 9.01765Z" fill="url(#paint0_linear_2795_7798)" />
                            </g>
                            <defs>
                                <linearGradient id="paint0_linear_2795_7798" x1="9.11136" y1="14.6127" x2="3.92826" y2="0.190186" gradientUnits="userSpaceOnUse">
                                    <stop stop-color="#FF85C0" />
                                    <stop offset="0.489362" stop-color="#D488F7" />
                                    <stop offset="1" stop-color="#76F7FF" />
                                </linearGradient>
                                <clipPath id="clip0_2795_7798">
                                    <rect width="13.5211" height="10.8169" fill="white" transform="translate(0.1875 0.190186)" />
                                </clipPath>
                            </defs>
                        </svg>
                    </div>
                    <div>
                        100
                    </div>
                </div>
            </div>
            {popper == 1 ? 
            <div className="popper">
                <img src={process.env.PUBLIC_URL + "/img/popper.gif"}></img>
            </div>
             : null}
        </div>
    )
}

export default Skin_score_section
