import './App.css';
import { useEffect, useState } from 'react';
import { Route, Routes, useNavigate, BrowserRouter, useLocation } from 'react-router-dom';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { ImageQualityProvider } from './page/ImageQualityContext';
import { Helmet } from 'react-helmet-async';

// page.js
import Main_page from './page/main';
import Person_info from './page/person_info';
import Policy_1 from './page/policy_01';
import Policy_2 from './page/policy_02';
import Camara_guide from './page/camara_guide';
import Camara_api from './page/camara_api';
import Skin_survey from './page/skin_survey';
import Survey_loading from './page/survey_loading';

import Mail_send_form from './page/mail_send_form';
import Email_success from './page/email_success';
import Skin_type_all from './page/skin_type_all';

import Skin_result_dr from './page/result/skin_result_dr';
import Skin_result_ds from './page/result/skin_result_ds';
import Skin_result_dra from './page/result/skin_result_dra';
import Skin_result_dsa from './page/result/skin_result_dsa';
import Skin_result_nr from './page/result/skin_result_nr';
import Skin_result_ns from './page/result/skin_result_ns';
import Skin_result_nsa from './page/result/skin_result_nsa';
import Skin_result_nra from './page/result/skin_result_nra';
import Skin_result_or from './page/result/skin_result_or';
import Skin_result_ora from './page/result/skin_result_ora';
import Skin_result_os from './page/result/skin_result_os';
import Skin_result_osa from './page/result/skin_result_osa';
import Skin_detail_dr from './page/detail/skin_dr';
import Skin_detail_dra from './page/detail/skin_dra';
import Skin_detail_ds from './page/detail/skin_ds';
import Skin_detail_dsa from './page/detail/skin_dsa';
import Skin_detail_nr from './page/detail/skin_nr';
import Skin_detail_nra from './page/detail/skin_nra';
import Skin_detail_ns from './page/detail/skin_ns';
import Skin_detail_nsa from './page/detail/skin_nsa';
import Skin_detail_or from './page/detail/skin_or';
import Skin_detail_ora from './page/detail/skin_ora';
import Skin_detail_os from './page/detail/skin_os';
import Skin_detail_osa from './page/detail/skin_osa';
import Test from './page/test';
import Test2 from './page/test2';


function App() {
  const location = useLocation();

  const noAnimationPaths = ['/skin_survey/1', '/skin_survey/2', '/skin_survey/3', '/skin_survey/4', '/skin_survey/5', '/skin_survey/6'];

  return (
    <div className="App">
      <Helmet>
        <meta property="og:title" content="LuLuTI | 나만의 피부 타입을 찾아서"></meta>
        <meta property="og:type" content="website"></meta>
        {/* <meta property="og:image" content="https://luluti.lululab-platform.com/img/share_img.png"></meta> */}
        <meta property="og:image" content="http://gnugoom.dothome.co.kr/luluti/img/share_img.png"></meta>
        <meta property="og:description" content=" AI 피부 분석을 통해 정확한 피부 상태를 확인해보세요."></meta>
        <meta property="og:url" content="http://gnugoom.dothome.co.kr/luluti/" />
      </Helmet>
      <ImageQualityProvider>
        <TransitionGroup>
          {noAnimationPaths.includes(location.pathname) ? (
            <Routes>
              <Route path='/skin_survey/:id' element={<Skin_survey />}></Route>
            </Routes>
          ) : null}
          {noAnimationPaths.includes(location.pathname) == false ? (
            <CSSTransition key={location.key} classNames="fade" timeout={300}>
              <Routes>
                <Route path='/' element={<Main_page />} />
                <Route path='/info' element={<Person_info />}></Route>
                <Route path='/camara_guide' element={<Camara_guide />}></Route>
                <Route path='/camara_api' element={<Camara_api />}></Route>

                <Route path='/survey_loading' element={<Survey_loading />}></Route>
                <Route path='/skin_survey/:id' element={<Skin_survey />}></Route>

                {/* 타입별 결과창 */}
                <Route path='/result/DR/:jobId' element={<Skin_result_dr />}></Route>
                <Route path='/result/DS/:jobId' element={<Skin_result_ds />}></Route>
                <Route path='/result/DR-A/:jobId' element={<Skin_result_dra />}></Route>
                <Route path='/result/DS-A/:jobId' element={<Skin_result_dsa />}></Route>

                <Route path='/result/NR/:jobId' element={<Skin_result_nr />}></Route>
                <Route path='/result/NS/:jobId' element={<Skin_result_ns />}></Route>
                <Route path='/result/NR-A/:jobId' element={<Skin_result_nra />}></Route>
                <Route path='/result/NS-A/:jobId' element={<Skin_result_nsa />}></Route>

                <Route path='/result/OR/:jobId' element={<Skin_result_or />}></Route>
                <Route path='/result/OS/:jobId' element={<Skin_result_os />}></Route>
                <Route path='/result/OR-A/:jobId' element={<Skin_result_ora />}></Route>
                <Route path='/result/OS-A/:jobId' element={<Skin_result_osa />}></Route>
                {/* 타입별 결과창 */}


                <Route path='/result_share' element={<Main_page />}></Route>

                <Route path='/email_form' element={<Mail_send_form />}></Route>
                <Route path='/email_success' element={<Email_success />}></Route>

                <Route path='/skin_type_all' element={<Skin_type_all />}></Route>
                {/* 타입별 상세창 */}
                <Route path='/detail/dr/' element={<Skin_detail_dr />}></Route>
                <Route path='/detail/ds/' element={<Skin_detail_ds />}></Route>
                <Route path='/detail/dra/' element={<Skin_detail_dra />}></Route>
                <Route path='/detail/dsa/' element={<Skin_detail_dsa />}></Route>

                <Route path='/detail/nr/' element={<Skin_detail_nr />}></Route>
                <Route path='/detail/ns/' element={<Skin_detail_ns />}></Route>
                <Route path='/detail/nra/' element={<Skin_detail_nra />}></Route>
                <Route path='/detail/nsa/' element={<Skin_detail_nsa />}></Route>

                <Route path='/detail/or/' element={<Skin_detail_or />}></Route>
                <Route path='/detail/os/' element={<Skin_detail_os />}></Route>
                <Route path='/detail/ora/' element={<Skin_detail_ora />}></Route>
                <Route path='/detail/osa/' element={<Skin_detail_osa />}></Route>
                {/* 타입별 상세창 */}

                <Route path='/test/' element={<Test />}></Route>
                <Route path='/test2/' element={<Test2 />}></Route>

                <Route path='/ai_loading' element={<Main_page />}></Route>
                <Route path='/ai_fail' element={<Main_page />}></Route>
                <Route path='/*' element={<Error />}></Route>
              </Routes>
            </CSSTransition>
          ) : null}
        </TransitionGroup>
      </ImageQualityProvider>
    </div>
  );
}


function Error() {
  let navigate = useNavigate();

  useEffect(() => {
    // 2초 후에 메인 페이지('./')로 이동
    const timer = setTimeout(() => {
      navigate('/');
    }, 2000);

    // 컴포넌트가 언마운트될 때 타이머를 정리
    return () => clearTimeout(timer);
  }, [navigate]); // 의존성 배열에 navigate 추가

  return (
    <div className='err_page'>
      <div>잘못된 접근입니다. 잠시 후 메인페이지로 돌아갑니다.</div>
    </div>
  );
}

export default App;
