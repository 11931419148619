import { useNavigate } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import axios from "axios";

function Mail_send_form() {
    let navigate = useNavigate()

    let [user_email , setUser_email] = useState("")
    let [btn_state , setBtn_state] = useState(false)
    let emailRef = useRef()
    
    function Check_email(e){
        let email_regex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/i;
        if(!email_regex.test(e.target.value)){ 
            setBtn_state(false)
            e.target.parentNode.classList.add("invalid") 
        }else{
            setBtn_state(true)
            e.target.parentNode.classList.remove("invalid") 
        }
    }

    // function Send_data(){
    //     let data ={
    //         "jobId": localStorage.getItem("jobId"),
    //         "email": user_email ,
    //     }
    //     let pdfInclude_data
    //     const base64data = localStorage.getItem('pdfBase64');
    //     if (base64data) {
    //         // Extract base64 string without the prefix
    //         const base64String = base64data.split(',')[1];

    //         pdfInclude_data = {
    //             "jobId": localStorage.getItem("jobId"),
    //             "email": user_email ,
    //             "file": base64String,
    //         }
    //     }

    //     let payload = {"jobId": pdfInclude_data.jobId , 'email' : [pdfInclude_data.email]}
    //     payload = JSON.stringify(payload)
    //     let formData = new FormData();
        

    //     // Base64 문자열을 디코딩하여 Uint8Array로 변환
    //     // let binaryString = window.atob(pdfInclude_data.file);
    //     // let len = binaryString.length;
    //     // let bytes = new Uint8Array(len);
    //     // for (let i = 0; i < len; i++) {
    //     //     bytes[i] = binaryString.charCodeAt(i);
    //     // }

    //     // let blob = new Blob([bytes], { type: "application/pdf" });
    //     // let file = new File([blob], "document.pdf", { type: "application/pdf" });

    //     formData.append("payload", payload , {contentType: 'application/json'});
    //     formData.append("attachFile", pdfInclude_data.file);


    //     // 다운로드 
    //     // let url = URL.createObjectURL(blob);
    //     // let a = document.createElement('a');
    //     // a.href = url;
    //     // a.download = 'document.pdf';
    //     // document.body.appendChild(a);
    //     // a.click();
    //     // document.body.removeChild(a);

    //     // api input 값이 없어서 우선 변수만 만들어 두었음. 아래의 data 변수를 pdfInclude_data 로 변경하면됩니다.
        
         
    //     axios.post("http://websdk.lululab-platform.com/api/reports/send-analysis" , formData, {
    //         headers: {
    //             'x-secret': '2a69f75f-0637-4c0c-852e-5e2502edcdfe',
    //             'x-license': '067d32d1be894d79a4bb',
    //             'Content-Type': 'multipart/form-data',
    //         },
    //     })
    //     .then((result)=>{
    //         console.log("전송완료")
    //         navigate("/email_success")
    //     })
    //     .catch((err)=>{
    //         console.log(err)
    //         console.log("payload", payload);
    //         console.log("attachFile", pdfInclude_data.file);
    //         console.log("전송실패")
    //     })
    // }

    function Send_data() {
        let data = {
            "jobId": localStorage.getItem("jobId"),
            "email": user_email,
        }
        let pdfInclude_data;
        const base64data = localStorage.getItem('pdfBase64');
        if (base64data) {
            const base64String = base64data.split(',')[1];
    
            pdfInclude_data = {
                "jobId": localStorage.getItem("jobId"),
                "email": user_email,
                "file": base64String,
            }
        }
    
        let payload = {"jobId": pdfInclude_data.jobId, 'email': [pdfInclude_data.email]};
        payload = JSON.stringify(payload);
        let formData = new FormData();
    
        function base64ToBlob(base64, contentType = '', sliceSize = 512) {
            const byteCharacters = atob(base64);
            const byteArrays = [];
    
            for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
                const slice = byteCharacters.slice(offset, offset + sliceSize);
    
                const byteNumbers = new Array(slice.length);
                for (let i = 0; i < slice.length; i++) {
                    byteNumbers[i] = slice.charCodeAt(i);
                }
    
                const byteArray = new Uint8Array(byteNumbers);
                byteArrays.push(byteArray);
            }
    
            const blob = new Blob(byteArrays, { type: contentType });
            return blob;
        }
    
        const pdfBlob = base64ToBlob(pdfInclude_data.file, 'application/pdf');
        const file = new File([pdfBlob], "document.pdf", { type: "application/pdf" });
    
        formData.append("payload", new Blob([payload], { type: 'application/json' }));
        formData.append("attachFile", file);
        
        // 메일 보내기
        axios.post("https://websdk.lululab-platform.com/api/reports/send-analysis", formData, {
            headers: {
                'x-secret': '2a69f75f-0637-4c0c-852e-5e2502edcdfe',
                'x-license': '067d32d1be894d79a4bb',
                'Content-Type': 'multipart/form-data',
            },
        })
        .then((result) => {
            console.log("전송완료");
            navigate("/email_success" , {state : "email_success"});
        })
        .catch((err) => {
            console.log(err);
            console.log("payload", payload);
            console.log("attachFile", pdfInclude_data.file);
            console.log("전송실패");
        });
    

        // // 다운로드
        // let url = URL.createObjectURL(file);
        // let a = document.createElement('a');
        // a.href = url;
        // a.download = 'document.pdf';
        // document.body.appendChild(a);
        // a.click();
        // document.body.removeChild(a);

        // // URL 객체 해제
        // URL.revokeObjectURL(url);
    }

    useEffect(() => {
        const storedData = localStorage.getItem('user_send_data');
        if (storedData) {
          try {
            const parsedData = JSON.parse(storedData);
            if (parsedData.email) {
              setUser_email(parsedData.email);
              setBtn_state(true);
            }
          } catch (error) {
            console.error('Error parsing stored data', error);
          }
        }
      }, []);

    return (
        <div className="sub_page">
            <div className="sub_top_bar small02 ju_fe">
                <div className="sub_page_top_title">
                    메일 보내기
                </div>
                <div className="back_btn" onClick={() => {
                    navigate(-1)
                }}>
                    <img src={process.env.PUBLIC_URL + "/img/clo_btn.svg"} />
                </div>
            </div>
            <div className="sub_body_wrap">
                <div className="sub_body">
                    <div className="con">
                        <div className="mail_send_title sub_headline02">
                            입력한 이메일 주소로 <br />
                            LuLuTI 리포트를 보내드릴게요
                        </div>
                        <div className="info_wrap">
                            <div className="form form_type_01">
                                <div className="lable_box email_input">
                                    <label className="small02" htmlFor="email">이메일</label>
                                    <input required id="email" type="email" value={user_email} placeholder="이메일 주소를 입력해 주세요" ref={emailRef} onChange={(e) => {
                                        Check_email(e)
                                        setUser_email(e.target.value)
                                    }}
                                    onBlur={(e)=>{
                                        if(btn_state == false && e.target.value != ""){
                                            e.target.parentNode.classList.add("invalid") 
                                        } else{
                                            e.target.parentNode.classList.remove("invalid") 
                                        }
                                    }}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="btn_fixed">
                            {
                                btn_state ? 
                                <div className="primary_fill_btn small02" onClick={()=>{
                                    Send_data()
                                }}>
                                    메일 보내기
                                </div>
                                :
                                <div className="gray_btn small02">
                                    메일 보내기
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Mail_send_form