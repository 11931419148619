import React, { useEffect, useRef, useState } from "react"
import Webcam from "react-webcam"
import { checkPermission } from "./utils"
import { useNavigate } from "react-router-dom"
import axios from "axios"

import { ImageQualityCheckRealTime } from "./ImageQualityCheckRealTimeImpl"
import { useImageQuality } from '../ImageQualityContext';

function WebcamPage({ loading_done, setFail_modal_btn, setCamara_on, camara_on ,api_init }) {
    const webcam = useRef(null)
    const canvas = useRef(null)
    const cropCanvasRef = useRef(null)
    const webcamOveralyRef = useRef(null)
    const navigate = useNavigate()

    let [lulu_data, setLuluData] = useState([90, 90, 90, 90])
    let [btn_state, setBtn_state] = useState(false)
    let [loading_state, setLoading_state] = useState(false)
    let [facingMode, setFacingMode] = useState("user");
    let [captureOn, setCaptureOn] = useState(false)
    let [camara_guide_box_re, setCamara_guide_box_re] = useState({ width: 310, height: 386 });
    let [imageSrc, setImageSrc] = useState(null);
    let [camara_mirror, setCamara_mirror] = useState(true)
    let [senddata_state, setSenddata_state] = useState(false)
    let [btn_click, setBtn_click] = useState(false)
    let [detect, setDetect] = useState("촬영 준비 중이에요")

    let capture, date, skin_interval,  pa_x1, pa_x2, pa_y1, pa_y2, initParameter;

    let switchCamera = () => {
        setFacingMode((prevFacingMode) => (prevFacingMode === "user" ? "environment" : "user"));
        if (camara_mirror) {
            setCamara_mirror(false)
        } else {
            setCamara_mirror(true)
        }
    };

    function Condition_box_state(lulu_data) {
        var code = lulu_data % 100
        if (Math.floor(code / 10) === 9) {
            return "condition_box nomal"
        } else if (Math.floor(code / 10) === 0) {
            return "condition_box good"
        } else if (Math.floor(code / 10) === 1) {
            return "condition_box warning"
        } else if (Math.floor(code / 10) === 2) {
            return "condition_box bad"
        } else {
            console.log(code / 10)
            return "condition_box nomal"
        }
    }

    function Request_analysis(fileUid) {
        // 분석요청후 jobid 저장 및 설문조사 페이지로 이동
        let user_send_data = localStorage.getItem("user_send_data")
        user_send_data = JSON.parse(user_send_data)
        function convertDateToNumber(dateString) {
            // 날짜 문자열을 "/"를 기준으로 분리합니다.
            const dateParts = dateString.split('/');

            // 년, 월, 일을 추출합니다.
            const year = dateParts[0];
            const month = dateParts[1];
            const day = dateParts[2];

            // 월과 일이 한 자리 수인 경우 앞에 0을 추가합니다.
            const formattedMonth = month.length === 1 ? '0' + month : month;
            const formattedDay = day.length === 1 ? '0' + day : day;

            // 모든 부분을 결합하여 숫자 형식으로 변환합니다.
            const numericDate = year + formattedMonth + formattedDay;

            return numericDate;
        }
        const birthdy = convertDateToNumber(user_send_data.birthdate);
        let user_info = {
            "name": user_send_data.name,
            "birthdy": birthdy,
            "sexdstn": user_send_data.gender,
        }
        let terms = [
            {
                "uid": fileUid,
                "sj": "개인정보 수집 및 동의",
                "conditions":
                    `(주)룰루랩은 이용자의 피부 나이 측정 및 상태 등의 피부 정보 분석 서비스 제공을 위해 아래와 같이 개인정보를 수집·이용 합니다.
                수집 및 이용 항목 :
                성별, 나이, 전화번호, 사진, 피부 관련 문진 정보
                수집 및 이용 목적 :
                피부 데이터 분석 및 결과 도출 / 피부 분석 데이터 활용
                이용 및 보유 기간 :
                수집일로부터 5년
                귀하는 개인정보 수집에 동의를 거부할 권리가 있으며, 동의를 거부할 경우 피부 분석 서비스를 받으실 수 없습니다. 수집된 개인정보는 다른 목적으로 사용하지 않습니다.`,
                "agreAt": user_send_data.agree01,
                "requireOptAt": user_send_data.agree01
            },
            {
                "uid": fileUid,
                "sj": "민감정보 수집 및 이용 동의 [필수]",
                "conditions":
                    `(주)룰루랩은 이용자의 피부 나이 측정 및 상태 등의 피부 정보 분석 서비스 제공을 위해 아래와 같이 민감정보를 수집·이용 합니다.
                수집 및 이용 항목 :
                피부 측정 결과(피부 나이, 피부 상태, 피부 점수)
                수집 및 이용 목적 :
                피부 데이터 분석 및 결과 도출 / 피부 분석 데이터 활용
                이용 및 보유 기간 :
                수집일로부터 5년
                귀하는 개인정보 수집에 동의를 거부할 권리가 있으며, 동의를 거부할 경우 피부 분석 서비스를 받으실 수 없습니다. 수집된 개인정보는 다른 목적으로 사용하지 않습니다.`,
                "agreAt": user_send_data.agree02,
                "requireOptAt": user_send_data.agree02
            }
        ]
        let result = {
            "email": user_send_data.email,
            "fUid": fileUid,
            "userInfo": user_info,
            "analysis": {},
            "terms": terms,
        };

        // 서버 코드
        axios.post("https://websdk.lululab-platform.com/api/skin-analysis", result, {
            headers: {
                'x-secret': '2a69f75f-0637-4c0c-852e-5e2502edcdfe',
                'x-license': '067d32d1be894d79a4bb',
                'Content-Type': 'application/json',
            }
        })
            .then((response) => {
                console.log("분석요청 성공")
                localStorage.setItem("jobId", response.data.data.jobId)
                console.log(response.data.data.jobId)
            })
            .catch((err) => {
                console.log("분석요청 실패")
                console.log(err)
            });
    }

    function check_camara() {
        // 가이드크기
        var guide = document.querySelector('.guide_state_good')
        setCamara_guide_box_re({
            width: guide.clientWidth,
            height: guide.clientHeight
        })
        console.log(guide.clientWidth)
        console.log(guide.clientHeight)
    }

    // check permisisons
    useEffect(() => {
        checkPermission()
    }, [])

    const getCropImageFromVideoStream = (canvasElement, currentVideoStream, isMirror) => {
        const video = currentVideoStream;
        const canvas = canvasElement;
        const ctx = canvas.getContext('2d');

        const videoWidth = video.videoWidth;
        const videoHeight = video.videoHeight;
        const cropWidth = video.clientWidth;
        const cropHeight = video.clientHeight;

        /** 이미지 Crop 시작 좌표 및 가로x세로 길이 설정 */
        let sx, sy, sWidth, sHeight;
        if (videoWidth / videoHeight > cropWidth / cropHeight) {
            // 비디오가 더 넓은 경우
            sHeight = videoHeight;
            sWidth = videoHeight * (cropWidth / cropHeight);
            // sWidth = videoHeight * (3 / 4);
            sx = (videoWidth - sWidth) / 2;
            sy = 0;
            console.log("넓은")
        } else {
            // 비디오가 더 높은 경우
            sWidth = videoWidth;
            sHeight = videoWidth / (cropWidth / cropHeight);
            // sHeight = videoWidth / (3 / 4);
            sx = 0;
            sy = (videoHeight - sHeight) / 2;
            console.log("높은")
        }

        console.log("sWidth" , sWidth)
        console.log("sHeight" , sHeight)

        /** 캔버스에 그리기 */
        //캔버스 사이즈 설정
        canvas.width = cropWidth;
        canvas.height = cropHeight;
        // 캔버스 좌우 반전
        if (isMirror == true) {
            ctx.scale(-1, 1);
            ctx.translate(-canvas.width, 0);
        }
        // 캔버스에 그리기
        ctx.drawImage(video, sx, sy, sWidth, sHeight, 0, 0, cropWidth, cropHeight);
        // 캔버스에서 이미지 가져오기
        const imageData = ctx.getImageData(0, 0, cropWidth, cropHeight);
        const imageSrc = canvas.toDataURL('image/png');
        // const imageSrc = canvas.toDataURL('image/jpeg');
        // console.log('crop base64', imageSrc);

        return { imageData: imageData, imageSrc: imageSrc };
    }
    const imageToScaleUp = (imgSrc, canvasElement, toWidth, toHeight) => {
        return new Promise((resolve, reject) => {
            const canvas = canvasElement;
            const ctx = canvas.getContext('2d');

            // 이미지 로드
            const image = new Image();
            image.src = imgSrc;
            image.onload = () => {
                const originalWidth = image.width;
                const originalHeight = image.height;
                const fullHDWidth = toWidth;
                const fullHDHeight = toHeight;

                let scaledWidth, scaledHeight;

                // 가로세로크기비교
                if (originalWidth / originalHeight > fullHDWidth / fullHDHeight) {
                    scaledWidth = fullHDWidth;
                    scaledHeight = (fullHDWidth * originalHeight) / originalWidth;
                    // scaledHeight = fullHDWidth * (3 / 4);
                } else {
                    scaledHeight = fullHDHeight;
                    scaledWidth = (fullHDHeight * originalWidth) / originalHeight;
                    // scaledWidth = fullHDHeight / (3 / 4);
                }

                canvas.width = fullHDWidth;
                canvas.height = fullHDHeight;

                // 비율을 유지하면서 중심에서 업사이징
                // 이미지 중심위치로
                const dx = (fullHDWidth - scaledWidth) / 2;
                const dy = (fullHDHeight - scaledHeight) / 2;

                ctx.clearRect(0, 0, fullHDWidth, fullHDHeight);
                ctx.drawImage(image, 0, 0, originalWidth, originalHeight, dx, dy, scaledWidth, scaledHeight);

                // 업사이징된 이미지를 데이터 URL로 반환
                const dataURL = canvas.toDataURL('image/jpeg');
                resolve(dataURL);
            };

            image.onerror = (error) => {
                reject(error);
            };

        });
    }
    function Capture_on() {
        // const screenshotQuality = 1; // 해상도 품질 (1이 최대)
        // capture = webcam.current.getScreenshot({
        //     width: webcam.current.video.videoWidth,
        //     height: webcam.current.video.videoHeight,
        //     quality: screenshotQuality,
        // })
        const crop = getCropImageFromVideoStream(canvas.current, webcam.current.video, webcam.current.props.mirrored);
        // console.log('capture', crop);
        imageToScaleUp(crop.imageSrc, canvas.current, 2560, 1440).then((scaleUpImage) => {
            // console.log('scaleUp', scaleUpImage);
            // 업사이징된 이미지를 새로운 이미지 요소에 설정
            setImageSrc(scaleUpImage)
            date = Date.now();
        }).catch((error) => {
            console.error('Error upscaling image:', error);
        });
    }

    let [face_guide_nomal , setFace_guide_nomal] = useState("guide_face")
    let [face_guide_warn , setFace_guide_warn] = useState("guide_state_warning")
    let [face_guide_good , setFace_guide_good] = useState("guide_state_good")

    const [camata_loading_count , set_camata_loading_count] = useState("camera_loading")

    useEffect(() => {
        console.log(lulu_data)
        var light = lulu_data[0] % 100
        var face = lulu_data[2] % 100
        var eyes = lulu_data[1] % 100

        setFace_guide_warn("guide_state_warning active")

        if(light === 90 && face === 90 && eyes === 90){
            setFace_guide_nomal("guide_face animation")
        }else{
            setFace_guide_nomal("guide_face")
        }
        
        if(light === 90 && face === 90 && eyes === 90){
            setDetect("촬영이 가능해요. 얼굴을 원 안에 맞춰주세요.")
        }
        else if(light !== 0){
            setDetect("조명을 밝혀주세요.")
        } else if(eyes !== 0 && face !== 0){
            setDetect("원 안에 턱과 광대를 맞춰주세요.")
        } else if(lulu_data[2] % 10 === 1){
            setDetect("너무 가까이 있어요. 조금 떨어져 주세요.")
        } else if(lulu_data[2] % 10 === 2){
            setDetect("너무 멀리 있어요. 가까이 와 주세요.")
        } else if(lulu_data[2] ===420){
            setDetect("원 안에 턱과 광대를 맞춰주세요.")
        } else if(eyes !== 0){
            setDetect("정면을 바라봐 주세요.")
        } 
        if (light === 0 && face === 0 && eyes === 0) {
            // 사진촬영_카운트 들어가야함 (2초지만 화면상으로는 3초)
            setDetect("촬영을 진행 중이에요. 잠시만 기다려 주세요.")
            setFace_guide_warn("guide_state_warning")
            setFace_guide_good("guide_state_good active")
            set_camata_loading_count("camera_loading active")
            setBtn_state(true)
            // setTimeout(() => {
            //     setBtn_state(true)
            //     setCaptureOn(true)
            //     if (!captureOn) {
            //         Capture_on()
            //     }
            // }, 3000);
        } else {
            setBtn_state(false)
            set_camata_loading_count("camera_loading")
            setFace_guide_warn("guide_state_warning active")
            setFace_guide_good("guide_state_good ")
        }
        // console.log("조명 " + light, "거리 " + face, "각도 " + eyes)
    }, [lulu_data])

    const timerRef = useRef(null); // 타이머 참조
    const skinIntervalRef = useRef(null);

    useEffect(() => {
        if (btn_state) {
            timerRef.current = setTimeout(() => {
                setBtn_state(true)
                setCaptureOn(true)
                if (!captureOn) {
                    Capture_on()
                    clearInterval(skinIntervalRef.current);
                }
            }, 2000);
        } else {
            clearTimeout(timerRef.current);
            timerRef.current = null;
          }
    }, [btn_state])

    const luminiSkinRef = useRef(null); // Assume this is properly initialized
    const imageQualityApi = useImageQuality();

    useEffect(() => {
        const cropBoxWidth = webcam.current.video.clientWidth;
        const cropBoxHeight = webcam.current.video.clientHeight;
        console.log(camara_guide_box_re)
        pa_x1 = ((cropBoxWidth / 2) - (camara_guide_box_re.width / 2)) / cropBoxWidth
        pa_y1 = ((cropBoxHeight / 2) - (camara_guide_box_re.height / 2)) / cropBoxHeight
        pa_x2 = ((cropBoxWidth / 2) + (camara_guide_box_re.width / 2)) / cropBoxWidth
        pa_y2 = ((cropBoxHeight / 2) + (camara_guide_box_re.height / 2)) / cropBoxHeight
        initParameter = [pa_x1, pa_y1, pa_x2, pa_y2]
        initParameter[1] = initParameter[1] + 0.15
        console.log("initParameter",initParameter)
        console.log("imageQualityApi",imageQualityApi)
        // pa_x1 = ((cropBoxWidth / 2) - (camara_guide_box_re.width / 2)) / cropBoxWidth * 0.9
        // pa_y1 = ((cropBoxHeight / 2) - (camara_guide_box_re.height / 2)) / cropBoxHeight * 0.9
        // pa_x2 = ((cropBoxWidth / 2) + (camara_guide_box_re.width / 2)) / cropBoxWidth * 1.1
        // pa_y2 = ((cropBoxHeight / 2) + (camara_guide_box_re.height / 2)) / cropBoxHeight * 1.1
        // initParameter = [pa_x1, pa_y1, pa_x2, pa_y2]
        // console.log("initParameter_2nd",initParameter)
        if(imageQualityApi){
            // api 로드 _ type02
            luminiSkinRef.current = imageQualityApi;
            luminiSkinRef.current.initUIParameter(initParameter);
            luminiSkinRef.current.setScaler(1, 1, 1, 1);
    
            console.log("useeffect")
            check_camara()
        } else {
            // api 로드 _ type01 
            // luminiSkinRef.current = new ImageQualityCheckRealTime(true);
            setTimeout(() => {
                luminiSkinRef.current = new ImageQualityCheckRealTime(true);
                luminiSkinRef.current.initUIParameter(initParameter);
                luminiSkinRef.current.setScaler(1, 1, 1, 1);
                console.log("useeffect")
                check_camara()
            }, 3000);
        }
    }, [])

    useEffect(() => {
        if (loading_done && camara_on) {
            runModel()
            setDetect("촬영이 가능해요. 얼굴을 원 안에 맞춰주세요.")
        }

        return ()=>{
            clearInterval(skinIntervalRef.current);
        }

    }, [loading_done, camara_on])

    const runModel = async () => {
        var luminiSkin = luminiSkinRef.current
        skinIntervalRef.current = setInterval(() => {
            if (typeof webcam.current !== "undefined" &&
                webcam.current !== null &&
                webcam.current.video.readyState === 4 &&
                luminiSkin.isModelLoaded) {
                const video = webcam.current.video;
                /** 체커 오버레이 이미지 캔버스 설정 */
                const videoOverlayCanvas = webcamOveralyRef.current;
                videoOverlayCanvas.width = video.clientWidth;
                videoOverlayCanvas.height = video.clientHeight;
                const ctx = videoOverlayCanvas.getContext('2d');
                /** 이미지 체커 실행(video steram과 crop ImageData 中 택 1) */
                // luminiSkin.imageQualityCheckRealTime(video, webcam.current.props.mirrored)
                const crop = getCropImageFromVideoStream(cropCanvasRef.current, webcam.current.video, webcam.current.props.mirrored);
                // console.log('imageData', `${crop.imageData.width}x${crop.imageData.height}`);
                // console.log(`[webcam video]: ${video.videoWidth}x${video.videoHeight} [webcam client(crop)]: ${video.clientWidth}x${video.clientHeight}`);
                luminiSkin.imageQualityCheckRealTime(crop.imageData, webcam.current.props.mirrored)
                    .then(data => {
                        // console.log("imageQualityCheckRealTime", data)
                        setLuluData(data)
                    })
                    .catch((err) => {
                        console.log("wait")
                    })
            }
        }, 200)
    }

    async function camara_done() {
        setLoading_state(true)
        setSenddata_state(true)
        //  로컬스토리지에서 사용자 정보 가지고오기
        let user_send_data = JSON.parse(localStorage.getItem("user_send_data"))
        let email = user_send_data.email

        // 서버전송
        const base64Response = await fetch(imageSrc);
        const blob = await base64Response.blob();

        // png 변경
        // const file = new File([blob], Date.now() + '.png', { type: 'image/png' });
        const file = new File([blob], Date.now() + '.jpg', { type: 'image/jpeg' });

        const formData = new FormData();
        formData.append('white', file);
        formData.append('userEmail', email);

        // 서버로 전송하기
        axios.post("https://websdk.lululab-platform.com/api/skin-analysis/images", formData, {
            headers: {
                'x-secret': '2a69f75f-0637-4c0c-852e-5e2502edcdfe',
                'x-license': '067d32d1be894d79a4bb',
                'Content-Type': 'multipart/form-data',
            },
        })
            .then((result) => {
                // 전송 성공시 uid 저장 및 분석요청진행
                let fileUid = result.data.data.fileUid
                Request_analysis(fileUid)
                navigate("/skin_survey/1" ,{state :"skin_survey"})
            })
            .catch((err) => {
                // 전송실패시 실패모달생성
                console.log(err)
                setLoading_state(false)
                setFail_modal_btn(1)
            })

        // 캡쳐 다운로드
        // const link = document.createElement('a');
        // link.href = capture;
        // link.download = 'captured_image.png';
        // document.body.appendChild(link);
        // link.click();
        // document.body.removeChild(link);
        // setLoading_state(false)

    }   

    return (
        <>
            {captureOn ?
                <div className="capture_confirm_wrap">
                    <div className="capture_confirm_box">
                        <img className="capture_img" src={imageSrc} alt="Captured" />
                        <div className="large01">
                            위 사진으로 피부 분석을 진행할까요?
                        </div>
                        <div className="fail_btn_wrap">
                            <div className="cancel_btn small01 p200_btn" onClick={() => {
                                setCaptureOn(false)
                                setFace_guide_warn("guide_state_warning active")
                                setFace_guide_good("guide_state_good")
                                runModel()
                            }}>
                                다시 찍기
                            </div>
                            <div className="re_camara_btn small01 primary_fill_btn" onClick={(e) => {
                                if (!senddata_state) {
                                    setBtn_click(true)
                                    e.target.classList.add("pressed")
                                    setTimeout(() => {
                                        e.target.classList.remove("pressed")
                                    }, 50);
                                    camara_done()
                                } else {
                                    console.log("이미 전송되었습니다 기다려주세요")
                                }
                            }}>
                                분석하기
                            </div>
                        </div>
                    </div>
                </div>
            : null}

            <div class={camata_loading_count} id="camera_loading">
                <div class="number headline01"></div>
                <svg>
                    <circle class="background-circle" cx="50%" cy="50%" r="4.5rem"></circle>
                    <circle class="progress-circle" cx="50%" cy="50%" r="4.5rem"></circle>
                </svg>
            </div>

            <div className="camara_section">
                {loading_state ?
                    <div className="data_send_loading_wrap">
                        <div className="data_send_loading">
                            <img src={process.env.PUBLIC_URL + "/img/loading_large.gif"}></img>
                        </div>
                    </div>
                    : null}
                <div className="camara_wrap">
                    <div className="condition_wrap">
                        <div className={Condition_box_state(lulu_data[0])}>
                            <div className="con_title label02">
                                <svg xmlns="http://www.w3.org/2000/svg" width="1.7rem" height="1.6rem" viewBox="0 0 17 16" fill="none">
                                    <g clip-path="url(#clip0_3047_704)">
                                        <path d="M8.61117 11.3334C10.4521 11.3334 11.9445 9.84103 11.9445 8.00008C11.9445 6.15913 10.4521 4.66675 8.61117 4.66675C6.77022 4.66675 5.27783 6.15913 5.27783 8.00008C5.27783 9.84103 6.77022 11.3334 8.61117 11.3334Z" stroke="white" stroke-linecap="round" stroke-linejoin="round" />
                                        <path d="M8.61182 0.666626V1.99996" stroke="white" stroke-linecap="round" stroke-linejoin="round" />
                                        <path d="M8.61182 14V15.3333" stroke="white" stroke-linecap="round" stroke-linejoin="round" />
                                        <path d="M3.42432 2.81323L4.37098 3.7599" stroke="white" stroke-linecap="round" stroke-linejoin="round" />
                                        <path d="M12.8521 12.2401L13.7987 13.1868" stroke="white" stroke-linecap="round" stroke-linejoin="round" />
                                        <path d="M1.27783 8.00024H2.61117" stroke="white" stroke-linecap="round" stroke-linejoin="round" />
                                        <path d="M14.6116 8.00018H15.9449" stroke="white" stroke-linecap="round" stroke-linejoin="round" />
                                        <path d="M3.42432 13.1868L4.37098 12.2401" stroke="white" stroke-linecap="round" stroke-linejoin="round" />
                                        <path d="M12.8521 3.7599L13.7987 2.81323" stroke="white" stroke-linecap="round" stroke-linejoin="round" />
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_3047_704">
                                            <rect width="16" height="16" fill="white" transform="translate(0.611816)" />
                                        </clipPath>
                                    </defs>
                                </svg>
                                <p>조명</p>
                            </div>
                            <div className="con_state label01">

                            </div>
                        </div>
                        <div className={Condition_box_state(lulu_data[2])}>
                            <div className="con_title label02">
                                <svg xmlns="http://www.w3.org/2000/svg" width="1.7rem" height="1.6rem" viewBox="0 0 17 16" fill="none">
                                    <g clip-path="url(#clip0_3047_3861)">
                                        <path d="M8.61182 14.0002C11.9255 14.0002 14.6118 11.3139 14.6118 8.00018C14.6118 4.68647 11.9255 2.00018 8.61182 2.00018C5.29811 2.00018 2.61182 4.68647 2.61182 8.00018C2.61182 11.3139 5.29811 14.0002 8.61182 14.0002Z" stroke="white" stroke-linecap="round" stroke-linejoin="round" />
                                        <path d="M5.9458 9.33319C5.9458 9.33319 6.9458 10.6665 8.61247 10.6665C10.2791 10.6665 11.2791 9.33319 11.2791 9.33319" stroke="white" stroke-linecap="round" stroke-linejoin="round" />
                                        <path d="M6.61157 6H6.61774" stroke="white" stroke-linecap="round" stroke-linejoin="round" />
                                        <path d="M10.6118 6H10.618" stroke="white" stroke-linecap="round" stroke-linejoin="round" />
                                        <path d="M3.9445 0.66687H1.77783C1.50169 0.66687 1.27783 0.890728 1.27783 1.16687V3.33354" stroke="white" />
                                        <path d="M13.2791 0.66687H15.4458C15.7219 0.66687 15.9458 0.890728 15.9458 1.16687V3.33354" stroke="white" />
                                        <path d="M13.2791 15.3333H15.4458C15.7219 15.3333 15.9458 15.1094 15.9458 14.8333V12.6666" stroke="white" />
                                        <path d="M3.9445 15.3333H1.77783C1.50169 15.3333 1.27783 15.1094 1.27783 14.8333V12.6666" stroke="white" />
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_3047_3861">
                                            <rect width="16" height="16" fill="white" transform="translate(0.611816)" />
                                        </clipPath>
                                    </defs>
                                </svg>

                                <p>거리</p>
                            </div>
                            <div className="con_state label01">

                            </div>
                        </div>
                        <div className={Condition_box_state(lulu_data[1])}>
                            <div className="con_title label02">
                                <svg xmlns="http://www.w3.org/2000/svg" width="1.7rem" height="1.6rem" viewBox="0 0 17 16" fill="none">
                                    <path d="M8.61247 14.6666C12.2944 14.6666 15.2791 11.6818 15.2791 7.99992C15.2791 4.31802 12.2944 1.33325 8.61247 1.33325C4.93057 1.33325 1.9458 4.31802 1.9458 7.99992C1.9458 11.6818 4.93057 14.6666 8.61247 14.6666Z" stroke="white" stroke-linecap="round" stroke-linejoin="round" />
                                    <path d="M8.61182 12.0001C10.821 12.0001 12.6118 10.2092 12.6118 8.00006C12.6118 5.79092 10.821 4.00006 8.61182 4.00006C6.40268 4.00006 4.61182 5.79092 4.61182 8.00006C4.61182 10.2092 6.40268 12.0001 8.61182 12.0001Z" stroke="white" stroke-linecap="round" stroke-linejoin="round" />
                                    <path d="M8.61117 9.3336C9.34755 9.3336 9.9445 8.73664 9.9445 8.00026C9.9445 7.26388 9.34755 6.66693 8.61117 6.66693C7.87479 6.66693 7.27783 7.26388 7.27783 8.00026C7.27783 8.73664 7.87479 9.3336 8.61117 9.3336Z" stroke="white" stroke-linecap="round" stroke-linejoin="round" />
                                </svg>
                                <p>각도</p>
                            </div>
                            <div className="con_state label01">
                            </div>
                        </div>
                        <div className="state_box label01">
                            {detect}
                        </div>
                    </div>
                    <Webcam
                        mirrored={camara_mirror}
                        ref={webcam}
                        muted={true}
                        videoConstraints={{
                            width: 2560,
                            height: 1440,
                            facingMode: "user",
                        }}
                        onUserMedia={() => {
                            console.log("카메라 준비 완료")
                            setCamara_on(true)
                        }}
                        onUserMediaError={() => {
                            console.log("카메라를 찾을수 없습니다.")
                        }}
                        screenshotFormat="image/jpeg" />
                    {/*<canvas ref={canvas} width={deviceResolution.width} height={deviceResolution.height} />*/}
                    <canvas ref={canvas} style={{ display: "none" }} />
                    <canvas ref={cropCanvasRef} style={{ display: "none" }} />
                    <canvas ref={webcamOveralyRef} style={{ display: "block" }} />
                    <div className="camara_guide_box">
                        {/* <svg xmlns="http://www.w3.org/2000/svg" width="31rem" height="38.6rem" viewBox="0 0 261 327" fill="none">
                            <path d="M130.499 0.00538258C106.977 1.25405 83.5942 8.04176 63.4241 20.6148C13.0579 51.611 0.690606 108.769 6.12263 165.265C8.06384 191.656 13.2895 217.973 24.4273 241.965C36.3693 268.127 54.6571 291.186 77.7748 307.855C85.3838 313.545 93.7887 317.982 102.699 321.093C111.639 324.204 121.046 325.937 130.499 326.217C120.987 326.477 111.428 325.215 102.278 322.463C79.2865 315.489 59.8112 299.023 44.0878 280.868C18.7131 251.03 5.20045 212.148 1.81912 173.029C-0.84215 148.635 -1.04848 123.653 4.37512 99.6262C7.60485 85.165 12.9021 71.1316 20.4985 58.4851C43.3635 20.308 87.6029 -0.383474 130.499 0.00538258Z" fill="url(#paint0_linear_2531_8499)" />
                            <path d="M130.499 0.00532155C173.395 -0.383535 217.634 20.308 240.503 58.4808C248.1 71.1273 253.397 85.1607 256.627 99.6218C262.05 123.649 261.84 148.635 259.183 173.025C255.801 212.144 242.289 251.03 216.91 280.864C201.182 299.023 181.72 315.476 158.724 322.458C149.574 325.211 140.015 326.472 130.503 326.213C139.956 325.932 149.363 324.2 158.303 321.089C167.217 317.982 175.622 313.541 183.227 307.85C206.345 291.181 224.632 268.126 236.575 241.961C247.712 217.968 252.938 191.651 254.879 165.261C260.303 108.721 247.956 51.6326 197.578 20.6104C177.408 8.0417 154.02 1.25398 130.499 0.00532155Z" fill="url(#paint1_linear_2531_8499)" />
                            <g clip-path="url(#clip0_2531_8499)">
                                <path d="M89.1746 150.076C89.7401 156.905 84.7376 162.908 78.0386 163.474C71.3396 164.039 65.4235 158.993 64.858 152.12C64.51 147.77 66.3805 143.768 69.5561 141.245C76.2116 140.288 81.7796 141.245 86.3906 143.115C87.9566 145.116 88.9571 147.466 89.1746 150.076Z" stroke="white" stroke-width="2.61001" stroke-miterlimit="10" stroke-linecap="round" />
                                <path d="M48.326 149.462C60.7825 140.86 76.8772 136.39 90.6245 144.203L90.6245 144.203L90.63 144.206C93.8392 145.976 96.6797 148.287 99.2201 150.901C97.487 149.54 95.6966 148.254 93.784 147.125C78.6448 137.739 62.7375 142.137 48.326 149.462Z" fill="white" stroke="white" />
                                <path d="M171.825 150.076C171.259 156.905 176.262 162.908 182.961 163.474C189.66 164.039 195.576 158.993 196.141 152.12C196.489 147.77 194.619 143.768 191.443 141.245C184.788 140.288 179.22 141.245 174.609 143.116C173.043 145.117 172.042 147.466 171.825 150.076Z" stroke="white" stroke-width="2.61001" stroke-miterlimit="10" stroke-linecap="round" />
                                <path d="M170.375 144.16C184.123 136.384 200.218 140.86 212.675 149.462C198.263 142.137 182.356 137.734 167.217 147.081C165.321 148.201 163.545 149.474 161.826 150.822C164.367 148.223 167.204 145.924 170.372 144.161L170.372 144.161L170.375 144.16Z" fill="white" stroke="white" />
                            </g>
                            <defs>
                                <linearGradient id="paint0_linear_2531_8499" x1="65.2495" y1="0" x2="65.2495" y2="326.251" gradientUnits="userSpaceOnUse">
                                    <stop offset="0.045" stop-color="white" stop-opacity="0.3" />
                                    <stop offset="0.505" stop-color="white" />
                                    <stop offset="1" stop-color="white" stop-opacity="0.3" />
                                </linearGradient>
                                <linearGradient id="paint1_linear_2531_8499" x1="195.749" y1="-6.10352e-05" x2="195.749" y2="326.246" gradientUnits="userSpaceOnUse">
                                    <stop offset="0.045" stop-color="white" stop-opacity="0.3" />
                                    <stop offset="0.505" stop-color="white" />
                                    <stop offset="1" stop-color="white" stop-opacity="0.3" />
                                </linearGradient>
                                <clipPath id="clip0_2531_8499">
                                    <rect width="177.48" height="25.23" fill="white" transform="translate(41.76 139.635)" />
                                </clipPath>
                            </defs>
                        </svg> */}
                        <div className="guide_back">
                            <svg width="36rem" height="79.5rem" viewBox="0 0 360 795" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g filter="url(#filter0_b_3885_14736)">
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M454 0H-94V814H454V0ZM320.696 324.944C322.33 329.461 324.125 334.745 325.348 339.403V339.408C332.993 367.266 333.7 396.809 330.918 425.469C330.146 433.705 329.208 443.077 327.875 451.202C318.826 509.628 289.475 561.089 240.949 593.302C240.854 593.363 240.759 593.426 240.663 593.489C240.571 593.55 240.478 593.611 240.387 593.67C222.049 605.691 201.025 611.702 180 611.702C158.975 611.702 137.951 605.691 119.613 593.67C119.522 593.611 119.43 593.55 119.338 593.49C119.242 593.427 119.146 593.364 119.051 593.302C70.5252 561.094 41.1688 509.628 32.1253 451.202C30.7918 443.077 29.8544 433.705 29.0824 425.469C26.3002 396.809 27.007 367.266 34.6519 339.403C35.8751 334.75 37.6697 329.461 39.304 324.944C41.0736 320.357 43.134 315.421 45.3397 311.035C70.3849 259.191 125.528 231.888 179.92 230.677H180.075C234.472 231.888 289.61 259.191 314.66 311.035C316.861 315.416 318.927 320.357 320.696 324.944Z" fill="black" fill-opacity="0.3" />
                                </g>
                                <defs>
                                    <filter id="filter0_b_3885_14736" x="-102" y="-8" width="564" height="830" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                                        <feFlood flood-opacity="0" result="BackgroundImageFix" />
                                        <feGaussianBlur in="BackgroundImageFix" stdDeviation="4" />
                                        <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_3885_14736" />
                                        <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_3885_14736" result="shape" />
                                    </filter>
                                </defs>
                            </svg>
                        </div>
                        <div className={face_guide_nomal}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="31rem" height="38.6rem" viewBox="0 0 310 386" fill="none">
                                <g clip-path="url(#clip0_3885_14739)">
                                    <path d="M16.1114 90.5312C8.22736 115.336 5.07481 141.256 5.25964 167.134C5.29132 176.861 5.91972 186.527 6.5798 196.233C7.57784 212.461 9.42078 228.511 12.1562 244.637C6.7699 229.134 3.68072 212.88 1.93811 196.606C0.0212406 180.383 -0.681085 163.71 0.781653 147.44C2.54011 127.859 7.13954 108.227 16.1114 90.5312Z" fill="url(#paint0_linear_3885_14739)" />
                                    <path d="M297.834 244.642C301.229 225.311 302.914 205.99 304.07 186.506C305.242 167.201 304.947 147.716 302.359 128.524C300.622 115.653 297.77 103.003 293.878 90.5364C296.883 96.399 299.323 102.527 301.461 108.763C309.71 133.885 311.341 160.709 309.05 186.859C307.275 206.328 304.339 226.114 297.828 244.642H297.834Z" fill="url(#paint1_linear_3885_14739)" />
                                    <path d="M78.2909 351.601C89.8555 359.217 101.784 366.705 114.896 371.463C124.491 375.031 134.588 377.525 144.838 378.41C169.012 380.766 193.145 374.484 213.966 362.616C216.95 360.919 220.034 359.017 222.954 357.218C225.922 355.358 228.879 353.451 231.82 351.504C222.004 361.645 210.618 370.559 197.538 376.559C170.961 389.133 139.193 389.143 112.599 376.61C99.5033 370.62 88.1446 361.757 78.2856 351.601H78.2909Z" fill="url(#paint2_linear_3885_14739)" />
                                </g>
                                <defs>
                                    <linearGradient id="paint0_linear_3885_14739" x1="8.05519" y1="62.3636" x2="8.05519" y2="276.274" gradientUnits="userSpaceOnUse">
                                        <stop stop-color="#CACACA" stop-opacity="0" />
                                        <stop offset="0.5" stop-color="white" />
                                        <stop offset="1" stop-color="#CACACA" stop-opacity="0" />
                                    </linearGradient>
                                    <linearGradient id="paint1_linear_3885_14739" x1="301.937" y1="62.3687" x2="301.937" y2="276.279" gradientUnits="userSpaceOnUse">
                                        <stop stop-color="#CACACA" stop-opacity="0" />
                                        <stop offset="0.5" stop-color="white" />
                                        <stop offset="1" stop-color="#CACACA" stop-opacity="0" />
                                    </linearGradient>
                                    <linearGradient id="paint2_linear_3885_14739" x1="232.013" y1="368.467" x2="77.9763" y2="368.467" gradientUnits="userSpaceOnUse">
                                        <stop stop-color="#CACACA" stop-opacity="0" />
                                        <stop offset="0.5" stop-color="white" />
                                        <stop offset="1" stop-color="#CACACA" stop-opacity="0" />
                                    </linearGradient>
                                    <clipPath id="clip0_3885_14739">
                                        <rect width="310" height="386" fill="white" />
                                    </clipPath>
                                </defs>
                            </svg>
                        </div>
                        <div className={face_guide_warn}>
                        <svg width="31rem" height="38.6rem" viewBox="0 0 310 386" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_175_59)">
<path d="M16.1114 90.5313C8.22736 115.336 5.07481 141.256 5.25964 167.134C5.29132 176.861 5.91972 186.527 6.5798 196.233C7.57784 212.461 9.42078 228.511 12.1562 244.637C6.7699 229.134 3.68072 212.88 1.93811 196.606C0.0212406 180.383 -0.681085 163.71 0.781653 147.44C2.54011 127.859 7.13954 108.227 16.1114 90.5313Z" fill="url(#paint0_linear_175_59)"/>
<path d="M297.834 244.642C301.229 225.311 302.914 205.99 304.07 186.506C305.242 167.201 304.947 147.716 302.359 128.524C300.622 115.653 297.77 103.003 293.878 90.5364C296.883 96.399 299.323 102.527 301.461 108.763C309.71 133.885 311.341 160.709 309.05 186.859C307.275 206.328 304.339 226.114 297.828 244.642H297.834Z" fill="url(#paint1_linear_175_59)"/>
<path d="M78.2909 351.601C89.8555 359.217 101.784 366.705 114.896 371.463C124.491 375.031 134.588 377.525 144.838 378.41C169.012 380.766 193.145 374.484 213.966 362.616C216.95 360.919 220.034 359.017 222.954 357.218C225.922 355.358 228.879 353.451 231.82 351.504C222.004 361.645 210.618 370.559 197.538 376.559C170.961 389.133 139.193 389.143 112.599 376.61C99.5033 370.62 88.1446 361.757 78.2856 351.601H78.2909Z" fill="url(#paint2_linear_175_59)"/>
</g>
<defs>
<linearGradient id="paint0_linear_175_59" x1="8.05519" y1="62.3636" x2="8.05519" y2="276.274" gradientUnits="userSpaceOnUse">
<stop stop-color="#CACACA" stop-opacity="0"/>
<stop offset="0.5" stop-color="#ED3B66"/>
<stop offset="1" stop-color="#CACACA" stop-opacity="0"/>
</linearGradient>
<linearGradient id="paint1_linear_175_59" x1="301.937" y1="62.3687" x2="301.937" y2="276.279" gradientUnits="userSpaceOnUse">
<stop stop-color="#CACACA" stop-opacity="0"/>
<stop offset="0.5" stop-color="#ED3B66"/>
<stop offset="1" stop-color="#CACACA" stop-opacity="0"/>
</linearGradient>
<linearGradient id="paint2_linear_175_59" x1="232.013" y1="368.467" x2="77.9763" y2="368.467" gradientUnits="userSpaceOnUse">
<stop stop-color="#CACACA" stop-opacity="0"/>
<stop offset="0.5" stop-color="#ED3B66"/>
<stop offset="1" stop-color="#CACACA" stop-opacity="0"/>
</linearGradient>
<clipPath id="clip0_175_59">
<rect width="310" height="386" fill="white"/>
</clipPath>
</defs>
</svg>

                        </div>
                        <div className={face_guide_good}>
                            <svg width="31rem" height="38.6rem" viewBox="0 0 310 386" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g clip-path="url(#clip0_3787_10174)">
                                    <path d="M16.1112 90.5312C8.22723 115.336 5.07469 141.256 5.25951 167.134C5.2912 176.861 5.91959 186.527 6.57967 196.233C7.57772 212.461 9.42066 228.511 12.156 244.637C6.76978 229.134 3.6806 212.88 1.93799 196.606C0.0211185 180.383 -0.681207 163.71 0.781531 147.44C2.53998 127.859 7.13942 108.227 16.1112 90.5312Z" fill="url(#paint0_linear_3787_10174)" />
                                    <path d="M297.833 244.642C301.229 225.311 302.913 205.99 304.07 186.506C305.242 167.201 304.946 147.716 302.359 128.524C300.622 115.653 297.77 103.003 293.878 90.5364C296.883 96.399 299.323 102.527 301.461 108.763C309.71 133.885 311.341 160.709 309.05 186.859C307.275 206.328 304.339 226.114 297.828 244.642H297.833Z" fill="url(#paint1_linear_3787_10174)" />
                                    <path d="M78.2908 351.601C89.8554 359.217 101.784 366.705 114.896 371.463C124.491 375.031 134.588 377.525 144.837 378.41C169.012 380.766 193.145 374.484 213.966 362.616C216.95 360.919 220.034 359.017 222.954 357.218C225.922 355.358 228.879 353.451 231.82 351.504C222.003 361.645 210.618 370.559 197.538 376.559C170.961 389.133 139.192 389.143 112.599 376.61C99.5031 370.62 88.1445 361.757 78.2855 351.601H78.2908Z" fill="url(#paint2_linear_3787_10174)" />
                                </g>
                                <defs>
                                    <linearGradient id="paint0_linear_3787_10174" x1="8.05507" y1="62.3636" x2="8.05507" y2="276.274" gradientUnits="userSpaceOnUse">
                                        <stop stop-color="#CACACA" stop-opacity="0" />
                                        <stop offset="0.5" stop-color="#40BAFF" />
                                        <stop offset="1" stop-color="#CACACA" stop-opacity="0" />
                                    </linearGradient>
                                    <linearGradient id="paint1_linear_3787_10174" x1="301.937" y1="62.3687" x2="301.937" y2="276.279" gradientUnits="userSpaceOnUse">
                                        <stop stop-color="#CACACA" stop-opacity="0" />
                                        <stop offset="0.5" stop-color="#40BAFF" />
                                        <stop offset="1" stop-color="#CACACA" stop-opacity="0" />
                                    </linearGradient>
                                    <linearGradient id="paint2_linear_3787_10174" x1="232.013" y1="368.467" x2="77.9762" y2="368.467" gradientUnits="userSpaceOnUse">
                                        <stop stop-color="#CACACA" stop-opacity="0" />
                                        <stop offset="0.5" stop-color="#40BAFF" />
                                        <stop offset="1" stop-color="#CACACA" stop-opacity="0" />
                                    </linearGradient>
                                    <clipPath id="clip0_3787_10174">
                                        <rect width="310" height="386" fill="white" />
                                    </clipPath>
                                </defs>
                            </svg>
                        </div>

                    </div>
                </div>
                <div className="camara_under over">
                    <div className="camara_btn_wrap">
                        <div className="small02">
                            촬영 조건이 모두 좋음일 때 <br /> 자동으로 촬영돼요.
                        </div>
                        <div className="refresh_btn" onClick={switchCamera}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="2.4rem" height="2.4rem" viewBox="0 0 24 24" fill="none">
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M2.93077 11.2003C3.00244 6.23968 7.07619 2.25 12.0789 2.25C15.3873 2.25 18.287 3.99427 19.8934 6.60721C20.1103 6.96007 20.0001 7.42199 19.6473 7.63892C19.2944 7.85585 18.8325 7.74565 18.6156 7.39279C17.2727 5.20845 14.8484 3.75 12.0789 3.75C7.8945 3.75 4.50372 7.0777 4.431 11.1982L4.83138 10.8009C5.12542 10.5092 5.60029 10.511 5.89203 10.8051C6.18377 11.0991 6.18191 11.574 5.88787 11.8657L4.20805 13.5324C3.91565 13.8225 3.44398 13.8225 3.15157 13.5324L1.47176 11.8657C1.17772 11.574 1.17585 11.0991 1.46759 10.8051C1.75933 10.5111 2.2342 10.5092 2.52824 10.8009L2.93077 11.2003Z" fill="#6B5FFF" />
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M19.7868 10.4666C20.0789 10.1778 20.5491 10.1778 20.8412 10.4666L22.5275 12.1332C22.8221 12.4244 22.8248 12.8993 22.5337 13.1939C22.2425 13.4885 21.7676 13.4913 21.473 13.2001L21.0631 12.7949C20.9937 17.7604 16.902 21.75 11.8828 21.75C8.56414 21.75 5.65416 20.007 4.04154 17.3939C3.82401 17.0414 3.93342 16.5793 4.28591 16.3618C4.63841 16.1442 5.10051 16.2536 5.31804 16.6061C6.66595 18.7903 9.10034 20.25 11.8828 20.25C16.0891 20.25 19.4925 16.9171 19.5629 12.7969L19.155 13.2001C18.8604 13.4913 18.3855 13.4885 18.0944 13.1939C17.8032 12.8993 17.8059 12.4244 18.1005 12.1333L19.7868 10.4666Z" fill="#6B5FFF" />
                            </svg>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default WebcamPage