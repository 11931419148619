import Sub_top_bar from "./sub_top";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { useLocation, useNavigate } from "react-router-dom";
import { useState ,useRef, useEffect } from "react";
import usePreviousPageRedirect from "./usePreviousPageRedirect";

function SimpleSlider() {
    let navigate = useNavigate()
    var settings = {
        autoplay: false,
        dots: true,
        arrows: false,
        infinite: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        beforeChange: (current, next) => next == 2 ? setBtnState("active") : setBtnState(" ")
    };
    let sliderRef = useRef(null);

    const next = () => {
        sliderRef.slickNext();
    };

    let [BtnState, setBtnState] = useState(" ")

    return (
        <>
            <Slider
                ref={slider => {
                    sliderRef = slider;
                }}
                {...settings}>
                <div>
                    <img className="slide_img" src={process.env.PUBLIC_URL + "/img/skin_guide/skin_guide_01.png"}></img>
                </div>
                <div>
                    <img className="slide_img" src={process.env.PUBLIC_URL + "/img/skin_guide/skin_guide_02.png"}></img>
                </div>
                <div>
                    <img className="slide_img" src={process.env.PUBLIC_URL + "/img/skin_guide/skin_guide_03.png"}></img>
                </div>
            </Slider>
            <div className='fixed_btn_wrap'>
                <div className="btn_fixed">
                    
                <div className={BtnState + " btn_wrap"}>
                    <div className="p200_btn small01" onClick={next}> 다음 </div>
                    <div className="primary_fill_btn small01" onClick={() => navigate('/camara_api',{state:"camara_api"})}>
                        <img style={{width:"2.5rem"}} src={process.env.PUBLIC_URL + "/img/camara_icon.png"}></img> 피부 촬영 시작
                    </div>
                </div>
                </div>
            </div>
        </>
    );
}

function Camara_guide() {
    const {state} = useLocation() 
    let navigate = useNavigate()

    useEffect(()=>{
        console.log(state)
        if(state !== "camara_guide"){
            navigate("/")
        }
    },[])
  
    return (
        <div className="sub_page">
            <Sub_top_bar title="피부 촬영"></Sub_top_bar>
            <div className="sub_body_wrap" style={{paddingBottom : "14rem"}}>
                <div className="sub_body con">
                    <div className="camera_slide_wrap">
                        <SimpleSlider></SimpleSlider>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default Camara_guide