// usePreviousPageRedirect.js
import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

const usePreviousPageRedirect = (validPaths, mainPagePath = '/') => {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const previousPath = document.referrer; // 이전 페이지의 주소를 가져옵니다.

    // 현재 경로가 유효한 경로들 중 하나인지 확인합니다.
    const isValidPath = validPaths.some(path => location.pathname.startsWith(path));

    console.log(previousPath)
    console.log(isValidPath)

    // 이전 페이지가 유효한 경로에 포함되지 않으면 메인 페이지로 리디렉션합니다.
    if (!isValidPath && previousPath) {
      // navigate(mainPagePath);
    }
  }, []);

};

export default usePreviousPageRedirect;
