import { json, useLocation, useNavigate } from "react-router-dom";
import Sub_top_bar from "./sub_top"
import { useState, useEffect } from "react"
import usePreviousPageRedirect from "./usePreviousPageRedirect";


function Person_info(){
    const validPaths = ['/', '/']; // 유효한 경로들
    usePreviousPageRedirect(validPaths);
    const {state} = useLocation()

    useEffect(()=>{
        if(state !=="info"){
            navigate("/")
        }
    },[])
    
    const [name, setName] = useState('');
    const [birthdate, setBirthdate] = useState('');
    const [gender, setGender] = useState('');
    const [btn_activate, set_btn_activate] = useState(false);
    const [policy_btn, setPolicy_btn] = useState(0)
    const [user_email, setUser_email] = useState("")
    let navigate = useNavigate();
    let [agreeState, setAgreeState] = useState([false, false, false]);
    // [allAgree, agree01, agree02]
    let [before_data, setBefore_data] = useState({})
    let [formData, setFormData] = useState({
        name: name,
        birthdate: birthdate,
        gender: gender,
        email: user_email,
        agree01: agreeState[1],
        agree02: agreeState[2],
    })
    useEffect(() => {
        if (policy_btn !== 0) {
            window.history.pushState(null, null, window.location.pathname);
        }

        const handlePopState = (event) => {
            if (policy_btn !== 0) {
                setPolicy_btn(0);
            } else {
                navigate('/'); // 특정 페이지로 이동
            }
        };

        window.addEventListener('popstate', handlePopState);

        return () => {
            window.removeEventListener('popstate', handlePopState);
        };
    }, [policy_btn, navigate]);
    let handleAllAgreeChange = (e) => {
        let checked = e.target.checked;
        setAgreeState([checked, checked, checked]);
    };
    // 개별 동의 체크박스 핸들러 (index: 1 또는 2)
    let handleIndividualAgreeChange = (index) => (e) => {
        let updatedAgrees = [...agreeState];
        updatedAgrees[index] = e.target.checked;
        // 모든 개별 체크박스가 체크되었는지 확인하여 모두 동의 상태 업데이트
        updatedAgrees[0] = updatedAgrees[1] && updatedAgrees[2];
        setAgreeState(updatedAgrees);
    };
    useEffect(() => {
        if (name != "" && birthdate != "" && gender != "" && agreeState[1] == true && agreeState[2] == true && birthdate.length == 10 && user_email != "") {
            set_btn_activate(true)
            setFormData({
                name: name,
                birthdate: birthdate,
                gender: gender,
                email: user_email,
                agree01: agreeState[1],
                agree02: agreeState[2],
            })
            console.log(formData)
        } else {
            set_btn_activate(false)
        }
    }, [name, birthdate, gender, agreeState, user_email]);
    // useEffect(() => {
    //     setFormData({
    //         name: name,
    //         birthdate: birthdate,
    //         gender: gender,
    //         email: user_email,
    //         agree01: agreeState[1],
    //         agree02: agreeState[2],
    //     })
    // }, [agreeState[2]]);


    // 이전 데이터가 있을경우

    // 컴포넌트가 처음 렌더링될 때 로컬스토리지를 확인
    useEffect(() => {
        let userdata = localStorage.getItem("user_send_data")
        if (userdata) {
            // 로컬스토리지에서 가져온 데이터를 객체로 변환
            const parsedData = JSON.parse(userdata);
            // formData 상태를 로컬스토리지의 값으로 업데이트
            setFormData({
                name: parsedData.name || name,
                birthdate: parsedData.birthdate || birthdate,
                gender: parsedData.gender || gender,
                email: parsedData.email || user_email,
                agree01: parsedData.agree01 !== undefined ? parsedData.agree01 : agreeState[1],
                agree02: parsedData.agree02 !== undefined ? parsedData.agree02 : agreeState[2],
            });

            document.querySelector(".name_input > input").value = parsedData.name
            if(parsedData.gender == "female"){document.querySelector("#gender_woman").checked = true }
            if(parsedData.gender == "male"){document.querySelector("#gender_man").checked = true }
            document.querySelector(".email_input > input").value = parsedData.email

            setName(parsedData.name)
            setBirthdate(parsedData.birthdate)
            setGender(parsedData.gender)
            setUser_email(parsedData.email)
            if(parsedData.agree01 && parsedData.agree02){
                setAgreeState([true,parsedData.agree01,parsedData.agree02])
            } else {
                setAgreeState([false,parsedData.agree01,parsedData.agree02])
            }
        }
    }, []);
    useEffect(()=>{
        console.log(formData)
    } , [formData])
    const check_dirth = (e) => {
        let input = e.target.value.replace(/\D/g, '');
        let formattedInput = '';
        if (input.length > 4 && !['0', '1'].includes(input[4])) {
            input = input.slice(0, 4);
        }
        if (input.length > 6 && !['0', '1', '2', '3'].includes(input[6])) {
            input = input.slice(0, 6);
        }
        if (input.length <= 4) {
            formattedInput = input;
        } else if (input.length <= 6) {
            formattedInput = `${input.slice(0, 4)}/${input.slice(4, 6)}`;
        } else {
            formattedInput = `${input.slice(0, 4)}/${input.slice(4, 6)}/${input.slice(6, 8)}`;
        }
        setBirthdate(formattedInput);
    };
    const validateBirthdate = (e) => {
        const input = e.target.value;
        // 입력된 값을 YYYY/MM/DD 형식으로 분리
        const parts = input.split('/');
        if (parts.length !== 3) {
            alert('잘못된 날짜 형식입니다. YYYY/MM/DD 형식으로 입력해주세요.');
            e.target.parentNode.classList.add("invalid")
            return;
        }

        const year = parseInt(parts[0], 10);
        const month = parseInt(parts[1], 10);
        const day = parseInt(parts[2], 10);

        // 연도 검사
        if (year < 1930 || year > 2020) {
            e.target.parentNode.classList.add("invalid")
            return;
        }

        // 월 검사
        if (month < 1 || month > 12) {
            e.target.parentNode.classList.add("invalid")
            return;
        }

        // 날짜 검사
        const date = new Date(year, month - 1, day);
        if (date.getFullYear() !== year || date.getMonth() + 1 !== month || date.getDate() !== day) {
            e.target.parentNode.classList.add("invalid")
            return;
        }

        e.target.parentNode.classList.remove("invalid")
    };
    function check_name(name) {
        const name_pattern = /[^a-zA-Z가-힣\s]/;
        const test = name_pattern.test(name)

        if (test) {
            console.log("이상한 이름입니다.")
            document.querySelector(".name_input").classList.add("invalid")
        } else {
            document.querySelector(".name_input").classList.remove("invalid")
            console.log("올바른 이름입니다.")
            setName(name)
        }
    }
    function Check_email(e) {
        let email_regex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/i;
        if (!email_regex.test(e.target.value)) {
            e.target.parentNode.classList.add("invalid")
            setUser_email("")
        } else {
            e.target.parentNode.classList.remove("invalid")
            setUser_email(e.target.value)
        }
    }

    return (
        <div className="sub_page">
            <div className='fixed_btn_wrap'>
                <div className="btn_fixed">

                    {btn_activate ?
                        <div className="primary_fill_btn small01" onClick={(e) => {
                            console.log(formData)
                            localStorage.setItem("user_send_data", JSON.stringify(formData))
                            navigate('/camara_guide',{state:"camara_guide"})
                            // axios.post("/camara_guide", { params: formData })
                            //     .then((result) => {
                            //         // console.log("전송완료")
                            //         navigate('/camara_guide')
                            //     }).catch((err) => {
                            //         // console.log(err)
                            //         navigate('/camara_guide')
                            //     })
                        }}>다음</div>
                        :
                        <div className="gray_btn small01">다음</div>
                    }
                </div>
            </div>
            <Sub_top_bar title="정보 입력"></Sub_top_bar>
            <div className="sub_body_wrap" style={{ paddingBottom: "19rem" }}>
                <div className="sub_body">
                    <div className="con">
                        <div className="sub_title">
                            <div className="sub_headline02">
                                정보입력
                            </div>
                            <div className="small02">
                                정확한 분석을 위해 정보 입력이 필요해요.
                            </div>
                        </div>
                        <div className="info_wrap">
                            <div className="form form_type_01">
                                <div className="lable_box name_input">
                                    <label className="small02" htmlFor="name">이름</label>
                                    <input required id="name" type="text" placeholder="이름을 입력해 주세요" onChange={(e) => {
                                        check_name(e.target.value)
                                    }} />
                                </div>
                                <div className="lable_box birth_input">
                                    <label className="small02" htmlFor="birth">생년월일</label>
                                    <input
                                        type="tel"
                                        placeholder="YYYY/MM/DD"
                                        value={birthdate}
                                        onChange={check_dirth}
                                        onBlur={(e) => {
                                            validateBirthdate(e)
                                        }}
                                        maxLength="10"
                                    />
                                </div>
                                <div className="lable_box">
                                    <label className="small02">성별</label>
                                    <div className="radio_wrap flex">
                                        <div className="radio">
                                            <input required name="gender" type="radio" value="female" id="gender_woman" onChange={(e) => {
                                                setGender(e.target.value)
                                            }} />
                                            <label htmlFor="gender_woman">여성</label>
                                        </div>
                                        <div className="radio">
                                            <input required name="gender" type="radio" value="male" id="gender_man" onChange={(e) => {
                                                setGender(e.target.value)
                                            }} />
                                            <label htmlFor="gender_man">남성</label>
                                        </div>
                                    </div>
                                </div>
                                <div className="lable_box email_input">
                                    <label className="small02" htmlFor="email">이메일</label>
                                    <input required id="email" type="email" placeholder="이메일 주소를 입력해 주세요" onChange={(e) => {
                                        Check_email(e)
                                    }}
                                    />
                                </div>
                                <div className="agree_check">

                                    <div className="lable_box check all_agree">
                                        <input
                                            onChange={handleAllAgreeChange}
                                            checked={agreeState[0]}
                                            type="checkbox"
                                            required
                                            name="gender"
                                            value="all_agree"
                                            id="all_agree"
                                        />
                                        <label className="small02" htmlFor="all_agree">
                                            <div className="icon_box">
                                                <img src={process.env.PUBLIC_URL + "/img/check_btn.png"} />
                                                <img src={process.env.PUBLIC_URL + "/img/check_btn_active.png"} />
                                            </div>
                                            <div className="label_text">
                                                전체동의
                                            </div>
                                        </label>
                                    </div>
                                    <div className="lable_box check">
                                        <input
                                            onChange={handleIndividualAgreeChange(1)}
                                            checked={agreeState[1]}
                                            type="checkbox"
                                            required
                                            name="agree01"
                                            value="agree01"
                                            id="agree01"
                                        />
                                        <label className="small02" htmlFor="agree01">
                                            <div className="icon_box">
                                                <img src={process.env.PUBLIC_URL + "/img/check_btn.png"} />
                                                <img src={process.env.PUBLIC_URL + "/img/check_btn_active.png"} />
                                            </div>
                                            <div className="text_box">
                                                (필수) 개인정보 수집 및 이용 동의
                                            </div>
                                        </label>
                                        <div className="arrow_box" onClick={() => { setPolicy_btn(1) }}>
                                            <img src={process.env.PUBLIC_URL + "/img/right_arrow.png"} />
                                        </div>
                                    </div>
                                    <div className="lable_box check">
                                        <input
                                            onChange={handleIndividualAgreeChange(2)}
                                            checked={agreeState[2]}
                                            type="checkbox"
                                            name="agree02"
                                            value="agree02"
                                            id="agree02"
                                        />
                                        <label className="small02" htmlFor="agree02">
                                            <div className="icon_box">
                                                <img src={process.env.PUBLIC_URL + "/img/check_btn.png"} />
                                                <img src={process.env.PUBLIC_URL + "/img/check_btn_active.png"} />
                                            </div>
                                            <div className="text_box">
                                                (필수) 민감정보 수집 및 이용 동의
                                            </div>
                                        </label>
                                        <div className="arrow_box" onClick={() => {
                                            setPolicy_btn(2)
                                            // console.log(policy_btn)
                                        }}>
                                            <img src={process.env.PUBLIC_URL + "/img/right_arrow.png"} />
                                        </div>
                                    </div>
                                </div>


                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {policy_btn == 1 ? <Policy_1 setPolicy_btn={setPolicy_btn}></Policy_1> : null}
            {policy_btn == 2 ? <Policy_2 setPolicy_btn={setPolicy_btn}></Policy_2> : null}

        </div>
    )
}

function Policy_1({ setPolicy_btn }) {

    useEffect(() => {
        const handleBackButton = (event) => {
          event.preventDefault();
        //   alert("뒤로가기 버튼이 눌렸습니다.");
          // 브라우저의 기본 뒤로가기 동작을 차단합니다.
          window.history.forward();
        };
    
        window.addEventListener('popstate', handleBackButton);
    
        return () => {
          window.removeEventListener('popstate', handleBackButton);
        };
    }, []);
    
    return (
        <div className="sub_page modal_page">
            <div className="sub_top_bar small02">
                <div className="back_btn" onClick={() => {
                    setPolicy_btn(0)
                }}>
                    <img style={{ width: "0.8rem" }} src={process.env.PUBLIC_URL + "/img/back_arrow.png"} />
                </div>
                <div className="sub_page_top_title">
                    개인정보 수집 및 이용
                </div>
            </div>
            <div className="sub_body_wrap">
                <div className="sub_body">
                    <div className="con small02">
                        개인정보 수집 및 이용 동의 [필수] <br></br>
                                (주)룰루랩은 이용자의 피부 나이 측정 및 상태 등의 피부 정보 분석 서비스 제공을 위해 아래와 같이 개인정보를 수집·이용 합니다.<br></br> <br></br>
                        <ul>
                            <li>
                                    수집 및 이용 항목 :
                                성별, 나이, 전화번호, 사진, 피부 관련 문진 정보
                            </li>
                            <li>
                                수집 및 이용 목적 :
                                피부 데이터 분석 및 결과 도출 / 피부 분석 데이터 활용
                            </li>
                            <li>
                                이용 및 보유 기간 :
                                수집일로부터 5년
                                귀하는 개인정보 수집에 동의를 거부할 권리가 있으며, 동의를 거부할 경우 피부 분석 서비스를 받으실 수 없습니다. 수집된 개인정보는 다른 목적으로 사용하지 않습니다.
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    )
}

function Policy_2({ setPolicy_btn }) {
    useEffect(() => {
        const handleBackButton = (event) => {
          event.preventDefault();
        //   alert("뒤로가기 버튼이 눌렸습니다.");
          // 브라우저의 기본 뒤로가기 동작을 차단합니다.
          window.history.forward();
        };
    
        window.addEventListener('popstate', handleBackButton);
    
        return () => {
          window.removeEventListener('popstate', handleBackButton);
        };
    }, []);
    return (
        <div className="sub_page modal_page">
            <div className="sub_top_bar small02">
                <div className="back_btn" onClick={() => {
                    setPolicy_btn(0)
                }}>
                    <img style={{ width: "0.8rem" }} src={process.env.PUBLIC_URL + "/img/back_arrow.png"} />
                </div>
                <div className="sub_page_top_title">
                    민감정보 수집 및 이용 동의
                </div>
            </div>
            <div className="sub_body_wrap">
                <div className="sub_body">
                    <div className="con small02">
                            민감정보 수집 및 이용 동의 [필수] <br></br>
                            (주)룰루랩은 이용자의 피부 나이 측정 및 상태 등의 피부 정보 분석 서비스 제공을 위해 아래와 같이 민감정보를 수집·이용 합니다.<br></br> <br></br>
                        <ul>
                            <li>
                                수집 및 이용 항목 :
                                피부 측정 결과(피부 나이, 피부 상태, 피부 점수)
                            </li>
                            <li>
                                수집 및 이용 목적 :
                                피부 데이터 분석 및 결과 도출 / 피부 분석 데이터 활용
                            </li>
                            <li>
                                이용 및 보유 기간 :
                                수집일로부터 5년
                                귀하는 개인정보 수집에 동의를 거부할 권리가 있으며, 동의를 거부할 경우 피부 분석 서비스를 받으실 수 없습니다. 수집된 개인정보는 다른 목적으로 사용하지 않습니다.
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Person_info