import { useEffect, useRef, useState } from "react";
import Sub_top_bar from "./sub_top";
import { json, useLocation, useNavigate, useParams } from "react-router-dom";
import axios from "axios";

function Skin_survey() {

    let navigate = useNavigate()
    let params = useParams()
    let total_question = 6
    let [SurveyProgress, setSurveyProgress] = useState(0)
    let [progress_percent, setProgress_percent] = useState(SurveyProgress / total_question * 100)
    let [close, setClose] = useState(false)
    let [Qna, setQna] = useState({})
    let [btn_activate, set_btn_activate] = useState(false)
    let prograssRef = useRef()

    const {state} = useLocation()

    function change_input(e) {
        // 전송 데이터 저장하기
        let input_value = e.target.value
        let copy = { ...Qna }
        let object_name = "q" + params.id
        copy[object_name] = input_value
        setQna(copy)
        next_btn()
    }

    function next_btn() {
        setTimeout(() => {
            document.querySelector(".survey_form_wrap").classList.add("fade-enter-active")
        }, 10);
        // 페이지 이동
        let nextPage = Number(params.id) + 1
        if(nextPage <= 6){
            console.log(Qna)
            console.log(Object.keys(Qna).length)
            navigate("/skin_survey/" + nextPage,{state:"skin_survey"})
        }
    }

    function prev_btn() {
        setTimeout(() => {
            document.querySelector(".survey_form_wrap").classList.add("fade-enter-active")
        }, 10);
        // 페이지 이동
        let nextPage = Number(params.id) - 1
        console.log(Qna)
        console.log(Object.keys(Qna).length)
        // 버튼 비활성화
        // console.log(Qna["q"+params.id])
        // if(Qna["q"+nextPage] == undefined){
        //     set_btn_activate(false)
        // } 
        navigate("/skin_survey/" + nextPage,{state:"skin_survey"})
    }

    function submit_btn() {
        let store_date = JSON.stringify(Qna)
        localStorage.setItem("survey_result", store_date)
        navigate("/survey_loading" ,{state:"survey_loading"})
    }

    let [fail_modal_btn, setFail_modal_btn] = useState(0)

    useEffect(() => {
        if (params.id <= 1) {
            setClose(true)
        } else {
            setClose(false)
        }
        // setProgress_percent(SurveyProgress / total_question * 100)
        setProgress_percent(Object.keys(Qna).length / total_question * 100)
        return () => {
        }
    })

    useEffect(() => {
        console.log(state)
        if( state !=="skin_survey"){
            navigate("/")
        }

        var survey_answer = localStorage.getItem("survey_result")
        
        if(survey_answer){
            survey_answer = JSON.parse(survey_answer)
            setQna(survey_answer)
        }

        setTimeout(() => {
            document.querySelector(".survey_form_wrap").classList.add("fade-enter-active")
        }, 10);
        
    }, [])

    useEffect(()=>{
        prograssRef.current.style.width = progress_percent + "%"
    },[progress_percent])

    useEffect(()=>{
        console.log(Object.keys(Qna).length)
        if(Object.keys(Qna).length === 6){
            console.log("모든 문항 답변 완료")
            set_btn_activate(true)
        }
    },[Qna])

    function gotoLink() {
        if (params.id == 1) {
            setFail_modal_btn(1)
        } else {
            navigate("/skin_survey/" + (params.id - 1) ,{state:"skin_survey"})
            setProgress_percent(params.id - 1)
            setTimeout(() => {
                document.querySelector(".survey_form_wrap").classList.add("fade-enter-active")
            }, 100);
        }
    }

    useEffect(()=>{
        document.querySelector(".back_btn").style.opacity = '0';
        setTimeout(() => {
            document.querySelector(".survey_form_wrap").classList.add("fade-enter-active")
        }, 10);
    })

    return (
        <div className="sub_page skin_survey">
            <Sub_top_bar title="피부 문진" close={close} gotoLink={gotoLink}></Sub_top_bar>
            <div className="sub_body_wrap" style={{paddingBottom: "14rem",position:"relative"}}>
                <div className="sub_body">
                    <div className="con">
                        <div className="progress_box">
                            <div className="progress_bar" ref={prograssRef}></div>
                        </div>
                        {params.id == 1 ?
                            <>
                                <div className="survey_form_wrap fade-enter-active">
                                    <div className="survey_num label02">
                                        {params.id} / 6
                                    </div>
                                    <div className="survey_title sub_headline02">
                                        T존 부위인 이마, 코, 턱 부위가  <br />
                                        자주 번들거리는 편인가요?
                                    </div>
                                    <div className="survey_radio_wrap">
                                        <div className="survey_radio label02">
                                            <input type="radio" name="skin_test_01" value="1" checked={Qna.q1 == 1? true : null} id="test_01_01" onChange={(e) => {
                                                change_input(e)
                                            }} />
                                            <label htmlFor="test_01_01">
                                                <div className="text_box">
                                                    그렇지 않은 편이에요
                                                </div>
                                                <div className="check_box"></div>
                                            </label>
                                        </div>
                                        <div className="survey_radio label02">
                                            <input type="radio" name="skin_test_01" value="2" checked={Qna.q1 == 2? true : null} id="test_01_02" onChange={(e) => {
                                                change_input(e)
                                            }} />
                                            <label htmlFor="test_01_02">
                                                <div className="text_box">
                                                    보통이에요
                                                </div>
                                                <div className="check_box"></div>
                                            </label>
                                        </div>
                                        <div className="survey_radio label02">
                                            <input type="radio" name="skin_test_01" checked={Qna.q1 == 3? true : null} value="3" id="test_01_03" onChange={(e) => {
                                                change_input(e)
                                            }} />
                                            <label htmlFor="test_01_03">
                                                <div className="text_box">
                                                    자주 그런 편이에요
                                                </div>
                                                <div className="check_box"></div>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div className="survey_btn_box">
                                    <div>
                                        <img className="slide_img" src={process.env.PUBLIC_URL + "/img/next_btn_deactive.svg"}></img>
                                    </div>
                                    <div onClick={next_btn}>
                                        <img className="slide_img" src={process.env.PUBLIC_URL + "/img/next_btn.svg"}></img>
                                    </div>
                                </div>
                            </>
                            : null}
                        {params.id == 2 ?
                            <>
                                <div className="survey_form_wrap">
                                    <div className="survey_num label02">
                                        {params.id} / 6
                                    </div>
                                    <div className="survey_title sub_headline02">
                                        세안 후 건조함을 느끼시나요?  <br />
                                    </div>
                                    <div className="survey_radio_wrap">
                                        <div className="survey_radio label02">
                                            <input type="radio" name="skin_test_02" checked={Qna.q2 == 1? true : null} value="1" id="test_01_01" onChange={(e) => {
                                                change_input(e)
                                            }} />
                                            <label htmlFor="test_01_01">
                                                <div className="text_box">
                                                    시간이 지나면 건조해요
                                                </div>
                                                <div className="check_box"></div>
                                            </label>
                                        </div>
                                        <div className="survey_radio label02">
                                            <input type="radio" name="skin_test_02" checked={Qna.q2 == 2? true : null} value="2" id="test_01_02" onChange={(e) => {
                                                change_input(e)
                                            }} />
                                            <label htmlFor="test_01_02">
                                                <div className="text_box">
                                                    시간이 지나면 괜찮아져요
                                                </div>
                                                <div className="check_box"></div>
                                            </label>
                                        </div>
                                        <div className="survey_radio label02">
                                            <input type="radio" name="skin_test_02" checked={Qna.q2 == 3 ? true : null} value="3" id="test_01_03" onChange={(e) => {
                                                change_input(e)
                                            }} />
                                            <label htmlFor="test_01_03">
                                                <div className="text_box">
                                                    세안 직후에도 건조하지 않아요
                                                </div>
                                                <div className="check_box"></div>
                                            </label>
                                        </div>
                                    </div>
                                </div>

                                <div className="survey_btn_box">
                                    <div onClick={prev_btn}>
                                    <img style={{transform:"rotate(180deg)"}} className="slide_img" src={process.env.PUBLIC_URL + "/img/next_btn.svg"}></img>
                                    </div>
                                    <div onClick={next_btn}>
                                        <img className="slide_img" src={process.env.PUBLIC_URL + "/img/next_btn.svg"}></img>
                                    </div>
                                </div>
                            </>
                            : null}
                        {params.id == 3 ?
                            <>
                                <div className="survey_form_wrap">
                                    <div className="survey_num label02">
                                        {params.id} / 6
                                    </div>
                                    <div className="survey_title sub_headline02">
                                        선크림이나 메이크업 제품을 바른 후 <br /> 보통 얼마나 지속되나요?
                                    </div>
                                    <div className="survey_radio_wrap">
                                        <div className="survey_radio label02">
                                            <input type="radio" name="skin_test_03" checked={Qna.q3 == 1 ? true : null} value="1" id="test_01_01" onChange={(e) => {
                                                change_input(e)
                                            }} />
                                            <label htmlFor="test_01_01">
                                                <div className="text_box">
                                                    다시 바르지 않아도 돼요
                                                </div>
                                                <div className="check_box"></div>
                                            </label>
                                        </div>
                                        <div className="survey_radio label02">
                                            <input type="radio" name="skin_test_03"  checked={Qna.q3 == 2 ? true : null} value="2" id="test_01_02" onChange={(e) => {
                                                change_input(e)
                                            }} />
                                            <label htmlFor="test_01_02">
                                                <div className="text_box">
                                                    4시간 정도는 문제 없어요
                                                </div>
                                                <div className="check_box"></div>
                                            </label>
                                        </div>
                                        <div className="survey_radio label02">
                                            <input type="radio" name="skin_test_03"  checked={Qna.q3 == 3 ? true : null} value="3" id="test_01_03" onChange={(e) => {
                                                change_input(e)
                                            }} />
                                            <label htmlFor="test_01_03">
                                                <div className="text_box">
                                                    2~3시간 정도 지속되지만 기름진 느낌이에요
                                                </div>
                                                <div className="check_box"></div>
                                            </label>
                                        </div>
                                    </div>
                                </div>

                                <div className="survey_btn_box">
                                    <div onClick={prev_btn}>
                                    <img style={{transform:"rotate(180deg)"}} className="slide_img" src={process.env.PUBLIC_URL + "/img/next_btn.svg"}></img>
                                    </div>
                                    <div onClick={next_btn}>
                                        <img className="slide_img" src={process.env.PUBLIC_URL + "/img/next_btn.svg"}></img>
                                    </div>
                                </div>
                            </>
                            : null}
                        {params.id == 4 ?
                            <>
                                <div className="survey_form_wrap">
                                    <div className="survey_num label02">
                                        {params.id} / 6
                                    </div>
                                    <div className="survey_title sub_headline02">
                                        보습제를 바르고 1~2시간 후 <br />얼굴이 당기거나 건조함이 느껴지니사요?
                                    </div>
                                    <div className="survey_radio_wrap">
                                        <div className="survey_radio label02">
                                            <input type="radio" name="skin_test_04"  checked={Qna.q4 == 0 ? true : null} value="0" id="test_01_01" onChange={(e) => {
                                                change_input(e)
                                            }} />
                                            <label htmlFor="test_01_01">
                                                <div className="text_box">
                                                    네, 그런 편이에요
                                                </div>
                                                <div className="check_box"></div>
                                            </label>
                                        </div>
                                        <div className="survey_radio label02">
                                            <input type="radio" name="skin_test_04"  checked={Qna.q4 == 1 ? true : null} value="1" id="test_01_03" onChange={(e) => {
                                                change_input(e)
                                            }} />
                                            <label htmlFor="test_01_03">
                                                <div className="text_box">
                                                    아니오, 거의 그렇지 않아요
                                                </div>
                                                <div className="check_box"></div>
                                            </label>
                                        </div>
                                    </div>
                                </div>


                                <div className="survey_btn_box">
                                    <div onClick={prev_btn}>
                                    <img style={{transform:"rotate(180deg)"}} className="slide_img" src={process.env.PUBLIC_URL + "/img/next_btn.svg"}></img>
                                    </div>
                                    <div onClick={next_btn}>
                                        <img className="slide_img" src={process.env.PUBLIC_URL + "/img/next_btn.svg"}></img>
                                    </div>
                                </div>
                            </>
                            : null}
                        {params.id == 5 ?
                            <>
                                <div className="survey_form_wrap">
                                    <div className="survey_num label02">
                                        {params.id} / 6
                                    </div>
                                    <div className="survey_title sub_headline02">
                                        크림과 같은 보습제를 발랐을 때 <br />피부에 따가움을 느낀 적이 있나요?
                                    </div>
                                    <div className="survey_radio_wrap">
                                        <div className="survey_radio label02">
                                            <input type="radio" name="skin_test_05"  checked={Qna.q5 == 0 ? true : null} value="0"  id="test_01_01" onChange={(e) => {
                                                change_input(e)
                                            }} />
                                            <label htmlFor="test_01_01">
                                                <div className="text_box">
                                                    네, 그런 적이 있어요
                                                </div>
                                                <div className="check_box"></div>
                                            </label>
                                        </div>
                                        <div className="survey_radio label02">
                                            <input type="radio" name="skin_test_05"  checked={Qna.q5 == 1 ? true : null} value="1" id="test_01_02" onChange={(e) => {
                                                change_input(e)
                                            }} />
                                            <label htmlFor="test_01_02">
                                                <div className="text_box">
                                                    아니오, 거의 그런 적 없어요
                                                </div>
                                                <div className="check_box"></div>
                                            </label>
                                        </div>
                                    </div>
                                </div>


                                <div className="survey_btn_box">
                                    <div onClick={prev_btn}>
                                        <img style={{transform:"rotate(180deg)"}} className="slide_img" src={process.env.PUBLIC_URL + "/img/next_btn.svg"}></img>
                                    </div>
                                    <div onClick={next_btn}>
                                        <img className="slide_img" src={process.env.PUBLIC_URL + "/img/next_btn.svg"}></img>
                                    </div>
                                </div>
                            </>
                            : null}
                        {params.id == 6 ?
                            <>
                                <div className="survey_form_wrap">
                                    <div className="survey_num label02">
                                        {params.id} / 6
                                    </div>
                                    <div className="survey_title sub_headline02">
                                        알레르기, 아토피, 접촉성 피부염과 같은<br />
                                        피부 질환을 겪어본 적이 있나요?
                                    </div>
                                    <div className="survey_radio_wrap">
                                        <div className="survey_radio label02">
                                            <input type="radio" name="skin_test_06"  checked={Qna.q6 == 0 ? true : null} value="0" id="test_01_01" onChange={(e) => {
                                                change_input(e)
                                            }} />
                                            <label htmlFor="test_01_01">
                                                <div className="text_box">
                                                    네, 겪어본 적 있어요
                                                </div>
                                                <div className="check_box"></div>
                                            </label>
                                        </div>
                                        <div className="survey_radio label02">
                                            <input type="radio" name="skin_test_06"  checked={Qna.q6 == 1 ? true : null} value="1" id="test_01_02" onChange={(e) => {
                                                change_input(e)
                                            }} />
                                            <label htmlFor="test_01_02">
                                                <div className="text_box">
                                                    아니로, 겪어본 적 없어요
                                                </div>
                                                <div className="check_box"></div>
                                            </label>
                                        </div>
                                    </div>
                                </div>

                                <div className="survey_btn_box" style={{marginBottom: "5rem" ,}}>
                                    <div onClick={prev_btn}>
                                        <img style={{transform:"rotate(180deg)"}} className="slide_img" src={process.env.PUBLIC_URL + "/img/next_btn.svg"}></img>
                                    </div>
                                    <div>
                                        <img style={{transform:"rotate(180deg)"}} className="slide_img" src={process.env.PUBLIC_URL + "/img/next_btn_deactive.svg"}></img>
                                    </div>
                                </div>
                                <div className='fixed_btn_wrap'>
                                    <div className="btn_fixed">
                                {btn_activate ?
                                    <div className="primary_fill_btn small01" onClick={() => {
                                        submit_btn()
                                    }}>
                                        분석 결과 확인
                                    </div>
                                    :
                                    <div className="gray_btn small01">
                                        답변되지 않은 문항이 있어요
                                    </div>
                                }
                                </div>
                                </div>
                            </>
                            : null}
                    </div>
                </div>
            </div>

            {fail_modal_btn == 1 ?
                <div className="fail_modal_wrap">
                    <div className="fail_modal">
                        <div className="large01">
                            AI 피부 분석을 그만하시겠어요?
                        </div>
                        <div className="small02">
                            진행 과정은 저장되지 않습니다.
                        </div>
                        <div className="fail_btn_wrap">
                            <div className="small01 p200_btn" onClick={() => {
                                setFail_modal_btn(0)
                            }}>
                                취소
                            </div>
                            <div className="small01 primary_fill_btn" onClick={() => {
                                setFail_modal_btn(0)
                                navigate("/")
                            }}>
                                그만두기
                            </div>
                        </div>
                    </div>
                </div>
                :
                null}
        </div>
    )
}

export default Skin_survey