import { useState } from "react";
import Sub_top_bar from "./sub_top";
import { useLocation, useNavigate } from "react-router-dom"
import About_luluti from "./about_luluti";


function Skin_type_all() {
    let navigate = useNavigate()
    const location = useLocation()
    let [modal_btn ,setModal_btn] = useState()
    console.log(location)
    

    var data = localStorage.getItem("user_skin_result")
    var img_url = process.env.PUBLIC_URL + "/img/skin_type_result/"

    if (data) {
        var gender = JSON.parse(data).customerInfo.sexdstn
        console.log("gender")
        console.log(gender)
        if (gender === "male") {
            
            img_url = process.env.PUBLIC_URL + "/img/skin_type_result_man/"
        }
    }
    return (
        <div className="sub_page gray">
            <div className="sub_top_bar small02 ju_sb">
                <div className="back_btn" onClick={() => {
                    navigate(location.state)
                }}>
                    <img style={{ width: "0.8rem" }} src={process.env.PUBLIC_URL + "/img/back_arrow.png"} />
                </div>

                <div className="sub_page_top_title">
                    LuLuTI 피부 타입
                </div>

                <svg xmlns="http://www.w3.org/2000/svg" width="2.4rem" height="2.4rem" viewBox="0 0 24 24" fill="none" onClick={() => {
                    setModal_btn(1)
                }}>
                    <circle cx="12" cy="12" r="8.5" stroke="#A5A5A5" />
                    <path d="M11.2464 13.8851C11.2584 12.2554 11.7488 11.7568 12.5742 11.2459C13.0646 10.9419 13.4474 10.4554 13.4474 9.77432C13.4474 8.9473 12.8134 8.42432 12.0239 8.42432C11.3421 8.42432 10.6603 8.83784 10.5885 9.83514H9.5C9.55981 8.36351 10.6603 7.5 12.0239 7.5C13.5191 7.5 14.5 8.48514 14.5 9.82297C14.5 10.7716 14.0574 11.4405 13.3158 11.9027C12.5742 12.3649 12.299 12.827 12.2751 13.8851V13.9459H11.2464V13.8851ZM11.7967 16.5C11.378 16.5 11.0191 16.1473 11.0311 15.7095C11.0191 15.2838 11.378 14.9311 11.7967 14.9311C12.2153 14.9311 12.5622 15.2838 12.5622 15.7095C12.5622 16.1473 12.2153 16.5 11.7967 16.5Z" fill="#A5A5A5" />
                </svg>
            </div>

            <div className="sub_body_wrap">
                <div className="sub_body">
                    <div className="con">
                        <div className="detail_wrap">
                            <div className="detail_box" onClick={() => {
                                navigate("/detail/dr/", { state: location.state })
                            }}>
                                <img src={img_url + "DR.png"}></img>
                                <div className="small01">DR</div>
                                <div className="label02">건성</div>
                            </div>
                            <div className="detail_box" onClick={() => {
                                navigate("/detail/ds/", { state: location.state })
                            }}>
                                <img src={img_url + "DS.png"}></img>
                                <div className="small01">DS</div>
                                <div className="label02">민감 건성</div>
                            </div>
                            <div className="detail_box" onClick={() => {
                                navigate("/detail/dra/", { state: location.state })
                            }}>
                                <img src={ img_url + "DRA.png"}></img>
                                <div className="small01">DR-A</div>
                                <div className="label02">알레르기성 건성</div>
                            </div>
                            <div className="detail_box" onClick={() => {
                                navigate("/detail/dsa/", { state: location.state })
                            }}>
                                <img src={ img_url + "DS.png"}></img>
                                <div className="small01">DS-A</div>
                                <div className="label02">알레르기성 민감 건성</div>
                            </div>
                            <div className="detail_box" onClick={() => {
                                navigate("/detail/nr/", { state: location.state })
                            }}>
                                <img src={ img_url + "NR.png"}></img>
                                <div className="small01">NR</div>
                                <div className="label02">중성</div>
                            </div>
                            <div className="detail_box" onClick={() => {
                                navigate("/detail/ns/", { state: location.state })
                            }}>
                                <img src={ img_url + "NS.png"}></img>
                                <div className="small01">NS</div>
                                <div className="label02">민감 중성</div>
                            </div>
                            <div className="detail_box" onClick={() => {
                                navigate("/detail/nra/", { state: location.state })
                            }}>
                                <img src={ img_url + "NRA.png"}></img>
                                <div className="small01">NR-A</div>
                                <div className="label02">알레르기성 중성</div>
                            </div>
                            <div className="detail_box" onClick={() => {
                                navigate("/detail/nsa/", { state: location.state })
                            }}>
                                <img src={ img_url + "NSA.png"}></img>
                                <div className="small01">NS-A</div>
                                <div className="label02">알레르기성 민감 중성</div>
                            </div>
                            <div className="detail_box" onClick={() => {
                                navigate("/detail/or/", { state: location.state })
                            }}>
                                <img src={ img_url + "OR.png"}></img>
                                <div className="small01">OR</div>
                                <div className="label02">지성</div>
                            </div>
                            <div className="detail_box" onClick={() => {
                                navigate("/detail/os/", { state: location.state })
                            }}>
                                <img src={ img_url + "OS.png"}></img>
                                <div className="small01">OS</div>
                                <div className="label02">민감 지성</div>
                            </div>
                            <div className="detail_box" onClick={() => {
                                navigate("/detail/ora/", { state: location.state })
                            }}>
                                <img src={ img_url + "ORA.png"}></img>
                                <div className="small01">OR-A</div>
                                <div className="label02">알레르기성 지성</div>
                            </div>
                            <div className="detail_box" onClick={() => {
                                navigate("/detail/osa/", { state: location.state })
                            }}>
                                <img src={ img_url + "OSA.png"}></img>
                                <div className="small01">OS-A</div>
                                <div className="label02">알레르기성 민감 지성</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {modal_btn == 1 ?
            <About_luluti setModal_btn={setModal_btn}></About_luluti>
            : null }
            
        </div>
    )
}

export default Skin_type_all