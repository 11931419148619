import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";

function SimpleSlider({setSlide_text}) {
    
let change_text  = ["피부타입","피부상태","피부랭킹"]

  var settings = {
    autoplay: true,
    dots: true,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    beforeChange: (current, next) => {document.querySelector(".survey_wrap .sub_headline01 span").classList.remove("on")
        setTimeout(()=>{
            document.querySelector(".survey_wrap .sub_headline01 span").classList.add("on")
            setSlide_text(change_text[next])
        },250)
    },
  };
  return (
    <Slider {...settings}>
        <div>
        <img className="slide_img" src={process.env.PUBLIC_URL+"/img/main_slide_02.png"}></img>
        </div>
        <div>
        <img className="slide_img" src={process.env.PUBLIC_URL+"/img/main_slide_01.png"}></img>
        </div>
        <div>
        <img className="slide_img" src={process.env.PUBLIC_URL+"/img/main_slide_03.png"}></img>
        </div>
    </Slider>
  );
}


function Main_page(){

    const location = useLocation()

    useEffect(()=>{

        localStorage.clear()

        setTimeout(() => {
            document.querySelector(".main_visual").classList += " active"
            document.querySelector(".main_visual .visual_img > .overlay").classList += " blur"
            document.querySelector(".scroll_nav").classList += " active"
        }, 500);
        
    },[])

    let [slide_text , setSlide_text] = useState("피부타입")
    let navigate = useNavigate()
    return (
        <>
            <div className="main_visual">
                <div className="visual_img">
                    <video muted autoPlay="1" playsInline loop src={process.env.PUBLIC_URL+"/img/mainVideo.mp4"}></video>
                    <div className="overlay"></div>
                    {/* <img src={process.env.PUBLIC_URL+"/img/main_visual.png"}></img> */}
                </div>
                <div className="visual_text">

                    <div className="headline02">
                        완벽한 피부로의 여정, <br/>
                        LuLuTI와 함께 시작하세요
                    </div>
                    
                    <div className="small02">
                        200만개의 피부과 의사 평가 데이터로 학습된 <br/>
                        피부 진단 AI로 내 본모습을 확인해보세요
                    </div>

                    <a onClick={()=> navigate("/info" , {state:"info"})} className="btn_gradient">
                        <img src={process.env.PUBLIC_URL + "/img/main_btn.png"}></img>
                    </a>

                    <div className="scroll_nav">
                    <img className="scroll_arrow" src={process.env.PUBLIC_URL+"/img/scroll_arrow.gif"}></img>
                    <div className="small02">
                        LuLuTI가 뭐에요?
                    </div>
                    <div className="label02">
                        스크롤
                    </div>
                </div>
                </div>
            </div>
            <div className="about_wrap">
                <div className="about_box con">
                    <div className="text_box">
                        <div className="sub_headline02">
                            LuLuTI가 뭐예요?
                        </div>
                        <div className="small02">
                        LuLuTI(룰루티아이)는 자신이 가지고 있는 고유의 피부 타입을 12가지로 분류한 룰루랩 고유의 피부 분류 정의에요.
                        </div>
                    </div>
                    <img className="about_lulu" src={process.env.PUBLIC_URL+"/img/about_lulu.png"}></img>
                    <a onClick={()=>{navigate("/skin_type_all" , {state :location.pathname})}} className="primary_btn small01" Link>
                        모든 피부타입 보러가기
                    </a>
                </div>
            </div>
            <div className="survey_wrap">
                <div className="survey_box con">
                    <div className="box_label label01">
                        AI피부 분석
                    </div>
                    <div className="sub_headline01">
                        한 번의 쵤영으로 <br/>
                        나의 <span className="on">{slide_text}</span>를 확인해 보세요
                    </div>
                    <SimpleSlider setSlide_text={setSlide_text}></SimpleSlider>
                    <a onClick={()=>{navigate("/info" ,{state:"info"})}} className="primary_fill_btn small01">
                        AI 피부 분석 시작하기
                    </a>
                    <div className="caption">
                    ‘AI 피부 분석’은 ‘룰루랩’ 만의 특허받은 인공지능 기술을 활용하여 피부 상태를 다각도로 분석하는 피부 측정 서비스입니다.
                    </div>
                </div>
            </div>
            <div className="share_wrap con">
                <div className="share_box">
                    <div className="text label02">
                        친구에게 공유하고 <br/>나와 비슷한 피부 타입을 찾아보세요
                    </div>
                    {/* <div className="share_icon_wrap">
                        <div className="icon_box" onClick={()=>{
                            window.navigator.share({
                                title: document.title,
                                text: "LuLuTI 피부 분석하기",
                                url: window.location.href
                                })
                        }} >
                            <img src={process.env.PUBLIC_URL+"/img/btm_logo_01.png"}></img>
                        </div>
                        <div className="icon_box">
                            <img src={process.env.PUBLIC_URL+"/img/btm_logo_02.png"}></img>
                        </div>
                        <div className="icon_box">
                            <img src={process.env.PUBLIC_URL+"/img/btm_logo_03.png"}></img>
                        </div>
                        <div className="icon_box">
                            <img src={process.env.PUBLIC_URL+"/img/btm_logo_04.png"}></img>
                        </div>
                    </div> */}
                            <div className="gray_line_btn btm_share share_btn small01"
                                onClick={() => {
                                    window.navigator.share({
                                        title: document.title,
                                        text: "LuLuTI 피부 분석하기",
                                        url: window.location.href
                                    })
                                }}>친구에게 공유하고 비슷한 타입 찾기
                                <svg xmlns="http://www.w3.org/2000/svg" width="17" height="19" viewBox="0 0 17 19" fill="none">
                                    <path d="M13.5 6L8.5 1L3.5 6" stroke="#666666" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                    <line x1="8.5" y1="1.75" x2="8.5" y2="13.75" stroke="#666666" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                    <path d="M13.5444 10H14.5C15.0523 10 15.5 10.4477 15.5 11V17C15.5 17.5523 15.0523 18 14.5 18H2.5C1.94772 18 1.5 17.5523 1.5 17V11C1.5 10.4477 1.94772 10 2.5 10H3.45556" stroke="#666666" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                </svg>
                            </div>
                </div>
            </div>
        </>
    )
}

  
  export default Main_page