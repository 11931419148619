function About_luluti({setModal_btn}) {
    return (
        <div className="about_wrap modal_page" style={{paddingTop : 0}}>
            <div className="fixed_clobtn"onClick={()=>{
                console.log("???")
                setModal_btn(0)
            }}>
                <img style={{width:"1.4rem"}} src={process.env.PUBLIC_URL+"/img/clo_btn.svg"}/>
            </div>
            <div className="about_box con">
                <div className="text_box">
                    <div className="sub_headline02">
                        LuLuTI가 뭐예요?
                    </div>
                    <div className="small02">
                        LuLuTI(룰루티아이)는 자신이 가지고 있는 고유의 피부 타입을 12가지로 분류한 룰루랩 고유의 피부 분류 정의에요.
                    </div>
                </div>
                <img className="about_lulu" src={process.env.PUBLIC_URL + "/img/about_lulu.png"}></img>
            </div>
        </div>
    )
}

export default About_luluti