// ImageQualityContext.js
import React, { createContext, useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { ImageQualityCheckRealTime } from "./camara/ImageQualityCheckRealTimeImpl";

// Context 생성
const ImageQualityContext = createContext(null);

// Context Provider 생성
export const ImageQualityProvider = ({ children }) => {
  const [apiInstance, setApiInstance] = useState(null);
  const location = useLocation();

  useEffect(() => {
    if (location.pathname === "/info") { // 특정 URL을 지정합니다.
      console.log("카메라 API 로드");
      const api = new ImageQualityCheckRealTime(true);
      setApiInstance(api);
    }
  }, [location.pathname]);

  return (
    <ImageQualityContext.Provider value={apiInstance}>
      {children}
    </ImageQualityContext.Provider>
  );
};

// Custom hook for easy access to the context
export const useImageQuality = () => useContext(ImageQualityContext);
