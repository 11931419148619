import { useEffect, useState } from "react";
import Sub_top_bar from "./sub_top";
import { useNavigate, Link, useLocation } from "react-router-dom";
import WebcamPage from "./camara/webcampage";


function Api_init({ PageState, setPageState, set_loading_done, camara_on }) {
  const [progress, setProgress] = useState(0);

  function updateDonutChart(percent, elem) {
    const circle = document.querySelector(elem);
    const text = document.querySelector('.donut-text');
    const offset = 100 - percent;
    let innertext = percent
    if (innertext < 10) {
      innertext = "0" + innertext
    }
    circle.style.strokeDashoffset = offset;
    text.innerHTML = `${innertext}<span>%</span>`;
  }

  const onProgressComplete = () => {
    set_loading_done(true)
    setPageState(2)
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      setProgress((prevProgress) => {
        if (prevProgress >= 99 && PageState == 1) {
          clearInterval(intervalId);
          onProgressComplete();
          updateDonutChart(100, '.donut-segment');
        }
        if (prevProgress >= 99) {
          clearInterval(intervalId);
          return 99;
        }
        const nextProgress = prevProgress + 1;
        updateDonutChart(nextProgress, '.donut-segment');
        return nextProgress;
      });
    }, 100);

    return () => clearInterval(intervalId);
  }, [PageState]);


  return (
    <>
      <div className="modal_wrap">
        <div className="donut-wrap">
          <div className="donut-container">
            <svg className="donut" viewBox="0 0 36 36">
              <circle className="donut-ring" cx="18" cy="18" r="15.915" fill="transparent" stroke="#eee" stroke-width="4"></circle>
              <circle className="donut-segment" cx="18" cy="18" r="15.915" fill="transparent" stroke="#6B5FFF" stroke-width="4" stroke-dasharray="100" stroke-dashoffset="100"></circle>
            </svg>
            <div className="donut-text">{progress}<span>%</span></div>
          </div>
          <div className="prograss_text small01">
            <span>AI 준비중...</span>
          </div>
          <div className="caption">
            룰루랩 A.I 에서는 피부과 전문의들의 진단을 기반으로 <br />
            고객님 피부에 맞는 최상의 솔루션을 제공해드리고 있어요.
          </div>
        </div>
      </div>
    </>
  );
}

function Camara_api({ api_init }) {

  let [PageState, setPageState] = useState(0)
  let [loading_done, set_loading_done] = useState(false)
  let [camara_on, setCamara_on] = useState(false);
  const { state } = useLocation()
  const navigate = useNavigate()

  useEffect(() => {
    if (state !== "camara_api") {
      navigate("/")
    }
  }, [])

  return (
    <>
      {PageState == 2 && camara_on ? null :
        <Api_init PageState={PageState} setPageState={setPageState} set_loading_done={set_loading_done}></Api_init>
      }

      <Load_fin setPageState={setPageState} loading_done={loading_done} camara_on={camara_on} setCamara_on={setCamara_on} api_init={api_init}></Load_fin>
    </>
  )
}

function Load_fin({ setPageState, loading_done, camara_on, setCamara_on, api_init }) {

  let navigate = useNavigate()
  let [fail_modal_btn, setFail_modal_btn] = useState(0)

  useEffect(() => {
    setPageState(1)
  }, [])

  return (
    <>
      <div className="sub_page" style={{ height: "100vh", overflow: "hidden" }}>
        <Sub_top_bar title="피부촬영"></Sub_top_bar>
        <div className="sub_body_wrap" style={{
          position: "relative",
          paddingBottom: 0,
        }}>
          <div className="sub_body" style={{ paddingTop: 0, }}>
            {/* <h1>API 전달 필요</h1>
                        <div className="primary_btn small01" onClick={()=>{setFail_modal_btn(1)}}>
                            실패하기 버튼 test
                        </div>
                        <div className="gray_btn small01" onClick={()=>{setPageState(0)}}>
                            로딩 다시보기
                        </div>
                        <div className="primary_fill_btn small01" onClick={()=> navigate("/skin_survey/1")}>
                            다음 페이지 이동 버튼
                        </div> */}
            <WebcamPage loading_done={loading_done} setFail_modal_btn={setFail_modal_btn} setCamara_on={setCamara_on} camara_on={camara_on}></WebcamPage>
          </div>
        </div>
        {fail_modal_btn == 1 ?
          <div className="fail_modal_wrap">
            <div className="fail_modal">
              <div className="large01">
                분석실패
              </div>
              <div className="small02">
                분석에 실패했습니다. 얼굴이 가이드에 잘맞 게 촬영되었는지 다시 한번 확인해 주세요.
              </div>
              <div className="fail_btn_wrap">
                <div className="cancel_btn small01 p200_btn" onClick={() => {
                  setFail_modal_btn(0)
                  navigate("/camara_guide", { state: "camara_guide" })
                }}>
                  취소
                </div>
                <div className="re_camara_btn small01 primary_fill_btn">
                  재 촬영하기
                </div>
              </div>
            </div>
          </div>
          :
          null}
      </div>
    </>
  )
}

export default Camara_api