import html2canvas from 'html2canvas';
import { saveAs } from 'file-saver';
import Slider from "react-slick";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useEffect, useRef, useState } from "react";

function SimilarSlider() {
    var settings = {
        autoplay: true,
        dots: true,
        arrows: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        beforeChange: (current, next) => { },
    };
    
    var data = localStorage.getItem("user_skin_result")
    var img_url = process.env.PUBLIC_URL + "/img/skin_type_result/"

    if (data) {
        var gender = JSON.parse(data).customerInfo.sexdstn
        console.log("gender")
        console.log(gender)
        if (gender === "male") {
            
            img_url = process.env.PUBLIC_URL + "/img/skin_type_result_man/"
        }
    }
    return (
        <>
            <Slider {...settings}>
                <div className="similarSlide">
                    <img className="slide_img" src={ img_url + "NR.png"}></img>
                    <div className="text_box">
                        <div className="sub_headline02">NR</div>
                        <div className="large03">중성</div>
                    </div>
                </div>
                <div className="similarSlide">
                    <img className="slide_img" src={ img_url + "NS.png"}></img>
                    <div className="text_box">
                        <div className="sub_headline02">NS</div>
                        <div className="large03">민감 중성</div>
                    </div>
                </div>
                <div className="similarSlide">
                    <img className="slide_img" src={ img_url + "NSA.png"}></img>
                    <div className="text_box">
                        <div className="sub_headline02">NS-A</div>
                        <div className="large03">알레르기성 민감 중성</div>
                    </div>
                </div>
            </Slider>
        </>
    );
}
function InfluencerPickSlider() {
    var settings = {
        autoplay: true,
        dots: true,
        arrows: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
    };
    return (
        <>
            <Slider {...settings}>
                <div className="InfluencerPickSlide">
                    <div className="influencer_info flex al_ce ju_sb">
                        <div className="left flex">
                            <div className="profile_img">
                                <img src={process.env.PUBLIC_URL + "/img/influencer_img.png"}></img>
                            </div>
                            <div className="profile_info">
                                <div className="skin_type label02">
                                    DR-A
                                </div>
                                <div className="influencer_name small01">
                                    김룰루
                                </div>
                            </div>
                        </div>
                        <div className="right flex">
                            <div className="youtube_link">
                                <img src={process.env.PUBLIC_URL + "/img/youtube.png"}></img>
                            </div>
                            <div className="insta_link">
                                <img src={process.env.PUBLIC_URL + "/img/insta.png"}></img>
                            </div>
                        </div>
                    </div>
                    <div className="product_info flex">
                        <div>
                            <div className="product_img">
                                <img src={process.env.PUBLIC_URL + "/img/product_sample_01.png"}></img>
                            </div>
                            <div className="product_brand caption">
                                토리든
                            </div>
                            <div className="product_name label02">
                                다이브인 저분자 히알루론산 세럼 70m
                            </div>
                            <div className="product_price label02">
                                24,000원
                            </div>
                        </div>
                        <div>
                            <div className="product_img">
                                <img src={process.env.PUBLIC_URL + "/img/product_sample_02.png"}></img>
                            </div>
                            <div className="product_brand caption">
                                토리든
                            </div>
                            <div className="product_name label02">
                                다이브인 저분자 히알루론산 세럼 70m
                            </div>
                            <div className="product_price label02">
                                24,000원
                            </div>
                        </div>
                    </div>
                </div>
                <div className="InfluencerPickSlide">
                    <div className="influencer_info flex al_ce ju_sb">
                        <div className="left flex">
                            <div className="profile_img">
                                <img src={process.env.PUBLIC_URL + "/img/influencer_img.png"}></img>
                            </div>
                            <div className="profile_info">
                                <div className="skin_type label02">
                                    DR-A
                                </div>
                                <div className="influencer_name small01">
                                    김룰루
                                </div>
                            </div>
                        </div>
                        <div className="right flex">
                            <div className="youtube_link">
                                <img src={process.env.PUBLIC_URL + "/img/youtube.png"}></img>
                            </div>
                            <div className="insta_link">
                                <img src={process.env.PUBLIC_URL + "/img/insta.png"}></img>
                            </div>
                        </div>
                    </div>
                    <div className="product_info flex">
                        <div>
                            <div className="product_img">
                                <img src={process.env.PUBLIC_URL + "/img/product_sample_01.png"}></img>
                            </div>
                            <div className="product_brand caption">
                                토리든
                            </div>
                            <div className="product_name label02">
                                다이브인 저분자 히알루론산 세럼 70m
                            </div>
                            <div className="product_price label02">
                                24,000원
                            </div>
                        </div>
                        <div>
                            <div className="product_img">
                                <img src={process.env.PUBLIC_URL + "/img/product_sample_02.png"}></img>
                            </div>
                            <div className="product_brand caption">
                                토리든
                            </div>
                            <div className="product_name label02">
                                다이브인 저분자 히알루론산 세럼 70m
                            </div>
                            <div className="product_price label02">
                                24,000원
                            </div>
                        </div>
                    </div>
                </div>
                <div className="InfluencerPickSlide">
                    <div className="influencer_info flex al_ce ju_sb">
                        <div className="left flex">
                            <div className="profile_img">
                                <img src={process.env.PUBLIC_URL + "/img/influencer_img.png"}></img>
                            </div>
                            <div className="profile_info">
                                <div className="skin_type label02">
                                    DR-A
                                </div>
                                <div className="influencer_name small01">
                                    김룰루
                                </div>
                            </div>
                        </div>
                        <div className="right flex">
                            <div className="youtube_link">
                                <img src={process.env.PUBLIC_URL + "/img/youtube.png"}></img>
                            </div>
                            <div className="insta_link">
                                <img src={process.env.PUBLIC_URL + "/img/insta.png"}></img>
                            </div>
                        </div>
                    </div>
                    <div className="product_info flex">
                        <div>
                            <div className="product_img">
                                <img src={process.env.PUBLIC_URL + "/img/product_sample_01.png"}></img>
                            </div>
                            <div className="product_brand caption">
                                토리든
                            </div>
                            <div className="product_name label02">
                                다이브인 저분자 히알루론산 세럼 70m
                            </div>
                            <div className="product_price label02">
                                24,000원
                            </div>
                        </div>
                        <div>
                            <div className="product_img">
                                <img src={process.env.PUBLIC_URL + "/img/product_sample_02.png"}></img>
                            </div>
                            <div className="product_brand caption">
                                피지오겔
                            </div>
                            <div className="product_name label02">
                                DMT 페이셜 크림 100ml
                            </div>
                            <div className="product_price label02">
                                29,900원
                            </div>
                        </div>
                    </div>
                </div>
            </Slider>
        </>
    );
}


function Skin_detail_nra() {
    let navigate = useNavigate()
    const location = useLocation()
    
    var data = localStorage.getItem("user_skin_result")
    var img_url = process.env.PUBLIC_URL + "/img/skin_type_result/"

    if (data) {
        var gender = JSON.parse(data).customerInfo.sexdstn
        console.log("gender")
        console.log(gender)
        if (gender === "male") {
            img_url = process.env.PUBLIC_URL + "/img/skin_type_result_man/"
        }
    }
    return (
        <div className="sub_page">
            <div className='fixed_btn_wrap'>
                <div className="btn_fixed">
                    <div className="p200_btn small01" onClick={() => {
                        navigate("/skin_type_all" ,{state : location.state} )
                    }}>
                        닫기
                    </div>
                </div>
            </div>
            <div className="sub_body_wrap" style={{ height: "100vh" }}>
                <div className="sub_body" style={{ paddingTop: "0" }}>
                    <div className="result_capture_area con" style={{ paddingTop: "2rem" }}>
                        <div className="type_title">
                            <div className="headline01">
                                NR-A
                            </div>
                            <div className="sub_headline02">
                                알레르기성 중성
                            </div>
                            <div className="small02">
                                <span>Neutral</span>
                                <span>|</span>
                                <span>Resistant</span>
                                <span>|</span>
                                <span>Allergic</span>
                            </div>
                        </div>
                        <div className="type_img">
                            <img src={ img_url + "NRA.png"}></img>
                        </div>
                        <div className="type_info large03">
                            특정 제품 성분과 외부 환경에 반응하는
                            <br />
                            <span>알레르기성 중성</span> 피부에요.
                        </div>
                        <div className="gray_line"></div>
                        <div className="balance">
                            <div className="small02 flex ju_ce">
                                유수분 밸런스
                            </div>
                            <div className="balance_bar">
                                <div className="balance_circle" style={{ left: 50 + "%" }}></div>
                            </div>
                            <div className="text_box flex al_ce ju_sb">
                                <div className="dry">
                                    <div className="small02">
                                        건성
                                    </div>
                                    <div className="lable02">
                                        Dry
                                    </div>
                                </div>
                                <div className="neutral">
                                    <div className="small02">
                                        중성
                                    </div>
                                    <div className="lable02">
                                        Neutral
                                    </div>
                                </div>
                                <div className="oily">
                                    <div className="small02">
                                        지성
                                    </div>
                                    <div className="lable02">
                                        Oily
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="sensitive result_type_wrap">
                            <div className="title small02 flex al_ce ju_ce">
                                피부 민감도
                            </div>
                            <div className="result_type_box">
                                <div className="result_type ">
                                    <div className="ko flex al_ce ju_ce">
                                        민감성
                                    </div>
                                    <div className="en flex al_ce ju_ce">
                                        Sensitive
                                    </div>
                                </div>
                                <div className="result_type on">
                                    <div className="ko flex al_ce ju_ce">
                                        비민감성
                                    </div>
                                    <div className="en flex al_ce ju_ce">
                                        Insensitive
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="allergy result_type_wrap">
                            <div className="title small02 flex al_ce ju_ce">
                                알레르기 여부
                            </div>
                            <div className="result_type_box">
                                <div className="result_type on">
                                    <div className="ko flex al_ce ju_ce">
                                        알레르기성
                                    </div>
                                    <div className="en flex al_ce ju_ce">
                                        Allergic
                                    </div>
                                </div>
                                <div className="result_type ">
                                    <div className="ko flex al_ce ju_ce">
                                        비알레르기성
                                    </div>
                                    <div className="en flex al_ce ju_ce">
                                        Non-allergic
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="con">
                        <div className="gray_line"></div>
                        <div className="similar_type">
                            <div className="title sub_headline01">
                                <span>NR-A</span>와 <br />비슷한 피부 타입
                            </div>
                            <SimilarSlider></SimilarSlider>
                            <div className="gray_line_btn share_btn small01"
                                onClick={() => {
                                    window.navigator.share({
                                        title: document.title,
                                        text: "LuLuTI 피부 분석하기",
                                        url: window.location.href
                                    })
                                }}>친구에게 공유하고 비슷한 타입 찾기
                                <svg xmlns="http://www.w3.org/2000/svg" width="17" height="19" viewBox="0 0 17 19" fill="none">
                                    <path d="M13.5 6L8.5 1L3.5 6" stroke="#666666" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                    <line x1="8.5" y1="1.75" x2="8.5" y2="13.75" stroke="#666666" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                    <path d="M13.5444 10H14.5C15.0523 10 15.5 10.4477 15.5 11V17C15.5 17.5523 15.0523 18 14.5 18H2.5C1.94772 18 1.5 17.5523 1.5 17V11C1.5 10.4477 1.94772 10 2.5 10H3.45556" stroke="#666666" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                </svg>
                            </div>
                        </div>
                        <div className="gray_line thick"></div>

                        <div className="influencer_pick">
                            <div className="title small02">
                                같은 타입 인플루언서의 추천 아이템
                            </div>
                            <InfluencerPickSlider></InfluencerPickSlider>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    )
}

export default Skin_detail_nra