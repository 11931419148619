
import axios from "axios";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Slider from "react-slick";

function Concern_slider({set_img_type , result}) {
    
    const 주름 = Math.round(result.wrinkle)
    const 모공 = Math.round(result.pore)
    const 트러블 = Math.round(result.acne)
    const 색소침착 = Math.round(result.melanin)
    const 다크서클 = Math.round(result.darkcircle)
    const 붉은기 = Math.round(result.hemoglobin)
    const 탄력 = Math.round(result.elasticity)
    const 광채 = Math.round(result.radiance)
    const 피부결 = Math.round(result.texture)
    const 피지 = Math.round(result.sebum)
    

    var settings = {
        autoplay: false,
        dots: false,
        arrows: false,
        infinite: false,
        speed: 500,
        slidesToShow: 4.7,
        slidesToScroll: 1,
        swipeToSlide: true,
        beforeChange: (current, next) => { },
    };

    function Item_click(e , data_about) {
        document.querySelector(".skin_concern_box.active").classList.remove("active")
        e.target.closest(".skin_concern_box").classList.add("active")
        set_img_type(data_about)
    }

    function Sorting_content(){
        
    }

    const scores = [
        ['주름',"wrinkle", 주름 , "00"],
        ['모공',"pore", 모공,"01"],
        ['트러블',"acne", 트러블,"02"],
        ['색소침착',"melanin", 색소침착,"03"],
        ['다크서클',"darkcircle", 다크서클,"04"],
        ['붉은기',"hemoglobin", 붉은기,"05"],
        ['탄력',"elasticity", 탄력,"06"],
        ['광채',"radiance", 광채,"07"],
        ['피부결',"texture", 피부결,"08"]
    ];

    scores.sort((a, b) => a[2] - b[2]);

    useEffect(()=>{
        console.log(scores);
    },[])

    return (
        <>
            <Slider {...settings}>

                <div className="skin_concern_box active" onClick={(e)=>{Item_click(e, scores[0][1])}}>
                    <div className="skin_concern">
                        <img className="slide_img" src={process.env.PUBLIC_URL + `/img/skin_icon/skin_concern${scores[0][3]}.png`}></img>
                        <div className="text_box">
                            <div className="label02">{scores[0][0]}</div>
                            <div className="label02">{scores[0][2]}/10</div>
                        </div>
                    </div>
                </div>
                <div className="skin_concern_box" onClick={(e)=>{Item_click(e, scores[1][1])}}>
                    <div className="skin_concern">
                        <img className="slide_img" src={process.env.PUBLIC_URL + `/img/skin_icon/skin_concern${scores[1][3]}.png`}></img>
                        <div className="text_box">
                            <div className="label02">{scores[1][0]}</div>
                            <div className="label02">{scores[1][2]}/10</div>
                        </div>
                    </div>
                </div>
                <div className="skin_concern_box" onClick={(e)=>{Item_click(e, scores[2][1])}}>
                    <div className="skin_concern">
                        <img className="slide_img" src={process.env.PUBLIC_URL + `/img/skin_icon/skin_concern${scores[2][3]}.png`}></img>
                        <div className="text_box">
                            <div className="label02">{scores[2][0]}</div>
                            <div className="label02">{scores[2][2]}/10</div>
                        </div>
                    </div>
                </div>
                <div className="skin_concern_box" onClick={(e)=>{Item_click(e, scores[3][1])}}>
                    <div className="skin_concern">
                        <img className="slide_img" src={process.env.PUBLIC_URL + `/img/skin_icon/skin_concern${scores[3][3]}.png`}></img>
                        <div className="text_box">
                            <div className="label02">{scores[3][0]}</div>
                            <div className="label02">{scores[3][2]}/10</div>
                        </div>
                    </div>
                </div>
                <div className="skin_concern_box" onClick={(e)=>{Item_click(e, scores[4][1])}}>
                    <div className="skin_concern">
                        <img className="slide_img" src={process.env.PUBLIC_URL + `/img/skin_icon/skin_concern${scores[4][3]}.png`}></img>
                        <div className="text_box">
                            <div className="label02">{scores[4][0]}</div>
                            <div className="label02">{scores[4][2]}/10</div>
                        </div>
                    </div>
                </div>
                <div className="skin_concern_box" onClick={(e)=>{Item_click(e, scores[5][1])}}>
                    <div className="skin_concern">
                        <img className="slide_img" src={process.env.PUBLIC_URL + `/img/skin_icon/skin_concern${scores[5][3]}.png`}></img>
                        <div className="text_box">
                            <div className="label02">{scores[5][0]}</div>
                            <div className="label02">{scores[5][2]}/10</div>
                        </div>
                    </div>
                </div>
                <div className="skin_concern_box" onClick={(e)=>{Item_click(e, scores[6][1])}}>
                    <div className="skin_concern">
                        <img className="slide_img" src={process.env.PUBLIC_URL + `/img/skin_icon/skin_concern${scores[6][3]}.png`}></img>
                        <div className="text_box">
                            <div className="label02">{scores[6][0]}</div>
                            <div className="label02">{scores[6][2]}/10</div>
                        </div>
                    </div>
                </div>
                <div className="skin_concern_box" onClick={(e)=>{Item_click(e, scores[7][1])}}>
                    <div className="skin_concern">
                        <img className="slide_img" src={process.env.PUBLIC_URL + `/img/skin_icon/skin_concern${scores[7][3]}.png`}></img>
                        <div className="text_box">
                            <div className="label02">{scores[7][0]}</div>
                            <div className="label02">{scores[7][2]}/10</div>
                        </div>
                    </div>
                </div>
            </Slider>
        </>
    );
}

function Skin_concern_slider({result}){
    let navigate = useNavigate()
    const params = useParams() 
    let imglist , 주름 , 모공, 트러블, 색소침착, 다크서클 , 붉은기, 피지, 탄력, 광채, 피부결
    let [data_receive , setData_receive] = useState(true)
    
    function request_data(jobId) {
        axios.get("https://websdk.lululab-platform.com/api/skin-analysis/results/" + jobId , {
            headers: {
                'x-secret': '2a69f75f-0637-4c0c-852e-5e2502edcdfe',
                'x-license': '067d32d1be894d79a4bb',
                'Content-Type': 'application/json',
            }
        })
        .then((response) => {
            if(response.data.error == "202 ACCEPTED" ){
                alert("잘못된 접근입니다. 확인을 눌러 메인페이지로 돌아갑니다.")
                navigate("/")
            } else{
                let receive_data = JSON.stringify(response.data.data)
                localStorage.setItem("user_skin_result" , receive_data)
                setData_receive(true)
            }
        })
        .catch((err) => {
            // 잘못된 jobId
            if(err.code == "ERR_BAD_REQUEST"){
                console.log("결과데이터 조회 실패")
                navigate("/")
            }
        });
    }


    // request_data(params.jobId)

    if(localStorage.getItem("user_skin_result") == undefined){
        console.log(params.jobId)
    } else{
    }

    result = JSON.parse(localStorage.getItem("user_skin_result"))
    주름 = Math.round(result.wrinkle);
    모공 = Math.round(result.pore);
    트러블 = Math.round(result.acne);
    색소침착 = Math.round(result.melanin);
    다크서클 = Math.round(result.darkcircle);
    붉은기 = Math.round(result.hemoglobin);
    탄력 = Math.round(result.elasticity);
    광채 = Math.round(result.radiance);
    피부결 = Math.round(result.texture);
    imglist = result.imageUrlSet.overlay

    let skinMetrics = [
        { name: "주름", value: 주름 },
        { name: "모공", value: 모공 },
        { name: "트러블", value: 트러블 },
        { name: "색소침착", value: 색소침착 },
        { name: "다크서클", value: 다크서클 },
        { name: "붉은기", value: 붉은기 },
        { name: "피지", value: 피지 },
        { name: "탄력", value: 탄력 },
        { name: "광채", value: 광채 },
        { name: "피부결", value: 피부결 }
    ];

    skinMetrics.sort((a, b) => a.value - b.value);

    let lowestThree = skinMetrics.slice(0, 3);

    let lowestThreeNames = lowestThree.map(metric => metric.name);

    let resultString = lowestThreeNames.join(', ');

    const scores = [
        ['주름',"wrinkle", 주름 , "00"],
        ['모공',"pore", 모공,"01"],
        ['트러블',"acne", 트러블,"02"],
        ['색소침착',"melanin", 색소침착,"03"],
        ['다크서클',"darkcircle", 다크서클,"04"],
        ['붉은기',"hemoglobin", 붉은기,"05"],
        ['탄력',"elasticity", 탄력,"06"],
        ['광채',"radiance", 광채,"07"],
        ['피부결',"texture", 피부결,"08"]
    ];

    scores.sort((a, b) => a[2] - b[2]);

    useEffect(()=>{
        set_img_type(scores[0][1])
    },[])

    let [img_type , set_img_type] = useState("wrinkle")
    let type_list ={
        "wrinkle": '주름',
        "pore": '모공',
        'acne':'트러블' ,
        'melanin' :'색소침착' ,
        'darkcircle' : '다크서클',
        'hemoglobin' : '붉은기',
        'sebum': '피지',
        'elasticity' :'탄력 ',
        'radiance':'광채',
        'texture' : '피부결'
    }
    return (
        <>
            <div className="skin_problem_wrap headline02">
                <div className="title">
                    주요 피부 고민은 <br />
                    <span>{resultString}</span>이에요
                </div>
                <div className="slider_wrap">
                    <div className="slider_absolute">
                        <Concern_slider set_img_type={set_img_type} result={result}/>
                    </div>
                </div>
                <div className="label02">
                    아이콘을 눌러 자세한 피부 상태를 확인해 보세요
                </div>
                <div className="skin_analyzed_img">
                    <div className="skin_label label02">
                        {type_list[img_type]} | Lv.{Math.round(result[img_type])}
                    </div>
                    <img src={imglist[img_type]}></img>
                </div>
            </div>
        </>
    )
}

export default Skin_concern_slider