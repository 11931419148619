import React, { useState, useEffect, useRef } from 'react';
import Webcam from 'react-webcam';

const Test = () => {
    const webcam = useRef(null);
    const [deviceResolution, setDeviceResolution] = useState({ width: 1920, height: 1080 });
  const [devices, setDevices] = useState([]);

  useEffect(() => {
    const getDevices = async () => {
      try {
        const devices = await navigator.mediaDevices.enumerateDevices();
        const videoDevices = devices.filter(device => device.kind === 'videoinput');
        setDevices(videoDevices);
      } catch (err) {
        console.error(err);
      }
    };
    getDevices();
  }, []);

  const isMobileOrIOS = () => {
    return /Mobi|Android|iPhone|iPad|iPod/i.test(navigator.userAgent);
  };

  const getResolutions = async () => {
    let idealRes = { width: 1920, height: 1080 };
    let maxRes = { width: 2560, height: 1440 };

    if (isMobileOrIOS()) {
      idealRes = { width: 1080, height: 1920 };
      maxRes = { width: 1440, height: 2560 };
    }

    for (const device of devices) {
      const constraints = {
        video: {
          deviceId: device.deviceId,
          width: { min: 640, ideal: idealRes.width, max: maxRes.width },
          height: { min: 480, ideal: idealRes.height, max: maxRes.height }
        },
        audio: false
      };

      try {
        const stream = await navigator.mediaDevices.getUserMedia(constraints);
        const track = stream.getVideoTracks()[0];
        const settings = track.getSettings();

        if (settings.width > idealRes.width && settings.height > idealRes.height) {
          idealRes = { width: settings.width, height: settings.height };
        }

        track.stop(); // 스트림을 멈춰서 자원을 해제합니다.
      } catch (err) {
        console.error(err);
      }
    }

    setDeviceResolution(idealRes);
  };

  useEffect(() => {
    if (devices.length > 0) {
      getResolutions();
    }
  }, [devices]);

  return (
    <div>
      <h1>Webcam with Ideal Resolution</h1>
      <Webcam
        ref={webcam}
        videoConstraints={{
          width: deviceResolution.width,
          height: deviceResolution.height,
          facingMode: "user"
        }}
      />
      <p>
        Device Resolution: {deviceResolution.width}x{deviceResolution.height}
      </p>
    </div>
  );
};

export default Test;
