import { useNavigate } from "react-router-dom"

function Email_success() {
    let navigate = useNavigate()
    return (
        <div className="sub_page flex al_ce ju_ce">
            <div className="con ">

                <div className="mid_content success_content">
                    <div className="sub_headline02">
                        LuLuTI 리포트 <br/>
                        메일 전달을 완료했어요
                    </div>
                    <img style={{width:"20rem"}} src={process.env.PUBLIC_URL + "/img/success_icon.png"}></img>
                </div>

                <div className="btn_fixed">
                    <div className="p200_btn small01" onClick={()=>{
                        navigate(-2)
                    }}>
                        닫기
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Email_success