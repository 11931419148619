import { useEffect, useState } from "react";
import Slider from "react-slick";


function SimilarSlider() {
    var settings = {
        autoplay: false,
        dots: true,
        arrows: false,
        infinite: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        beforeChange: (current, next) => { },
    };
    return (
        <>
            <Slider {...settings}>
                <div className="product_slide">
                    <div className="product_img">
                        <img src={process.env.PUBLIC_URL + "/img/sell_sample.png"}></img>
                    </div>
                    <div>
                        <div className="product_brand label02">
                            피지오겔
                        </div>
                        <div className="product_name small02">
                            DMT 페이셜 크림 100ml
                        </div>
                        <div className="product_price large02">
                            29,900
                        </div>
                    </div>
                </div>
                <div className="product_slide">
                    <div className="product_img">
                        <img src={process.env.PUBLIC_URL + "/img/sell_sample.png"}></img>
                    </div>
                    <div>
                        <div className="product_brand label02">
                            피지오겔
                        </div>
                        <div className="product_name small02">
                            DMT 페이셜 크림 100ml
                        </div>
                        <div className="product_price large02">
                            29,900
                        </div>
                    </div>
                </div>
                <div className="product_slide">
                    <div className="product_img">
                        <img src={process.env.PUBLIC_URL + "/img/sell_sample.png"}></img>
                    </div>
                    <div>
                        <div className="product_brand label02">
                            피지오겔
                        </div>
                        <div className="product_name small02">
                            DMT 페이셜 크림 100ml
                        </div>
                        <div className="product_price large02">
                            29,900
                        </div>
                    </div>
                </div>
            </Slider>
        </>
    );
}

function Recom_tap_section({result}) {
    let [tap_num, setTap_num] = useState(0)
    let [product_name, setProduct_name] = useState("클렌징")
    let [product_list, setProduct_list] = useState("test")

    const 주름 = Math.round(result.wrinkle);
    const 모공 = Math.round(result.pore);
    const 트러블 = Math.round(result.acne);
    const 색소침착 = Math.round(result.melanin);
    const 다크서클 = Math.round(result.darkcircle);
    const 붉은기 = Math.round(result.hemoglobin);
    const 피지 = Math.round(result.sebum);
    const 탄력 = Math.round(result.elasticity);
    const 광채 = Math.round(result.radiance);
    const 피부결 = Math.round(result.texture);

    let skinMetrics = [
        { name: "주름", value: 주름 },
        { name: "모공", value: 모공 },
        { name: "트러블", value: 트러블 },
        { name: "색소침착", value: 색소침착 },
        { name: "다크서클", value: 다크서클 },
        { name: "붉은기", value: 붉은기 },
        { name: "피지", value: 피지 },
        { name: "탄력", value: 탄력 },
        { name: "광채", value: 광채 },
        { name: "피부결", value: 피부결 }
    ];

    skinMetrics.sort((a, b) => a.value - b.value);

    let lowestThree = skinMetrics.slice(0, 3);

    let lowestThreeNames = lowestThree.map(metric => metric.name);

    useEffect(()=>{
        console.log(lowestThreeNames)
    })

    function active_change(e) {
        document.querySelector(".tap_menu.active").classList.remove("active")
        e.target.classList.add("active")
    }
    function active_change_product(e) {
        document.querySelector(".product_type_wrap .product_type.active").classList.remove("active")
        e.target.classList.add("active")
        let name = e.target.textContent
        setProduct_name(name)
    }

    useEffect(() => {
        setTimeout(() => {
            document.querySelector(".recom_product_box").classList.add("active")
        }, 100);
    }, [tap_num])

    useEffect(() => {
        document.querySelector(".recom_product_box .title > span").classList.remove("active")
        document.querySelector(".recom_product_box .product_box").classList.remove("active")
        setTimeout(() => {
            document.querySelector(".recom_product_box .title > span").classList.add("active")
            document.querySelector(".recom_product_box .product_box").classList.add("active")
        }, 100);
    }, [product_name])

    const skin_type_text = () => {
        const match = window.location.href.match(/result\/([^/]+)/);
        let text = match[1]
        if (match[1] == "dr") {
            text = "DR"
        } else if (match[1] == "dra") {
            text = "DR-A"
        } else if (match[1] == "ds") {
            text = "DS"
        } else if (match[1] == "dsa") {
            text = "DS-A"
        } else if (match[1] == "nr") {
            text = "NR"
        } else if (match[1] == "nra") {
            text = "NR-A"
        } else if (match[1] == "ns") {
            text = "NS"
        } else if (match[1] == "nsa") {
            text = "NS-A"
        } else if (match[1] == "or") {
            text = "OR"
        } else if (match[1] == "ora") {
            text = "OR-A"
        } else if (match[1] == "os") {
            text = "OS"
        } else if (match[1] == "osa") {
            text = "OS-A"
        }
        return text
    };
    return (
        <>
            <div className="recom_product_tap_box flex al_ce ju_sb">
                <div className="tap_menu small02 active" onClick={(e) => {
                    active_change(e)
                    setTap_num(0)
                    setProduct_name("클렌징")
                }}>
                    {lowestThreeNames[0]}
                </div>
                <div className="tap_menu small02" onClick={(e) => {
                    active_change(e)
                    setTap_num(1)
                    setProduct_name("클렌징")
                }}>
                    {lowestThreeNames[1]}
                </div>
                <div className="tap_menu small02" onClick={(e) => {
                    active_change(e)
                    setTap_num(2)
                    setProduct_name("클렌징")
                }}>
                    {lowestThreeNames[2]}
                </div>
            </div>
            {tap_num == 0 ?
                <div className="recom_product_box">
                    <div className="title sub_headline02">
                        20대 초반 {skin_type_text()} 여성들이 <br />
                        {lowestThreeNames[0]} 고민으로 많이 본 <span className="active">{product_name}</span>
                    </div>
                    <div className="product_type_wrap">
                        <div className="product_type label02 active" onClick={(e) => {
                            active_change_product(e)
                        }}>
                            클렌징
                        </div>
                        <div className="product_type label02" onClick={(e) => {
                            active_change_product(e)
                        }}>
                            토너
                        </div>
                        <div className="product_type label02" onClick={(e) => {
                            active_change_product(e)
                        }}>
                            세럼
                        </div>
                        <div className="product_type label02" onClick={(e) => {
                            active_change_product(e)
                        }}>
                            모이스쳐라이저
                        </div>
                        <div className="product_type label02" onClick={(e) => {
                            active_change_product(e)
                        }}>
                            스페셜케어
                        </div>
                        <div className="product_type label02" onClick={(e) => {
                            active_change_product(e)
                        }}>
                            선케어
                        </div>
                    </div>
                    <div className="product_box active">
                        <SimilarSlider product_list={product_list}></SimilarSlider>
                    </div>
                </div>
                : null}
            {tap_num == 1 ?
                <div className="recom_product_box">
                    <div className="title sub_headline02">
                        20대 초반 {skin_type_text()} 여성들이 <br />
                        {lowestThreeNames[1]} 고민으로 많이 본 <span className="active">{product_name}</span>
                    </div>
                    <div className="product_type_wrap">
                        <div className="product_type label02 active" onClick={(e) => {
                            active_change_product(e)
                        }}>
                            클렌징
                        </div>
                        <div className="product_type label02" onClick={(e) => {
                            active_change_product(e)
                        }}>
                            토너
                        </div>
                        <div className="product_type label02" onClick={(e) => {
                            active_change_product(e)
                        }}>
                            세럼
                        </div>
                        <div className="product_type label02" onClick={(e) => {
                            active_change_product(e)
                        }}>
                            모이스쳐라이저
                        </div>
                        <div className="product_type label02" onClick={(e) => {
                            active_change_product(e)
                        }}>
                            스페셜케어
                        </div>
                        <div className="product_type label02" onClick={(e) => {
                            active_change_product(e)
                        }}>
                            선케어
                        </div>
                    </div>
                    <div className="product_box active">
                    <SimilarSlider product_list={product_list}></SimilarSlider>
                    </div>
                </div>
                : null}
            {tap_num == 2 ?
                <div className="recom_product_box">
                    <div className="title sub_headline02">
                        20대 초반 {skin_type_text()} 여성들이 <br />
                        {lowestThreeNames[2]} 고민으로 많이 본 <span className="active">{product_name}</span>
                    </div>
                    <div className="product_type_wrap">
                        <div className="product_type label02 active" onClick={(e) => {
                            active_change_product(e)
                        }}>
                            클렌징
                        </div>
                        <div className="product_type label02" onClick={(e) => {
                            active_change_product(e)
                        }}>
                            토너
                        </div>
                        <div className="product_type label02" onClick={(e) => {
                            active_change_product(e)
                        }}>
                            세럼
                        </div>
                        <div className="product_type label02" onClick={(e) => {
                            active_change_product(e)
                        }}>
                            모이스쳐라이저
                        </div>
                        <div className="product_type label02" onClick={(e) => {
                            active_change_product(e)
                        }}>
                            스페셜케어
                        </div>
                        <div className="product_type label02" onClick={(e) => {
                            active_change_product(e)
                        }}>
                            선케어
                        </div>
                    </div>
                    <div className="product_box active">
                    <SimilarSlider product_list={product_list}></SimilarSlider>
                    </div>
                </div>
                : null}
        </>
    )
}

export default Recom_tap_section
